<div class="alert-chain">
  <div class="header bordered m-0 p-2">
    <h3>
      <i class="fas fa-link"></i> Alert Chain
      <i
        class="fas fa-info-circle float-right"
        placement="left"
        [popoverTitle]="content.alertChain.title"
        [ngbPopover]="content.alertChain.body"
        popoverClass="more-info"></i>
    </h3>
  </div>
  <ngb-accordion #acc="ngbAccordion" activeIds="eval, asset-summary">
    <ngb-panel id="eval">
      <!-- <ng-template ngbPanelTitle>
        <span><i class="fas fa-chevron-right"></i> Evaluation</span>
      </ng-template> -->
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link p-0">
            <h5>
              <i class="fas fa-chevron-{{ opened ? 'down' : 'right' }}"></i>
              Trigger<ng-container *ngIf="operators.length > 1">s</ng-container>
            </h5>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <ng-container *ngIf="operators.length > 0">
          <div
            class="row step"
            *ngFor="let operator of operators; let i = index">
            <lana-evaluation-tile
              [operator]="operator"
              [criterionId]="operator.criterionId"
              (formValue)="operatorValue($event, i)"
              (removeOperator)="removeOperator.emit(i)"
              (addAnother)="addAnother.emit()"
              [step]="true"
              [readonly]="operator?.readonly"
              [criterionUnit]="operator?.criterionUnit"
              [inputDataType]="operator?.inputDataType"
              [hideOperator]="
                operators.length === 1 || operators.length === i + 1
              "
              [lastTrigger]="operators.length === i + 1"></lana-evaluation-tile>
          </div>
        </ng-container>
      </ng-template>
    </ngb-panel>
    <ng-container *ngIf="assets.length > 0">
      <ngb-panel id="asset-summary">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <button ngbPanelToggle class="btn btn-link p-0">
              <h5>
                <i class="fas fa-chevron-{{ opened ? 'down' : 'right' }}"></i>
                Asset Summary
              </h5>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <lana-assets-summary
            [assets]="assets"
            (remove)="removeAsset.emit($event)"></lana-assets-summary>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>

  <!-- <div class="row mb-3">
    <div class="col">
      <ng-container *ngIf="operators.length > 0">
        <div class="row step" *ngFor="let operator of operators; let i = index">
          <lana-evaluation-tile
            [operator]="operator"
            [criterionId]="operator.criterionId"
            (formValue)="operatorValue($event, i)"
            (removeOperator)="removeOperator.emit(i)"
            [step]="true"
            [readonly]="operator?.readonly"
            [criterionUnit]="operator?.criterionUnit"
            [hideOperator]="operators.length === 1 || operators.length === i + 1"
          ></lana-evaluation-tile>
        </div>
      </ng-container>
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="col">
      <div *ngIf="assets.length > 0" [@slideLeft]="assets.length > 0 ? true : false">
        <lana-assets-summary
          [assets]="assets"
          (remove)="removeAsset.emit($event)"
        ></lana-assets-summary>
      </div>
    </div>
  </div> -->
</div>
