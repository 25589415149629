import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Menu, Menus } from './../models/menu/menu';
import { ConfigsLoaderService } from './configs-loader.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private readonly menus: Menu[] = [
    {
      id: 20,
      name: 'Callpass Main Menu',
      menuItems: [
        {
          id: 21,
          name: 'Dashboard',
          url: 'dashboard',
          icon: 'speedometer2',
          childMenuItems: []
        }
      ]
    },
    {
      id: 1,
      name: 'LANA Main Menu',
      menuItems: [
        {
          id: 1,
          name: 'Dashboard',
          url: 'dashboard',
          icon: 'speedometer2',
          childMenuItems: []
        },
        {
          id: 2,
          name: 'Device Management',
          url: 'device-management',
          icon: 'layers',
          childMenuItems: []
        },
        {
          id: 3,
          name: 'Company',
          url: 'company',
          icon: 'building',
          childMenuItems: [
            {
              id: 31,
              name: 'Duplicate Merge',
              url: 'duplicate-merge',
              icon: 'arrows-angle-contract',
              childMenuItems: []
            },
            {
              id: 32,
              name: 'Account Transfer',
              url: 'account-transfer',
              icon: 'arrow-left-right',
              childMenuItems: []
            },
            {
              id: 33,
              name: 'Device Status',
              url: 'device-status',
              icon: 'exclamation-circle',
              childMenuItems: []
            },
            {
              id: 34,
              name: 'Batch Processing',
              url: 'batch-processing',
              icon: 'exclamation-circle',
              childMenuItems: []
            }
          ]
        },
        {
          id: 5,
          name: 'User Management',
          url: 'user-management',
          icon: 'people',
          childMenuItems: []
        }
      ]
    },
    {
      id: 2,
      name: 'iGotcha Main Menu',
      menuItems: [
        {
          id: 1,
          name: 'Dashboard',
          url: 'dashboard',
          icon: 'speedometer2',
          childMenuItems: []
        },
        {
          id: 2,
          name: 'Device Management',
          url: 'device-management',
          icon: 'layers',
          childMenuItems: []
        },
        {
          id: 3,
          name: 'Company',
          url: 'company',
          icon: 'building',
          childMenuItems: [
            {
              id: 31,
              name: 'Duplicate Merge',
              url: 'duplicate-merge',
              icon: 'arrows-angle-contract',
              childMenuItems: []
            },
            {
              id: 32,
              name: 'Account Transfer',
              url: 'account-transfer',
              icon: 'arrow-left-right',
              childMenuItems: []
            },
            {
              id: 33,
              name: 'Device Status',
              url: 'device-status',
              icon: 'exclamation-circle',
              childMenuItems: []
            },
            {
              id: 34,
              name: 'Batch Processing',
              url: 'batch-processing',
              icon: 'exclamation-circle',
              childMenuItems: []
            }
          ]
        },
        {
          id: 4,
          name: 'Notices',
          url: 'notices',
          icon: 'bell',
          childMenuItems: []
        },
        {
          id: 5,
          name: 'User Management',
          url: 'user-management',
          icon: 'people',
          childMenuItems: []
        },
        {
          id: 6,
          name: 'Reports',
          url: 'reports',
          icon: 'clipboard-data',
          childMenuItems: [
            {
              id: 61,
              name: 'Device Report',
              url: 'device-report',
              icon: 'arrows-angle-contract',
              childMenuItems: []
            },
            {
              id: 62,
              name: 'Audit Report',
              url: 'audit-report',
              icon: 'arrow-left-right',
              childMenuItems: []
            }
          ]
        },
        {
          id: 7,
          name: 'Sellers',
          url: 'sellers',
          icon: 'people',
          childMenuItems: []
        }
      ]
    },
    {
      id: 3,
      name: 'User Menu',
      menuItems: [
        { id: 5, name: 'Switch Company', url: null, childMenuItems: [] },
        { id: 6, name: 'Profile', url: '/profile', childMenuItems: [] },
        { id: 7, name: 'Contact Us', url: null, childMenuItems: [] },
        { id: 8, name: 'Logout', url: '/logout', childMenuItems: [] }
      ]
    },
    {
      id: 4,
      name: 'Top Menu',
      menuItems: [
        { id: 9, name: 'Callpass', url: '/callpass', childMenuItems: [] },
        { id: 91, name: 'LANA', url: '/lana', childMenuItems: [] },
        { id: 92, name: 'iGotcha', url: '/igotcha', childMenuItems: [] }
      ]
    }
  ];

  constructor(private configService: ConfigsLoaderService) {
    if (this.configService?.configs?.production) {
      const userMenu = this.menus.find((m) => m.id === 2);
      const item = userMenu.menuItems.find((item) => item.id === 9);
      const index = userMenu.menuItems.indexOf(item);
      userMenu.menuItems.splice(index, 1);
    }
  }

  menus$ = of(this.menus);

  getMenu(menuId: Menus): Observable<Menu> {
    return this.menus$.pipe(
      map((menus) => menus.find((menu) => menu.id === menuId))
    );
  }
}
