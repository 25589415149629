import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Configs } from '../models/configs/configs';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigsLoaderService {
  private httpClient: HttpClient;
  configs: Configs;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public async loadConfigs(): Promise<any> {
    const config$ = this.httpClient
      .get('assets/configs/configs.json')
      .pipe((settings) => settings);

    const firstValue = await firstValueFrom(config$);
    this.configs = firstValue as Configs;

    return firstValue;
  }
}
