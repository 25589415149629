<div class="card" @fadeSlideDown>
  <div class="card-header">{{ header }}</div>
  <div class="card-body">
    <ng-container *ngIf="showButtons">
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm mr-2 mb-2"
        (click)="checkAllItems()">
        Check All
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm mr-2 mb-2"
        (click)="uncheckAllItems()">
        Uncheck All
      </button>
    </ng-container>
    <div class="list">
      <ejs-listview
        #listview
        [dataSource]="data"
        [showCheckBox]="true"
        [fields]="fields"
        (select)="onSelect($event)">
      </ejs-listview>
    </div>
  </div>
  <div class="card-footer text-muted text-center">
    Total: <strong>{{ data.length }}</strong>
  </div>
</div>
