import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewportService } from 'src/app/services/viewport.service';

@Component({
  selector: 'lana-viewport',
  templateUrl: './viewport.component.html',
  styleUrls: ['./viewport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewportComponent implements OnInit {
  @ViewChild('content') content: TemplateRef<any>;
  deviceType = '';

  constructor(private viewport: ViewportService, private ngbModal: NgbModal) {}

  dismiss(modal: NgbActiveModal): void {
    modal.dismiss();
  }

  ngOnInit(): void {
    this.deviceType = this.viewport.check();
    if (this.deviceType !== '') {
      setTimeout(() => {
        this.ngbModal.open(this.content);
      }, 1000);
    }
  }
}
