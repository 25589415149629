import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetSettingAction'
})
export class AssetSettingActionPipe implements PipeTransform {
  transform(value: string): string {
    return `Set ${value.split(' ').reverse()[0]}`;
  }
}
