import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/operators';
import { ConfigsLoaderService } from './configs-loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  appInsights: ApplicationInsights;
  private routerSubscription: Subscription;
  constructor(
    private router: Router,
    private configService: ConfigsLoaderService
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey:
          this.configService?.configs?.appInsights?.instrumentationKey,
        autoTrackPageVisitTime: true
      }
    });
    this.appInsights.loadAppInsights();
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(
            `${this.getRouteTemplate(event.state.root)} view`,
            event.urlAfterRedirects
          );

          //this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
        }
      });
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  setUserId(userId: string, companyId: number) {
    var validatedId = userId.replace(/[,;=| ]+/g, '_');
    this.appInsights.setAuthenticatedUserContext(
      validatedId,
      companyId.toString(),
      true
    );
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path + ' ';
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
