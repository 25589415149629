import { Component, Input, OnInit, ViewChild } from '@angular/core';
// import { data } from './datasource';
import {
  ExcelExportProperties,
  ExcelExportService,
  GridComponent,
  ToolbarItems,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Constants } from 'src/app/constants';
import { DateFormatter } from 'src/app/formatters/date.formatter';
import { AuthService } from 'src/app/services/auth.service';
import { AlertStateFormatter } from 'src/app/formatters/alertState.formatter';
import { PriorityLevelFormatter } from 'src/app/formatters/priorityLevel.fomatter';
import { AlertHistory } from 'src/app/models/dashboard/alerts';

@Component({
  selector: 'lana-dashboard-reports',
  templateUrl: './dashboard-reports.component.html',
  styleUrls: ['./dashboard-reports.component.scss'],
  providers: [ToolbarService, ExcelExportService]
})
export class DashboardReportsComponent implements OnInit {
  // public data: object[];
  public toolbarOptions: ToolbarItems[];
  public formGroup: UntypedFormGroup;
  @ViewChild('grid') public grid: GridComponent;
  @Input() fileName: string = 'Export.xlsx';
  @Input() data;
  pageOptions: object;
  public dateFormatter = DateFormatter.getValue<any>(
    'responseDateTime',
    false,
    this.authService.currentUser$
  );
  public stateFormatter =
    AlertStateFormatter.getValue<AlertHistory>('alertState');
  public priorityLevelFormatter =
    PriorityLevelFormatter.getValue<AlertHistory>('priorityLevel');

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngbModel: NgbModal,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.pageOptions = { pageSize: Constants.DefaultPageSize, pageCount: 5 };
    this.toolbarOptions = ['ExcelExport'];

    this.formGroup = this.formBuilder.group({
      duration: this.formBuilder.control('1h'),
      recipient: this.formBuilder.control('')
    });
  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id.includes('excelexport')) {
      const excelExportProperties: ExcelExportProperties = {
        fileName: this.fileName
      };
      this.grid.excelExport(excelExportProperties);
    }
  }

  openModal(content: any): void {
    this.ngbModel.open(content);
  }

  pauseAlert(): void {}
  stopAlert(): void {}
}
