<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Device Not Compatible</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      For best performance and experience, please use a computer or tablet{{
        deviceType === 'portrait' ? ' in landscape mode' : ''
      }}.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-primary"
      (click)="modal.close()">
      Acknowledge
    </button>
  </div>
</ng-template>
