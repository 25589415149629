import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  ɵstringify as stringify
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Roles } from './../../models/auth/role';
import { AuthService } from './../../services/auth.service';

@Directive({
  selector: '[lanaHasCompany]'
})
export class HasCompanyDirective implements OnDestroy {
  constructor(
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    templateRef: TemplateRef<LanaHasCompanyContext>
  ) {
    this.thenTemplateRef = templateRef;
  }

  private readonly destroy = new Subject<void>();

  private context = new LanaHasCompanyContext();
  private thenTemplateRef: TemplateRef<LanaHasCompanyContext> = null;
  private elseTemplateRef: TemplateRef<LanaHasCompanyContext> = null;
  private thenViewRef: EmbeddedViewRef<LanaHasCompanyContext> = null;
  private elseViewRef: EmbeddedViewRef<LanaHasCompanyContext> = null;

  @Input()
  set lanaHasCompany(companyToCheck: string) {
    this.context.lanaHasCompany = companyToCheck || '';

    this.authService.currentUser$
      .pipe(
        takeUntil(this.destroy),
        tap((currentUser) => {
          // this.context.$implicit =
          //   currentUser.companyName.toLocaleLowerCase() === companyToCheck.toLocaleLowerCase()
          //     ? true
          //     : false;
          // this.updateView();
        })
      )
      .subscribe();
  }

  @Input()
  set lanaHasCompanyThen(
    templateRef: TemplateRef<LanaHasCompanyContext> | null
  ) {
    assertTemplate('lanaHasCompanyThen', templateRef);
    this.thenTemplateRef = templateRef;
    this.thenViewRef = null;
    this.updateView();
  }

  @Input()
  set lanaHasCompanyElse(
    templateRef: TemplateRef<LanaHasCompanyContext> | null
  ) {
    assertTemplate('lanaHasCompanyElse', templateRef);
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null;
    this.updateView();
  }

  private updateView() {
    if (this.context.$implicit) {
      if (!this.thenViewRef) {
        this.viewContainer.clear();
        this.elseViewRef = null;
        if (this.thenTemplateRef) {
          this.thenViewRef = this.viewContainer.createEmbeddedView(
            this.thenTemplateRef,
            this.context
          );
        }
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.thenViewRef = null;
        if (this.elseTemplateRef) {
          this.elseViewRef = this.viewContainer.createEmbeddedView(
            this.elseTemplateRef,
            this.context
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}

export class LanaHasCompanyContext {
  public $implicit: boolean = false;
  public lanaHasCompany: string = null!;
}

function assertTemplate(
  property: string,
  templateRef: TemplateRef<any> | null
): void {
  const isTemplateRefOrNull = !!(
    !templateRef || templateRef.createEmbeddedView
  );

  if (!isTemplateRefOrNull) {
    throw new Error(
      `${property} must be a TemplateRef, but received '${stringify(
        templateRef
      )}'.`
    );
  }
}
