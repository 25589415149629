import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ZeroFormatter } from '../formatters/zeroFormatter.formatter';
import { AssetGroup } from '../models/group/asset-group';
import { Tracker } from '../models/tracker/tracker';
import { Constants } from './../constants';
import { AlertNotification } from './../models/alert-notification/alert-notification';
import { LanaColumnModel } from './../models/column';
import { FilterSettings } from './../models/filter-settings';
import {
  OdataResponse,
  OdataResponseTransform
} from './../models/odata-response';
import { AssetService } from './asset.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor(
    private authService: AuthService,
    private assetService: AssetService,
    private httpClient: HttpClient
  ) {}

  private readonly currentlyViewingAsset =
    new BehaviorSubject<AlertNotification>(null);
  private readonly alertNotification = new BehaviorSubject<any[]>(null);
  private readonly odataUrl = `${Constants.Odata}/AlertNotifications?$expand=CompanySummary`;
  private readonly odataGroupsUrl = `${Constants.Odata}/AssetGroups?$expand=Assets,CompanySummary`;
  private readonly odataTypesUrl = `${Constants.Odata}/AlertNotificationTypes`;
  private readonly odataPriorityUrl = `${Constants.Odata}/AlertTemplatePriorityLevels`;
  private readonly exportData = new BehaviorSubject(null);
  private readonly url = `${Constants.Api}/AlertNotification`;
  private readonly EvaluationDetailTypesUrl = `${Constants.Api}/EvaluationDetailTypes`;
  private readonly alertsUrl = `${Constants.Api}/AlertTemplate`;
  private readonly emailUrl = `${Constants.Api}/emailtemplate`;

  currentlyViewingAsset$ = this.currentlyViewingAsset.asObservable();
  alertNotification$ = this.alertNotification.pipe(
    filter((alertNotifications) => alertNotifications !== null)
  );

  readonly columns: LanaColumnModel<Tracker>[] = [
    {
      field: 'companySummary.name',
      headerText: 'Company Name',
      generateUrl: (tracker) =>
        tracker.companySummary
          ? `/management/sub-companies/${tracker.companySummary.id}`
          : ''
    },
    {
      field: 'serialNumber',
      headerText: 'Tracker Serial #',
      generateUrl: (tracker) => `/management/trackers/${tracker.id}`
    },
    {
      field: 'currentAsset.name',
      headerText: 'Asset Name',
      generateUrl: (tracker) =>
        tracker.currentAsset
          ? `/management/assets/${tracker.currentAsset.id}`
          : ''
    },
    {
      field: 'lastKnownAssetBatteryVoltage',
      headerText: 'Asset Battery',
      formatter: ZeroFormatter.getValue<Tracker>('lastKnownAssetBatteryVoltage')
    },
    { field: 'trackerTypeName', headerText: 'Tracker Type' }
  ];

  readonly filterSettings: FilterSettings = {
    columns: [
      {
        field: 'companySummary.name',
        headerText: 'Company Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'name',
        headerText: 'Alert Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'recipientList',
        headerText: 'Email Recipients',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  readonly content: any = {
    step1: {
      title: 'Select Alert Type',
      body: 'Alert types are the condition(s) used to cause the alert to execute.'
    },
    step2: {
      title: 'Select Trigger(s)',
      body: 'Choose which trigger(s) to enable in the alert. This is the condition set to generate the alert'
    },
    step3: {
      title: 'Select Asset(s)',
      body: 'Choose the asset(s) that will be used in this alert.'
    },
    step4: {
      title: 'Alert Information',
      body: 'Identifiable name and description for the alert.'
    },
    step5: {
      title: 'Recipients',
      body: 'Email address(es) and phone number(s) used to notify a triggered alert.'
    },
    step6: {
      title: 'Notifications',
      body: 'User defined conditions under which an alert notification is sent.'
    },
    alertChain: {
      title: 'Alert Chain',
      body: 'Visual representation of the alert being built including a summary of the asset(s) selected.'
    }
  };

  getEvaluationDetailTypes(): Observable<any> {
    return this.httpClient.get(`${this.EvaluationDetailTypesUrl}`);
  }

  createAlertTemplate(model: any): Observable<any> {
    return this.httpClient.post<any>(this.alertsUrl, model);
  }

  updateAlertTemplate(model: any): Observable<AlertNotification> {
    return this.httpClient.put<any>(this.alertsUrl, model);
  }

  deleteAlertTemplate(id: string): Observable<void> {
    return this.httpClient.request<void>(
      'delete',
      `${this.alertsUrl}/${id}`,
      {}
    );
  }

  getAlertTemplateById(alertId: string): Observable<any> {
    return this.authService.companyId$.pipe(
      switchMap((_) => this.httpClient.get<any>(`${this.alertsUrl}/${alertId}`))
    );
  }

  filterTypes(type): Observable<OdataResponseTransform<AssetGroup>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<AssetGroup>>(
          `${this.odataGroupsUrl}&companyContext=${companyId}&$filter=CompanySummary/Id eq ${companyId}&$count=true`
        )
      ),
      map(
        (response) =>
          ({ result: response.value, count: response['@odata.count'] } as any)
      ),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getAssets() {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.assetService.getAssets({
          filterQuery: `&$filter=CompanySummary/Id eq ${companyId}`,
          pageQuery: '',
          sortQuery: '&$orderby=lastReportTimestamp desc'
        })
      ),
      catchError((error) => {
        return error;
      })
    );
  }

  getAlertNotifications({
    filterQuery,
    pageQuery,
    sortQuery
  }): Observable<OdataResponseTransform<AlertNotification>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<AlertNotification>>(
          `${this.odataUrl}&companyContext=${companyId}&${pageQuery}${filterQuery}${sortQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getAlertNotificationTypes(): Observable<
    OdataResponseTransform<AlertNotification>
  > {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient
          .get<OdataResponse<AlertNotification>>(
            `${this.odataTypesUrl}?companyContext=${companyId}`
          )
          .pipe(
            map((response) => ({
              result: response.value,
              count: response['@odata.count']
            })),
            catchError((_) => of({ result: [], count: 0 }))
          )
      )
    );
  }

  getAlertTemplatePriorityLevels(): Observable<any> {
    return this.httpClient.get(`${this.odataPriorityUrl}`).pipe(
      map((response: any) => response.value),
      catchError((_) => of([]))
    );
  }

  getExportAlertNotifications(
    filterQuery,
    includeSubCompany
  ): Observable<OdataResponseTransform<AlertNotification>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<AlertNotification>>(
          `${this.odataUrl}&companyContext=${companyId}${
            includeSubCompany
              ? filterQuery
              : filterQuery !== ''
              ? filterQuery + ' and CompanySummary/Id eq ' + companyId
              : '&$filter=CompanySummary/Id eq ' + companyId
          }&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 })),
      tap((response) => {
        if (response.result.length > 0) {
          const bounds = response.result;
          this.exportData.next(bounds);
        }
      })
    );
  }

  getEmailTemplate(model): Observable<any> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.post(
          this.emailUrl,
          { ...model, companyContext: companyId },
          {
            responseType: 'text'
          }
        )
      )
    );
  }
}
