import { Pipe, PipeTransform } from '@angular/core';
import * as formatcoords from 'formatcoords';

@Pipe({
  name: 'addressPoint'
})
export class AddressPointPipe implements PipeTransform {
  transform(value: { latitude: number; longitude: number }): string {
    return value
      ? formatcoords(value.latitude, value.longitude).format('d X', {
          latLonSeparator: ', '
        })
      : '';
  }
}
