<div
  class="item-container"
  [class.selected]="selected"
  (delayedHover)="showGeozoneOnMap.emit(geozone)"
  delay="200"
  (mouseleave)="hideGeozoneOnMap.emit()">
  <div class="d-flex align-items-center pl-1 pr-3 pb-3 pt-3">
    <div class="ml-3 mr-3">
      <i class="fas fa-globe-americas grow"></i>
    </div>
    <div class="flex-grow-1 small mr-2">
      <div class="font-weight-bold">{{ geozone.name }}</div>
      <div *ngIf="geozone?.entityBreadcrumb" class="small">
        {{ geozone.entityBreadcrumb | companyBreadcrumb }}
      </div>
      <div class="small" *ngIf="geozone?.fullAddress">
        {{ geozone.fullAddress }}
      </div>
      <div class="small">
        Assets Within Geozone:
        {{ geozone?.currentAssetCount ? geozone.currentAssetCount : 0 }}
      </div>
    </div>
  </div>
</div>
