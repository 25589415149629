import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';

import { ContactUsComponent } from './../../../components/contact-us/contact-us.component';
import { Roles } from './../../../models/auth/role';
import { User } from './../../../models/user/user';
import { UserService } from './../../../services/user.service';

@Component({
  selector: 'lana-user-status-card',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss']
})
export class UserStatusCardComponent implements OnInit {
  constructor(
    private ngbModel: NgbModal,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  @Input() user: User;
  @ViewChild(ContactUsComponent) contactUs: ContactUsComponent;

  readonly roles = Roles;

  openModal(content: any): void {
    this.ngbModel.open(content);
  }

  showSuccess() {
    this.toastr.success('Invitation sent successfully', 'Success!');
  }

  showError() {
    this.toastr.error(
      'If you continue to get this error, please <span class="toast-contact-us">contact us</span> and let us know',
      'An error has occurred',
      {
        timeOut: 5000,
        enableHtml: true
      }
    );

    const elements = document.getElementsByClassName('toast-contact-us');

    setTimeout(() => {
      Array.from(elements).forEach((element: any) => {
        element.addEventListener('click', () => {
          this.contactUs.openModel();
        });
      });
    }, 0);
  }

  resendInvitation(userId: string) {
    this.userService
      .resendInvite(userId)
      .pipe(
        tap((_) => {
          this.showSuccess();
        }),
        catchError((error) => {
          this.showError();
          return throwError(error);
        })
      )
      .subscribe();
  }

  deleteUser(userId: string): void {
    this.userService
      .deleteUser(userId)
      .pipe(
        first(),
        tap(() => {
          this.ngbModel.dismissAll();
          this.router.navigateByUrl('/management/users');
        })
      )
      .subscribe();
  }

  ngOnInit(): void {}
}
