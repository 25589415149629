import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lana-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent implements OnInit {
  @ViewChild('content') content: TemplateRef<any>;

  constructor(private ngbModal: NgbModal) {}

  dismiss(modal: NgbActiveModal): void {
    modal.dismiss();
  }

  openModel(): void {
    this.ngbModal.open(this.content, {
      backdrop: 'static',
      size: 'lg',
      scrollable: true
    });
  }

  ngOnInit(): void {}
}
