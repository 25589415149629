<ng-template #content let-modal>
  <div class="reports-modal-map">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ data?.name ? data.name : data?.assetName }}
      </h4>
      <small class="modal-subtitle" *ngIf="fullAddress !== ''">{{
        fullAddress
      }}</small>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <agm-map
        [latitude]="latitude"
        [longitude]="longitude"
        [zoom]="defaultZoomInLevel"
        [mapTypeId]="'hybrid'"
        [streetViewControl]="true"
        [zoomControl]="true">
        <agm-marker
          [iconUrl]="{
            path: 0,
            scale: 12,
            strokeColor: '#FFFFFF',
            strokeWeight: 2,
            fillColor: '#009ede',
            fillOpacity: 1
          }"
          [latitude]="latitude"
          [longitude]="longitude">
        </agm-marker>
      </agm-map>
    </div>
  </div>
</ng-template>
