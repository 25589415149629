import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../constants';
import { RuntimeConfiguration } from '../models/global-settings/runtime-configuration';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService {
  private readonly apiUrl = `${Constants.Api}//RuntimeConfiguration`;
  constructor(private httpClient: HttpClient) {}

  getGlobalSettings(): Observable<RuntimeConfiguration[]> {
    return this.httpClient.get<RuntimeConfiguration[]>(this.apiUrl);
  }
  getGlobalSettingById(key: string) {
    return this.httpClient.get(`${this.apiUrl}/${key}`);
  }
  updateGlobalSetting(key: string, value: string) {
    return this.httpClient.put(this.apiUrl, { key: key, value: value });
  }
  updateGlobalSettings(configs: { key: string; value: string }[]) {
    return this.httpClient.put(`${this.apiUrl}/all`, configs);
  }
}
