<ng-template #pause let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Pause Alert</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>How long would you like to <strong>pause</strong> this Alert for?</p>
    <form [formGroup]="formGroup">
      <div class="form-group">
        <label>Duration</label>
        <input class="form-control" type="text" formControlName="duration" />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="pauseAlert()">
      Pause Alert
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss()">
      Cancel
    </button>
  </div>
</ng-template>
<ng-template #stop let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Stop Alert</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>How long would you like to <strong>stop</strong> this Alert for?</p>
    <form [formGroup]="formGroup">
      <div class="form-group">
        <label>Duration</label>
        <input
          class="form-control"
          type="text"
          formControlName="duration"
          placeholder="Duration" />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="stopAlert()">
      Stop Alert
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #forward let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Forward Alert</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Who would you like to <strong>forward</strong> this alert to?</p>
    <form [formGroup]="formGroup">
      <div class="form-group">
        <label>Email Recipient</label>
        <input
          class="form-control"
          type="text"
          formControlName="recipient"
          placeholder="Email Recipient" />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="stopAlert()">
      Stop Alert
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss()">
      Cancel
    </button>
  </div>
</ng-template>

<ejs-grid
  #grid
  [dataSource]="data"
  [allowExcelExport]="true"
  [allowPaging]="true"
  [pageSettings]="pageOptions"
  [toolbar]="toolbarOptions"
  (toolbarClick)="toolbarClick($event)">
  <e-columns>
    <e-column
      field="alertName"
      headerText="Alert Name"
      autofit="true"></e-column>
    <e-column
      field="tracker.serialNumber"
      headerText="Tracker Serial Number"
      autofit="true"></e-column>
    <e-column
      field="asset.name"
      headerText="Asset Name"
      autofit="true"></e-column>
    <!-- <e-column
      field="alertState"
      headerText="Alert State"
      autofit="true"
      [formatter]="stateFormatter"
      [disableHtmlEncode]="false"
    ></e-column> -->
    <e-column
      field="priorityLevel"
      headerText="Priority Level"
      autofit="true"
      [formatter]="priorityLevelFormatter"
      [disableHtmlEncode]="false"></e-column>
    <e-column
      field="responseDateTime"
      headerText="Alert Timestamp"
      [formatter]="dateFormatter"
      width="220"></e-column>
  </e-columns>
  <!-- <e-columns>
    <e-column field="serialNumber" headerText="Tracker Serial #" width="150"></e-column>
    <e-column field="status" headerText="Status" width="150"></e-column>
    <e-column field="currentAsset.name" headerText="Asset Name" width="150"></e-column>
    <e-column field="alertType" headerText="Alert Type" width="150"></e-column> -->
  <!-- <e-column headerText="Actions" width="250" textAlign="Center">
        <ng-template #template let-data>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-danger btn-sm" (click)="stopAlert()">
              <i class="fas fa-times-circle"></i> Stop Alert
            </button>
            <button type="button" class="btn btn-outline-secondary btn-sm" (click)="openModal(pause)">
              <i class="fas fa-pause-circle"></i> Pause Alert
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm"
              (click)="openModal(forward)"
            >
              <i class="fas fa-envelope"></i> Forward Alert
            </button>
          </div>
        </ng-template>
      </e-column> -->
  <!-- </e-columns> -->
</ejs-grid>
