import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  tap
} from 'rxjs/operators';

import { Constants } from './../constants';
import { Asset } from './../models/asset/asset';
import { MapAsset } from './../models/asset/map-asset';
import { Category } from './../models/category/category';
import { Geozone } from './../models/geozone/geozone';
import { MapCloudGeozone } from './../models/geozone/map-cloud-geozone';
import { AssetGroup } from './../models/group/asset-group';
import {
  RecentlyViewedEntity,
  RecentlyViewedEntityResult
} from './../models/map/recently-viewed-entity';
import { EntityType } from './../models/search/entity-type';
import { AuthService } from './auth.service';
import { GeozoneService } from './geozone.service';
import { GroupService } from './group.service';
import { MapAssetService } from './map-asset.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  constructor(
    private authService: AuthService,
    private geozoneService: GeozoneService,
    private groupService: GroupService,
    private httpClient: HttpClient,
    private mapAssetService: MapAssetService
  ) {
    this.init();
  }

  private readonly currentlyShowingAsset = new BehaviorSubject<MapAsset>(null);
  private readonly currentlyShowingMapAsset = new BehaviorSubject<MapAsset>(
    null
  );
  private readonly currentlyShowingMapCloudGeozone =
    new BehaviorSubject<MapCloudGeozone>(null);
  private readonly recentlyViewedEntities = new BehaviorSubject<
    RecentlyViewedEntity[]
  >([]);
  private readonly visibleAssets = new BehaviorSubject<Asset[]>([]);
  private readonly visibleMapAssets = new BehaviorSubject<MapAsset[]>([]);
  private readonly url = `${Constants.Api}/image`;

  currentlyShowingAsset$ = this.currentlyShowingAsset.asObservable();
  currentlyShowingMapAsset$ = this.currentlyShowingMapAsset.asObservable();
  currentlyShowingMapCloudGeozone$ =
    this.currentlyShowingMapCloudGeozone.asObservable();
  recentlyViewedEntities$ = this.recentlyViewedEntities.pipe(
    switchMap((entities) => this.mapRecentlyViewedEntities(entities))
  );
  visibleAssets$ = this.visibleAssets.asObservable();
  visibleMapAssets$ = this.visibleMapAssets.asObservable();
  visibleAssetsMap = new Map<number, boolean>();
  visibleMapAssetsMap = new Map<number, boolean>();
  visibleGroupMap = new Map<number, boolean>();

  private readonly companyRecentlyViewedEntitiesKey = (companyId: number) =>
    `recently-viewed-entities-${companyId}`;

  private getCompanyRecentlyViewedEntities(
    companyId: number
  ): RecentlyViewedEntity[] {
    const entities = localStorage.getItem(
      this.companyRecentlyViewedEntitiesKey(companyId)
    );

    return entities ? JSON.parse(entities) : [];
  }

  private init(): void {
    this.authService.companyId$
      .pipe(
        filter((companyId) => companyId !== null),
        distinctUntilChanged(),
        map((companyId) => this.getCompanyRecentlyViewedEntities(companyId)),
        tap((entities) => this.recentlyViewedEntities.next(entities))
      )
      .subscribe();
  }

  hideAssets(): void {
    this.visibleAssets.next([]);
    this.visibleAssetsMap.forEach((_, key) =>
      this.visibleAssetsMap.delete(key)
    );
  }

  setVisibleAssets(assets: Asset[]): void {
    this.visibleAssets.next(assets);
    assets.forEach((asset) => this.visibleAssetsMap.set(asset.id, true));
  }

  setVisibleMapAssets(assets: MapAsset[]): void {
    this.visibleMapAssets.next(assets);
    assets.forEach((asset) => this.visibleMapAssetsMap.set(asset.id, true));
  }

  toggleAssetVisibility(asset: Asset, isVisibleOnMap: boolean): void {
    const visibleAssets = this.visibleAssets.getValue();

    this.visibleAssetsMap.set(asset.id, isVisibleOnMap);

    if (isVisibleOnMap) {
      this.visibleAssets.next([...visibleAssets, asset]);
    } else {
      this.visibleAssets.next(
        visibleAssets.filter((visibleAsset) => visibleAsset.id !== asset.id)
      );
    }
  }

  toggleMapAssetVisibility(asset: MapAsset, isVisibleOnMap: boolean): void {
    const visibleMapAssets = this.visibleMapAssets.getValue();

    this.visibleMapAssetsMap.set(asset.id, isVisibleOnMap);

    if (isVisibleOnMap) {
      this.visibleMapAssets.next([...visibleMapAssets, asset]);
    } else {
      this.visibleMapAssets.next(
        visibleMapAssets.filter((visibleAsset) => visibleAsset.id !== asset.id)
      );
    }
  }

  updateRecentlyViewed(recentlyViewedEntity: RecentlyViewedEntity): void {
    const recentlyViewedEntities = this.recentlyViewedEntities.getValue();
    const filtered = recentlyViewedEntities
      .filter((value) => value.id !== recentlyViewedEntity.id)
      .slice(0, Constants.DefaultRecentlyViewedPageSize - 1);
    const updated = [recentlyViewedEntity, ...filtered];

    this.recentlyViewedEntities.next(updated);

    this.authService.companyId$
      .pipe(
        first(),
        map((companyId) => this.companyRecentlyViewedEntitiesKey(companyId)),
        tap((key) => localStorage.setItem(key, JSON.stringify(updated)))
      )
      .subscribe();
  }

  setCurrentlyShowingGeozone(mapCloudGeozone: MapCloudGeozone): void {
    this.currentlyShowingMapCloudGeozone.next(mapCloudGeozone);
  }

  setCurrentlyShowingAsset(asset: MapAsset): void {
    const currentlyShowingAsset = this.currentlyShowingAsset.getValue();
    const value =
      currentlyShowingAsset && asset && asset.id === currentlyShowingAsset.id
        ? null
        : asset;

    if (value) {
      this.currentlyShowingAsset.next(null);
      setTimeout(() => {
        this.currentlyShowingAsset.next(value);
      }, 100);
    } else {
      this.currentlyShowingAsset.next(null);
    }
  }

  setCurrentlyShowingMapAsset(asset: MapAsset): void {
    const currentlyShowingMapAsset = this.currentlyShowingMapAsset.getValue();

    if (currentlyShowingMapAsset && asset) {
      if (currentlyShowingMapAsset.id === asset.id) {
        asset.isSelected.next(false);
        this.currentlyShowingMapAsset.next(null);
      } else {
        currentlyShowingMapAsset.isSelected.next(false);
        asset.isSelected.next(false);
        this.currentlyShowingMapAsset.next(null);

        setTimeout(() => {
          asset.isSelected.next(true);
          this.currentlyShowingMapAsset.next(asset);
        }, 0);
      }
    } else if (asset) {
      asset.isSelected.next(true);
      this.currentlyShowingMapAsset.next(asset);
    }
  }

  getCurrentlyShowingMapAsset(): MapAsset {
    return this.currentlyShowingMapAsset.getValue();
  }

  getImage(imageId: string) {
    imageId = imageId.replace(/"/g, '');
    return this.httpClient
      .get(`${this.url}/${imageId}`, { responseType: 'text' })
      .pipe(first((data) => data.length > 0));
  }

  resetCurrent(): void {
    const currentlyShowingMapAsset = this.currentlyShowingMapAsset.getValue();

    if (currentlyShowingMapAsset) {
      currentlyShowingMapAsset.isSelected.next(false);
      this.currentlyShowingMapAsset.next(null);
    }
  }

  private mapRecentlyViewedEntities(
    entities: RecentlyViewedEntity[]
  ): Observable<RecentlyViewedEntityResult[]> {
    const futures = entities.reduce((working, next) => {
      switch (next.entityType) {
        case EntityType.Asset:
          working.push(
            combineLatest([
              of(next),
              of(Category.Assets),
              this.mapAssetService.mapAssets$.pipe(
                map((response) => response.find(({ id }) => id === next.id))
              )
            ])
          );
          break;
        case EntityType.AssetGroup:
          working.push(
            combineLatest([
              of(next),
              of(Category.Groups),
              this.groupService.getAssetGroup(next.id)
            ])
          );
          break;
        case EntityType.Geozone:
          working.push(
            combineLatest([
              of(next),
              of(Category.CloudBasedGeozones),
              this.geozoneService.getMapCloudGeozone(next.id)
            ])
          );
          break;
      }
      return working;
    }, [] as Observable<[RecentlyViewedEntity, Category, MapAsset | AssetGroup | Geozone]>[]);

    return futures.length > 0
      ? combineLatest(futures).pipe(
          map((response) =>
            response
              .map(([recentlyViewed, category, entity]) => ({
                ...recentlyViewed,
                category,
                entity
              }))
              .filter((value) => value.entity !== null)
          )
        )
      : of([]);
  }
}
