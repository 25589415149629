import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import {
  CheckBoxSelectionService,
  MultiSelectComponent
} from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  last,
  tap
} from 'rxjs/operators';
import { MapCloudGeozoneService } from 'src/app/services/map-cloud-geozone.service';

@Component({
  selector: 'lana-geozone',
  templateUrl: './geozone.component.html',
  styleUrls: ['./geozone.component.scss'],
  providers: [CheckBoxSelectionService]
})
export class GeozoneComponent implements OnInit {
  public mode: string;
  public fields: Object = { text: 'name', value: 'id' };
  public geozoneFields: Object = { text: 'name', value: 'cosmosId' };
  public query: Query = new Query();
  @Input() formGroup: UntypedFormGroup;
  @Input() step: boolean;
  geozone: Observable<Object>;

  @ViewChild('geozoneMultiSelect')
  public geozoneMultiSelect: MultiSelectComponent;
  public geozoneDirections: { [key: string]: Object }[] =
    this.mapCloudGeozoneService.geozoneDirections;

  public filterTypes = [
    { id: true, name: 'Include All Geozones' },
    { id: false, name: 'Select Individual Geozones' }
  ];
  public allGeozones = true;

  constructor(private mapCloudGeozoneService: MapCloudGeozoneService) {}

  updateForm() {
    const value = this.formGroup.value;
    if (this.allGeozones) {
      this.formGroup.setValue({
        ...value,
        geozoneIds: [],
        thresholdValue: { geozoneIds: [], includeAllGeozones: true }
      });
    }
  }

  ngOnInit(): void {
    this.mode = 'CheckBox';
    this.geozone = this.mapCloudGeozoneService.getMapCloudGeozones();
    this.allGeozones = this.formGroup.get('includeAllGeozones').value;

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250),
        tap((value) => (this.allGeozones = value.includeAllGeozones)),
        tap((_) => this.updateForm())
      )
      .subscribe();

    if (this.step === false) {
      this.formGroup.patchValue({
        geozoneDirection: '2'
      });
    }
  }

  onCreate(args: any): void {
    const value = this.formGroup.get('geozoneIds').value;
    if (value.length === 0) {
      setTimeout(() => {
        this.geozoneMultiSelect.selectAll(true);
      }, 200);
    }
  }
}
