import { Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MapService } from 'src/app/services/map.service';
import { EventData } from './../../models/telemetry/event-data';
import { VisionModalComponent } from './../../components/vision-modal/vision-modal.component';
import { ThemeService } from '@app/services/theme.service';

@Component({
  selector: 'lana-event-data-info-window',
  templateUrl: './event-data-info-window.component.html',
  styleUrls: ['./event-data-info-window.component.scss']
})
export class EventDataInfoWindowComponent {
  @Input() eventData: EventData;
  @Input() imageUrl: Observable<string>;
  @Input() timeZone: string;
  @Input() set imageId(image: string) {
    if (typeof image !== 'undefined') {
      this.getImages(image);
    }
  }
  @ViewChild(VisionModalComponent) visionModal: VisionModalComponent;

  public image: Observable<any>;
  theme: Observable<string>;
  showImage = true;

  constructor(
    private mapService: MapService,
    private themeService: ThemeService
  ) {
    this.theme = this.themeService.currentCustomer$;
  }

  getImages(image) {
    this.image = this.mapService.getImage(image);
  }

  updateUrl(e) {
    this.showImage = false;
  }

  openModal(image) {
    const asset = this.eventData;

    const vision = {
      name: asset.assetName,
      timestamp: asset.responseDateTime,
      trackerBatteryVoltage: asset?.deviceBatteryVoltage,
      trackerSerialNumber: asset?.serialNumber,
      fullAddress: asset?.fullAddress,
      latitude: asset?.latitude,
      longitude: asset?.longitude,
      eventCodeDescription: asset?.eventCodeDescription,
      occupancySensorVolumePercentFull:
        asset?.lanaVisionEvent?.imagePayload?.occupancyVolume,
      occupancySensorIsDoorOpen:
        asset?.lanaVisionEvent?.occupancySensorIsDoorOpen,
      image: asset?.lanaVisionEvent?.imagePayload?.imageId,
      temperature: asset?.lanaVisionEvent?.visionTemperature
    };
    this.visionModal.openModal(vision, this.timeZone, image);
  }
}
