import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { AssetGroup } from '../models/group/asset-group';
import { Constants } from './../constants';
import { DateFormatter } from './../formatters/date.formatter';
import { Asset, AssignAsset, BulkAssignAssets } from './../models/asset/asset';
import { AssetComment } from './../models/asset/asset-comment';
import { CommentList } from './../models/asset/comment';
import { LanaColumnModel } from './../models/column';
import { FilterSettings } from './../models/filter-settings';
import {
  OdataResponse,
  OdataResponseTransform
} from './../models/odata-response';
import { AuthService } from './auth.service';
import { ZeroFormatter } from './../formatters/zeroFormatter.formatter';
import { EmptyFormatter } from '../formatters/empty.formatter';
import { Device } from '@app/models/device/device';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private readonly currentlyViewingAsset = new BehaviorSubject<Asset>(null);
  private readonly odataUrl = `${Constants.Odata}/Assets?$expand=CompanySummary,CurrentTracker`;
  private readonly odataUrl2 = `${Constants.Odata}/Assets?$expand=CompanySummary`;
  private readonly url = `${Constants.Api}/Asset`;
  private readonly trackerUrl = `${Constants.Api}/Tracker`;

  currentlyViewingAsset$ = this.currentlyViewingAsset.asObservable();

  // readonly columns: LanaColumnModel<Asset>[] = [
  //   {
  //     field: 'name',
  //     headerText: 'Asset Name',
  //     generateUrl: asset => `/management/assets/${asset.id}`,
  //     autoFit: true,
  //   },
  //   {
  //     field: 'currentTracker.serialNumber',
  //     headerText: 'Tracker Serial #',
  //     generateUrl: asset =>
  //       asset.currentTracker ? `/management/trackers/${asset.currentTracker.id}` : '',
  //   },
  //   { field: 'vin', headerText: 'VIN' },
  //   {
  //     field: 'lastReportTimestamp',
  //     headerText: 'Last Report',
  //     formatter: DateFormatter.getValue<Asset>(
  //       'lastReportTimestamp',
  //       false,
  //       this.authService.currentUser$
  //     ),
  //     autoFit: true,
  //   },
  // ];
  readonly columns: LanaColumnModel<Device>[] = [
    {
      field: 'serialNumber',
      headerText: 'Serial Number',
      generateUrl: (device) => `/management/device/${device.serialNumber}`,
      autoFit: true
    },
    {
      field: 'deviceType',
      headerText: 'Device Type'
    },
    {
      field: 'activeDate',
      headerText: 'Active',
      formatter: DateFormatter.getValue<Device>(
        'activeDate',
        false,
        this.authService.currentUser$
      ),
      autoFit: true
    },
    {
      field: 'expireDate',
      headerText: 'Expire',
      formatter: DateFormatter.getValue<Device>(
        'expireDate',
        false,
        this.authService.currentUser$
      ),
      autoFit: true
    }
  ];
  readonly AccountsTransfer: LanaColumnModel<Asset>[] = [
    {
      field: 'name',
      headerText: 'Device Name',
      generateUrl: (asset) => `/management/assets/${asset.id}`,
      autoFit: true
    },
    {
      field: 'currentTracker.serialNumber',
      headerText: 'Device ID',
      generateUrl: (asset) =>
        asset.currentTracker
          ? `/management/trackers/${asset.currentTracker.id}`
          : ''
    },
    { field: 'sim', headerText: 'SIM' },
    { field: 'vin', headerText: 'VIN' },
    { field: 'assetYear', headerText: 'Year' },
    { field: 'Status', headerText: 'Status' }
  ];
  readonly filterSettings: FilterSettings = {
    columns: [
      {
        field: 'name',
        headerText: 'Asset Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'currentTracker.serialNumber',
        headerText: 'Tracker Serial #',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      // { field: 'currentTracker.trackerTypeName', headerText: 'Tracker Type', matchCase: false, operator: 'contains', predicate: 'or' },
      // { field: 'metaAssetDetails.metaAssetTypeName', headerText: 'Type', matchCase: false, operator: 'contains', predicate: 'or' },
      // { field: 'metaAssetDetails.metaAssetMakeName', headerText: 'Make', matchCase: false, operator: 'contains', predicate: 'or' },
      // { field: 'metaAssetDetails.metaAssetModelName', headerText: 'Model', matchCase: false, operator: 'contains', predicate: 'or' },
      {
        field: 'vin',
        headerText: 'VIN',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'licensePlate',
        headerText: 'License Plate',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  readonly assetAssignmentColumns: LanaColumnModel<Asset>[] = [
    { field: 'companySummary.name', headerText: 'Company Name' },
    { field: 'name', headerText: 'Name' },
    {
      field: 'lastReportTimestamp',
      headerText: 'Last Report Timestamp',
      formatter: DateFormatter.getValue<Asset>(
        'lastReportTimestamp',
        false,
        this.authService.currentUser$
      )
    }
  ];

  readonly assetAssignmentFilterSettings: FilterSettings = {
    columns: [
      {
        field: 'name',
        headerText: 'Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  getAssets({
    filterQuery,
    pageQuery,
    sortQuery
  }): Observable<OdataResponseTransform<Asset>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<Asset>>(
          `${this.odataUrl}&companyContext=${companyId}&${pageQuery}${filterQuery}${sortQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }
  getAssetsForWidgetsDropDown({
    filterQuery,
    pageQuery,
    sortQuery
  }): Observable<OdataResponseTransform<Asset>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<Asset>>(
          `${this.odataUrl2}&companyContext=${companyId}&${pageQuery}${filterQuery}${sortQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }
  getExportAssets({ filterQuery }): Observable<OdataResponseTransform<Asset>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<Asset>>(
          `${this.odataUrl}&companyContext=${companyId}&${filterQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getAsset(assetId: number): Observable<Asset> {
    this.currentlyViewingAsset.next(null);

    return assetId
      ? this.httpClient
          .get<Asset>(`${this.url}/${assetId}`)
          .pipe(tap((response) => this.currentlyViewingAsset.next(response)))
      : of(null).pipe(tap((_) => this.currentlyViewingAsset.next(null)));
  }

  getAssetsForAssetGroup(
    assetGroupId: number,
    { page, top }: { page?: number; top?: number } = { page: null, top: null }
  ): Observable<{ result: Asset[]; count: number }> {
    const pageQuery =
      Number.isInteger(page) && Number.isInteger(top)
        ? `&$skip=${page * top}&$top=${top}`
        : '';

    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient
          .get<OdataResponse<Asset>>(
            `${this.odataUrl}&companyContext=${companyId}&$count=true&$filter=AssetGroups/any(ag:ag/Id eq ${assetGroupId})${pageQuery}`
          )
          .pipe(
            map((response) => ({
              result: response.value,
              count: response['@odata.count']
            })),
            catchError((_) => of({ result: [], count: 0 }))
          )
      )
    );
  }

  getAssetsForCompany(
    companyId: number,
    { filter = '', page, top }: { filter?: string; page?: number; top?: number }
  ): Observable<OdataResponseTransform<Asset>> {
    const filterQuery = filter
      ? ` and contains(tolower(Name), '${filter}')`
      : '';
    const pageQuery =
      Number.isInteger(page) && Number.isInteger(top)
        ? `&$skip=${page * top}&$top=${top}`
        : '';
    const url = `${this.odataUrl}&companyContext=${companyId}&$count=true&$filter=CompanySummary/Id eq ${companyId}${filterQuery}${pageQuery}`;

    return this.httpClient.get<OdataResponse<Asset>>(url).pipe(
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getComments(assetId: number): Observable<CommentList> {
    const url = `${this.url}/${assetId}/Comments`;

    return this.httpClient.get<CommentList>(url).pipe(
      map((response) => {
        response.comments = response.comments.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
        return response;
      })
    );
  }

  createComment(model: AssetComment): Observable<CommentList> {
    const url = `${this.url}/Comment`;

    return this.httpClient.post<CommentList>(url, model);
  }

  removeAsset(id: number): Observable<void> {
    return this.httpClient.request<void>('delete', this.url, { body: { id } });
  }

  createAsset(asset: Asset): Observable<Asset> {
    asset = Object.assign({}, asset, {
      dateDamaged: this.convertDateToTimezone(asset.dateDamaged),
      inspectionDueDate: this.convertDateToTimezone(asset.inspectionDueDate)
    });
    return this.httpClient.post<Asset>(this.url, asset);
  }

  convertDateToTimezone(date) {
    if (!date) {
      return '';
    }

    try {
      const d = moment(date);
      return moment(d).format();
    } catch (e) {
      return date;
    }
  }

  updateAsset(asset: Asset): Observable<Asset> {
    asset = Object.assign({}, asset, {
      dateDamaged: this.convertDateToTimezone(asset.dateDamaged),
      inspectionDueDate: this.convertDateToTimezone(asset.inspectionDueDate)
    });

    const currentAsset = this.currentlyViewingAsset.getValue();

    return this.httpClient
      .put<Asset>(this.url, { ...currentAsset, ...asset })
      .pipe(
        tap((response) => this.currentlyViewingAsset.next(response))
        // catchError(error => {
        //   console.log('error is catched ', error);
        //   this.currentlyViewingAsset.next(currentAsset);
        //   return throwError(error);
        // })
      );
  }

  addAssetGroupForAsset(assetGroup: AssetGroup): void {
    const currentValue = this.currentlyViewingAsset.getValue();

    if (currentValue) {
      currentValue.assetGroups = [...currentValue.assetGroups, assetGroup];

      this.currentlyViewingAsset.next(currentValue);
    }
  }

  removeAssetGroupFromAsset(assetGroupId: number): void {
    const currentValue = this.currentlyViewingAsset.getValue();

    if (currentValue) {
      currentValue.assetGroups = currentValue.assetGroups.filter(
        (assetGroup) => assetGroup.id !== assetGroupId
      );

      this.currentlyViewingAsset.next(currentValue);
    }
  }

  assignToCompany(model: AssignAsset): Observable<Asset> {
    return this.httpClient.put<Asset>(`${this.url}/assign-to-company`, model);
  }

  bulkAssignToCompany(model: BulkAssignAssets): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/bulk-assign-to-company`,
      model
    );
  }

  getAssignableAssets(
    currentlyViewingCompanyId: number,
    { filterQuery, pageQuery, sortQuery }
  ): Observable<OdataResponseTransform<Asset>> {
    return this.httpClient
      .get<OdataResponse<Asset>>(
        `${this.odataUrl}&companyContext=${currentlyViewingCompanyId}&${pageQuery}${filterQuery}${sortQuery}&$count=true`
      )
      .pipe(
        map((response) => ({
          result: response.value,
          count: response['@odata.count']
        })),
        catchError((_) => of({ result: [], count: 0 }))
      );
  }

  getReclaimableAssets(
    currentlyViewingCompanyId: number,
    { filterQuery, pageQuery, sortQuery }
  ): Observable<OdataResponseTransform<Asset>> {
    const filterQueryWithCompanyId = filterQuery
      ? `${filterQuery} and CompanySummary/Id ne ${currentlyViewingCompanyId}`
      : `&$filter=CompanySummary/Id ne ${currentlyViewingCompanyId}`;

    return this.httpClient
      .get<OdataResponse<Asset>>(
        `${this.odataUrl}&companyContext=${currentlyViewingCompanyId}&${pageQuery}${filterQueryWithCompanyId}${sortQuery}&$count=true`
      )
      .pipe(
        map((response) => ({
          result: response.value,
          count: response['@odata.count']
        })),
        catchError((_) => of({ result: [], count: 0 }))
      );
  }

  setCommands(url: string, asset, value: string) {
    return this.httpClient.get<Asset>(
      `${this.trackerUrl}/${asset.value.currentTracker.id}/${url}/${value}`
    );
  }

  setCurrentlyViewingAsset(asset: Asset | null) {
    this.currentlyViewingAsset.next(asset);
  }

  getCurrentlyViewingAsset(): Asset | null {
    return this.currentlyViewingAsset.getValue();
  }
}
