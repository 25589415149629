import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lana-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  public month: number = new Date().getMonth();
  public fullYear: number = new Date().getFullYear();
  public date: number = new Date().getDate();
  public schedule = [];

  invalidStart = false;
  invalidEnd = false;
  currentIndex: number;

  readonly loading = new BehaviorSubject(false);

  @ViewChild('content') content: TemplateRef<any>;
  @Output() alertSchedule = new EventEmitter<any>();

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    const config = this.formGroup.get('configuration');

    if (config.value) {
      const value = config.value;
      const configuration = value?.configuration;
      const schedule =
        configuration.alertSchedule.length > 0
          ? configuration.alertSchedule
          : [];
      this.updateSchedule(schedule);
    } else if (this.schedule?.length === 0) {
      this.addSchedule();
    }
  }

  openModal(): void {
    this.modalService.open(this.content, {
      backdrop: 'static',
      size: 'lg',
      scrollable: true
    });
  }

  daySelected(selection: number, index: number) {
    for (let i = 0; i < this.schedule.length; i++) {
      if (index === i) {
        const sch = this.schedule[i];
        const alertOnDays = sch.alertOnDays;

        alertOnDays.map((day) => {
          if (day.val === selection) {
            day.selected = !day.selected;
          }
          return day;
        });

        sch.alertOnDays = [...alertOnDays];

        this.schedule[i] = { ...sch };
      }
    }
  }

  time(when: string, value, index: number) {
    this.invalidStart = false;
    this.invalidEnd = false;
    this.currentIndex = null;
    if (value) {
      for (let i = 0; i < this.schedule.length; i++) {
        if (index === i) {
          const sch = this.schedule[i];

          sch[when] = value;

          this.schedule[i] = { ...sch };
        }
      }
    } else {
      this.currentIndex = index;
      if (when === 'startTime') {
        this.invalidStart = true;
      }
      if (when === 'endTime') {
        this.invalidEnd = true;
      }
    }
  }

  addSchedule() {
    this.loading.next(true);
    const newSchedule = {
      startTime: new Date(this.fullYear, this.month, this.date, 0, 0, 0),
      endTime: new Date(this.fullYear, this.month, this.date, 23, 59, 59),
      alertOnDays: [
        { name: 'Sun', val: 0, selected: true },
        { name: 'Mon', val: 1, selected: true },
        { name: 'Tue', val: 2, selected: true },
        { name: 'Wed', val: 3, selected: true },
        { name: 'Thu', val: 4, selected: true },
        { name: 'Fri', val: 5, selected: true },
        { name: 'Sat', val: 6, selected: true }
      ]
    };

    setTimeout(() => {
      this.schedule.push(newSchedule);
      this.loading.next(false);
    }, 500);
  }

  updateSchedule(schedules) {
    this.loading.next(true);
    const updated = [];
    for (let i = 0; i < schedules.length; i++) {
      const schedule = schedules[i];

      const newSchedule = {
        startTime: new Date(
          this.fullYear,
          this.month,
          this.date,
          schedule?.startTimeHourAsInt ?? 0,
          schedule?.startTimeMinutesAsInt ?? 0,
          0
        ),
        endTime: new Date(
          this.fullYear,
          this.month,
          this.date,
          schedule?.endTimeHourAsInt ?? 0,
          schedule?.endTimeMinutesAsInt ?? 0,
          0
        ),
        alertOnDays: [
          { name: 'Sun', val: 0, selected: false },
          { name: 'Mon', val: 1, selected: false },
          { name: 'Tue', val: 2, selected: false },
          { name: 'Wed', val: 3, selected: false },
          { name: 'Thu', val: 4, selected: false },
          { name: 'Fri', val: 5, selected: false },
          { name: 'Sat', val: 6, selected: false }
        ]
      };
      schedule.alertOnDays.map((day) => {
        newSchedule.alertOnDays[day]['selected'] = true;
      });

      updated.push(newSchedule);
    }
    setTimeout(() => {
      this.schedule = [...updated];
      this.loading.next(false);
    }, 500);
  }

  removeSchedule(index: number) {
    this.schedule.splice(index, 1);
  }

  save() {
    this.alertSchedule.emit(this.schedule);
    this.modalService.dismissAll();
  }
}
