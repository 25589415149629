<ng-template #resend let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Re-Send Invite</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Would you like to re-send the new user invitation to {{ user.username }}?
      The invitation will expire in 7 days.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="resendInvitation(user.id)">
      Re-Send Invitation
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss()">
      Cancel
    </button>
  </div>
</ng-template>
<ng-template #cancel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cancel Invite</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Would you like to cancel the new user invitation for {{ user.username }}?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="deleteUser(user.id)">
      Cancel Invitation
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss()">
      Cancel
    </button>
  </div>
</ng-template>
<div class="card shadow">
  <div class="card-header d-flex align-items-center">
    <div class="mr-auto font-weight-bold">
      <i
        class="fas fa-{{
          user?.userStatusId === 1 ? 'toggle-on' : 'toggle-off'
        }} fa-fw mr-2"></i>
      User Status
    </div>
    <div></div>
  </div>
  <ng-template #loadingTemplate>
    <lana-loading></lana-loading>
  </ng-template>
  <div class="card-body p-0">
    <ul class="list-group list-group-flush">
      <ng-template #defaultStatus>
        <li class="list-group-item p-5 text-center">
          <p class="mb-0">User Status Unknown</p>
        </li>
      </ng-template>
      <ng-container *ngIf="user?.userStatusName; else defaultStatus">
        <li class="list-group-item">
          <div class="d-flex align-items-center">
            <div class="mr-auto">
              {{ user.userStatusName }}
            </div>
          </div>
        </li>
        <ng-container *lanaHasRole="[roles.Administrator]">
          <div
            class="row m-2"
            *ngIf="user.userStatusId === 3 || user.userStatusId === 4">
            <div class="col-6">
              <button
                type="button"
                class="btn btn-primary"
                (click)="openModal(resend)">
                Re-Send Invitation
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="openModal(cancel)">
                Cancel Invitation
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>
<lana-contact-us></lana-contact-us>
