import { Column } from '@syncfusion/ej2-grids';
import * as moment from 'moment-timezone';
import { tap } from 'rxjs/operators';

export class DateFormatter {
  static getValue<T>(prop: string, detailsPage = false, timezone: any) {
    return (_: Column, model: T) => {
      let myTimezone = 'America/New_York';
      if (timezone) {
        timezone
          .pipe(
            tap((data) => {
              myTimezone = data['ianaTimeZone'];
            })
          )
          .subscribe();
      }
      return model[prop]
        ? moment(model[prop]).tz(myTimezone).format('M/D/YYYY h:mm:ss A z')
        : detailsPage
        ? 'Current'
        : "<span style='color: #A9A9A9'>N/A</span>";
    };
  }
}
