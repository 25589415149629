import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
  DataStateChangeEventArgs,
  PredicateModel
} from '@syncfusion/ej2-grids';
import { Predicate } from '@syncfusion/ej2-data';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Asset } from './../models/asset/asset';
import { OdataResponseTransform } from './../models/odata-response';
import { AssetService } from './asset.service';
import { ODataHelperService } from './o-data-helper.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Constants } from '@app/constants';

@Injectable()
export class AssetTableService extends Subject<DataStateChangeEventArgs> {
  constructor(
    private assetService: AssetService,
    private authService: AuthService,
    private oDataHelperService: ODataHelperService,
    private httpClient: HttpClient
  ) {
    super();
  }

  private readonly destroy = new Subject();
  // private filter: PredicateModel[] = undefined;
  private filter: Predicate[] = undefined;
  private readonly exportData = new BehaviorSubject(null);

  exportData$ = this.exportData.asObservable();

  public execute(state: any): void {
    this.getData(state).subscribe((x) => super.next(x as any));
  }

  protected getExport(
    state: DataStateChangeEventArgs & { includeSubCompany: boolean }
  ): Observable<OdataResponseTransform<Asset>> {
    this.filter = state.where || this.filter;

    const filterQuery = this.oDataHelperService.calcFilterQuery(
      this.filter,
      state.includeSubCompany
    );

    return state.includeSubCompany
      ? this.assetService.getExportAssets({ filterQuery })
      : this.authService.companyId$.pipe(
          switchMap((companyId) =>
            this.assetService.getExportAssets({
              filterQuery: filterQuery
                ? `${filterQuery} and CompanySummary/Id eq ${companyId}`
                : `&$filter=CompanySummary/Id eq ${companyId}`
            })
          )
        );
  }

  getDeviceData() {
    return this.httpClient.get(`${Constants.Api}/igotcha/Device`);
  }

  protected getData(
    state: DataStateChangeEventArgs & { includeSubCompany: boolean }
  ): Observable<OdataResponseTransform<Asset>> {
    this.filter = state.where || this.filter;

    const filterQuery = this.oDataHelperService.calcFilterQuery(
      this.filter,
      state.includeSubCompany
    );
    const pageQuery = this.oDataHelperService.calcPageQuery(state);
    const sortQuery = this.oDataHelperService.calcSortQuery(state);

    return state.includeSubCompany
      ? this.assetService.getAssets({ filterQuery, pageQuery, sortQuery })
      : this.authService.companyId$.pipe(
          switchMap((companyId) =>
            this.assetService.getAssets({
              filterQuery: filterQuery
                ? `${filterQuery} and CompanySummary/Id eq ${companyId}`
                : `&$filter=CompanySummary/Id eq ${companyId}`,
              pageQuery,
              sortQuery
            })
          )
        );
  }
}
