<div class="pt-1 pb-1 mb-0 position-relative shadow-sm position crumbs">
  <p class="mb-0 ml-3">
    <i class="fas fa-tachometer-alt mr-3"></i>
    <span class="font-weight-bold"> Device Management </span>
  </p>
</div>
<div class="pl-5 pr-5">
  <div class="header bordered">
    <br />
    <h5>Device Management</h5>
    <h3>
      Device Management

      <button
        type="button"
        class="btn btn-outline-primary fl-r"
        (click)="open(content)">
        <i class="fa fa-plus" aria-hidden="true"></i> New Inventory
      </button>
    </h3>
  </div>
  <div class="pl-2 pr-2">
    <lana-grid
      [columns]="columns"
      [dataService]="assetTableService"
      [filterSettings]="filterSettings"
      includeSubCompanyText="assets"
      [allowExcelExport]="true"
      [isSearchAble]="true"
      [excelExportObservable]="true"
      [toolbarOptions]="['ExcelExport']"
      [exportFileName]="'Assets'"></lana-grid>

    <!-- <table class="table">
    <thead>
      <tr>
        <th scope="col">
          <div class="form-check">
            <input type="checkbox" />
          </div>
        </th>
        <th scope="col" *ngFor="let header of headers">{{ header.name }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">
          <div class="form-check">
            <input type="checkbox" />
          </div>
        </th>
        <td>Mark</td>
        <td>Otto</td>
        <td>@mdo</td>
      </tr>
      <tr>
        <th scope="row">2</th>
        <td>Jacob</td>
        <td>Thornton</td>
        <td>@fat</td>
      </tr>
      <tr>
        <th scope="row">3</th>
        <td>Larry</td>
        <td>the Bird</td>
        <td>@twitter</td>
      </tr>
    </tbody>
  </table> -->
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Upload Devices</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="company-list">
      <label>Company</label>
      <lana-company-selector-select
        [control]="formGroup.get('companyId')"
        [internalCompany]="true"
        [selectedCompany]="selectedCompanyFroms">
      </lana-company-selector-select>
      <lana-error-display
        [control]="formGroup.get('companyId')"
        label="Company"></lana-error-display>
    </div>
    <div class="control-section">
      <div class="uploadtemplate">
        <div id="dropArea">
          <span id="drop" class="droparea">
            Drag and Drop or
            <a href="javascript:void(0)" id="browse"><u>Browse</u></a> to Upload
          </span>
          <ejs-uploader
            #templateupload
            id="templatefileupload"
            [asyncSettings]="path"
            [dropArea]="dropElement"
            (progress)="onFileUpload($event)"
            (selected)="onFileSelect($event)"
            (failure)="onUploadFailed($event)"
            (success)="onUploadSuccess($event)"
            (removing)="onFileRemove($event)"></ejs-uploader>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="ml-auto">
      <button type="button" class="btn btn-primary">Upload</button>
      <button
        type="button"
        class="btn btn-default"
        (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </div>
</ng-template>
<!-- <lana-reports-modal-map #mapChild></lana-reports-modal-map> -->
