<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center">
    <lana-loading></lana-loading>
  </div>
</ng-template>
<ng-container *ngIf="dataSourceSettings; else loadingTemplate">
  <div class="shadow-sm">
    <ejs-pivotview
      #pivotview
      [dataSourceSettings]="dataSourceSettings"
      [gridSettings]="gridSettings"
      [toolbar]="toolbarOptions"
      showToolbar="showToolbar"
      allowExcelExport="allowExcelExport"></ejs-pivotview>
  </div>
</ng-container>
