import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Column, ICellFormatter } from '@syncfusion/ej2-grids';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Roles } from '../models/auth/role';
import { Constants } from './../constants';
import { LanaColumnModel } from './../models/column';
import { FilterSettings } from './../models/filter-settings';
import {
  OdataResponse,
  OdataResponseTransform
} from './../models/odata-response';
import { User, ExportUser } from './../models/user/user';
import { AuthService } from './auth.service';

class RoleFormatter implements ICellFormatter {
  getValue(_: Column, { adUserRoles }: User): string {
    return adUserRoles.length > 0 ? adUserRoles[0].roleName : '';
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private readonly odataUrl = `${Constants.Odata}/Users`;
  private readonly url = `${Constants.Api}/User`;

  readonly columns: LanaColumnModel<User>[] = [
    {
      field: 'companySummary.name',
      headerText: 'Company Name',
      generateUrl: (user) =>
        `/management/sub-companies/${user.companySummary.id}`
    },
    {
      field: 'name',
      headerText: 'Name',
      generateUrl: (user) => `/management/users/${user.id}`
    },
    {
      field: 'userStatusName',
      headerText: 'User Status',
      generateStatus: (user) => user,
      roles: [Roles.Administrator]
    },
    { field: 'username', headerText: 'Username' },
    {
      field: 'adUserRoles',
      headerText: 'Role',
      formatter: RoleFormatter,
      allowSorting: false
    }
  ];

  readonly filterSettings: FilterSettings = {
    columns: [
      {
        field: 'companySummary.name',
        headerText: 'Company Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'name',
        headerText: 'Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'userStatusName',
        headerText: 'User Status',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'username',
        headerText: 'Username',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'adUserRoles[].roleName',
        headerText: 'Role',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  getUsers({
    filterQuery,
    pageQuery,
    sortQuery
  }): Observable<OdataResponseTransform<User>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<User>>(
          `${this.odataUrl}?companyContext=${companyId}&${pageQuery}${
            filterQuery ? `${filterQuery}` : ''
          }${sortQuery}&$count=true&$expand=CompanySummary,AdUserRoles`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getExport({
    filterQuery
  }): Observable<OdataResponseTransform<ExportUser | User>> {
    const pageQuery = '';
    const sortQuery = '';
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<User>>(
          `${this.odataUrl}?companyContext=${companyId}&${
            filterQuery ? `${filterQuery}` : ''
          }&$count=true&$expand=CompanySummary,AdUserRoles`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      map((response) => ({
        result: response.result.map((rec) => {
          try {
            const { adUserRoles, ...rest } = rec;
            return {
              ...rest,
              ...{
                adUserRoles:
                  adUserRoles.length > 0 ? adUserRoles[0].roleName : ''
              }
            };
          } catch (e) {
            return rec;
          }
        }),
        count: response.count
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getSubCompanyUsers(companyId): Observable<OdataResponseTransform<User>> {
    return this.httpClient
      .get<OdataResponse<User>>(
        `${this.odataUrl}?companyContext=${companyId}&$top=1&$filter=CompanySummary/Id eq ${companyId}&$count=true`
      )
      .pipe(
        map((response) => ({
          result: response.value,
          count: response['@odata.count']
        })),
        catchError((_) => of({ result: [], count: 0 }))
      );
  }

  getUser(userId: string): Observable<User> {
    return this.httpClient.get<User>(`${this.url}/${userId}`);
  }

  createUser(user: User): Observable<User> {
    return this.httpClient.post<User>(this.url, user);
  }

  updateUser(user: User): Observable<User> {
    return this.httpClient
      .put<User>(this.url, user)
      .pipe(tap((_) => this.authService.refreshCurrentUser()));
  }

  resendInvite(userId: string): Observable<User> {
    return this.httpClient.post<User>(
      `${this.url}/${userId}/resend-invitation`,
      {}
    );
  }

  deleteUser(id: string): Observable<void> {
    return this.httpClient.request<void>('delete', this.url, { body: { id } });
  }
}
