import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType
} from '@azure/msal-browser';
import { filter, tap } from 'rxjs/operators';
import { ThemeService } from '../app/services/theme.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'lana-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly passwordResetCancellationToken: string = 'AADB2C90091';
  private readonly grantExpiredError: string = 'AADB2C90080';
  constructor(
    private themeService: ThemeService,
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private msalAuthService: MsalService
  ) {}

  title = 'LANA';

  ngOnInit(): void {
    const windowObject: any = window;
    const isMobile = this.themeService.detectMobileDevice();
    this.themeService.structureHostToCss();

    if (!isMobile && !windowObject.Cypress) {
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          ),
          tap((response) => {
            const payload = response.payload as AuthenticationResult;
            if (!this.msalAuthService.instance.getActiveAccount()) {
              this.msalAuthService.instance.setActiveAccount(payload.account);
            }
            this.authService.processAccessToken(payload.accessToken);
          })
        )
        .subscribe();

      // for when user cancels out of password reset. need to catch error and redirect to login
      // or if the grant has expired
      this.msalBroadcastService.msalSubject$
        .pipe(
          tap((response) => {
            if (
              response &&
              response.error &&
              (response.error.message.includes(
                this.passwordResetCancellationToken
              ) ||
                response.error.message.includes(this.grantExpiredError) ||
                response.eventType == EventType.ACQUIRE_TOKEN_FAILURE)
            ) {
              this.authService.logout();
            }
          })
        )
        .subscribe();
    }
  }
}
