<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ operator.name }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-template #defaultBodyTemplate>
      <ng-container *ngIf="readonly">
        <p>Device Alerts do not need a threshold value set.</p>
      </ng-container>
      <form [formGroup]="formGroup">
        <div class="form-group" [hidden]="readonly">
          <label>Threshold Value (in {{ criterionUnit }})</label>
          <ng-container [ngSwitch]="inputDataType">
            <ng-container *ngSwitchCase="'number'">
              <input
                class="form-control"
                type="number"
                formControlName="thresholdValue" />
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input
                class="form-control"
                type="text"
                formControlName="thresholdValue" />
            </ng-container>
          </ng-container>
          <lana-error-display
            [control]="formGroup.get('thresholdValue')"
            label="Threshold Value"></lana-error-display>
        </div>
        <div class="form-group" [hidden]="true">
          <label
            >Choose operation that will link between this trigger to the next
            (if you set another). "And" is set by default.</label
          >
          <select class="form-control" formControlName="operatorId">
            <option [ngValue]="7">And</option>
            <option [ngValue]="8">Or</option>
          </select>
          <lana-error-display
            [control]="formGroup.get('operatorId')"
            label="Operator Id"></lana-error-display>
        </div>
      </form>
    </ng-template>
    <ng-container *ngIf="isGeozone; else defaultBodyTemplate">
      <lana-geozone [step]="step" [formGroup]="formGroup"></lana-geozone>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="step"
      type="button"
      class="btn btn-sm btn-outline-danger mr-auto"
      (click)="remove(modal)">
      Remove
    </button>
    <button
      type="button"
      class="btn btn-sm btn-outline"
      (click)="modal.close()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-sm btn-primary"
      (click)="confirm(formGroup, modal)">
      {{ step ? 'Update' : 'Save' }}
    </button>
  </div>
</ng-template>

<div
  class="card {{ step ? 'step ml-2' : '' }}"
  (click)="addOperator(content)"
  @fadeSlideDown>
  <div class="card-header {{ step ? 'step-header' : '' }}">
    <ng-template #defaultNameTemplate>{{ operator.name }}</ng-template>
    <ng-container *ngIf="step; else defaultNameTemplate">
      <div class="row">
        <div class="col-11 truncate">{{ operator.name }}</div>
        <div class="col-1">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="removeOperator.emit(true)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="card-body {{ step ? 'step' : '' }}">
    <p class="card-text">
      {{ operator.description }}
    </p>
  </div>
  <ng-template #defaultFooter>
    <ng-container *ngIf="!step">
      <div class="card-footer">
        <i class="fas fa-plus-circle"></i> Click here to add to Alert
      </div>
    </ng-container>
  </ng-template>
  <ng-container *ngIf="!readonly && step; else defaultFooter">
    <div class="card-footer text-muted">
      <strong
        [innerHTML]="
          displayValue(evaluationCriteriaModels.thresholdValue)
        "></strong>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="step && lastTrigger">
  <button class="btn btn-sm btn-primary ml-2 mt-2" (click)="addAnother.emit()">
    <i class="fas fa-plus-circle"></i> Add Another Trigger
  </button>
</ng-container>

<div class="arrow-container" [hidden]="!step || hideOperator">
  <div class="step operator-arrow line">|</div>
  <div
    class="step operator-arrow operator-name"
    (click)="changeOperator(formGroup, operator?.operatorId)"
    ngbTooltip="Toggle between And / Or">
    {{ operator?.operatorId === 8 ? 'OR' : 'AND' }}
  </div>
  <div class="step operator-arrow arrow mb-2">
    <div
      class="arrow-image"
      style="
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEzIDMwIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiA8c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDpub25lO3N0cm9rZTojNUI1QjVDO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCgkuc3Qxe2ZpbGw6IzVCNUI1Qzt9PC9zdHlsZT4NCiA8cmVjdCB4PSI5IiB3aWR0aD0iMiIgaGVpZ2h0PSIzNy45NzQiIGZpbGw9IiM1MTUxNTEiIHN0cm9rZS13aWR0aD0iNC41MDYyIi8+DQogPHBvbHlnb24gdHJhbnNmb3JtPSJtYXRyaXgoMS4xNzg1IDAgMCAxLjE3ODUgLS42MDY5MiAyMy41NDQpIiBwb2ludHM9IjEuOTI4IDQuMDY1IDguOTk5IDExLjEzNiAxNi4wNzIgNC4wNjUgMTcuNDg2IDUuNDc5IDguOTk5IDEzLjk2NCAwLjUxNSA1LjQ3OSIgZmlsbD0iIzUxNTE1MSIvPg0KPC9zdmc+DQo=')
          center center no-repeat;
      "></div>
  </div>
</div>
