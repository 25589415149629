<button
  type="button"
  class="btn btn-block"
  [ngClass]="{
    'mt-3 mb-3': !noMargin,
    'btn-primary': primary,
    'btn-outline-danger': danger,
    'btn-success': success
  }"
  [disabled]="loading || disabled"
  (click)="buttonPressed.emit()">
  <ng-template #defaultTemplate>
    <ng-content></ng-content>
  </ng-template>
  <ng-container *ngIf="loading; else defaultTemplate">
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </ng-container>
</button>
