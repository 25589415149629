export interface CategoryMapping {
  count: number;
  categoryName: string;
  queryParams: {
    category: string;
  };
}

export enum Category {
  Assets = 'assets',
  Groups = 'groups',
  CloudBasedGeozones = 'cloudBasedGeozones'
}
