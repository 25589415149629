import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { EmailAddress, User } from './../../../models/user/user';
import { AuthService } from './../../../services/auth.service';

@Component({
  selector: 'lana-subscription-information',
  templateUrl: './subscription-information.component.html',
  styleUrls: ['./subscription-information.component.scss']
})
export class SubscriptionInformationComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) {}

  @Input() user: User;
  @Output() subscriptionAdded = new EventEmitter();

  formGroup: UntypedFormGroup;
  isCurrentUser: Observable<boolean>;
  showConfirm = false;
  readonly loading = new BehaviorSubject(false);

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      id: this.formBuilder.control(null),
      adUserId: this.formBuilder.control(null, Validators.required),
      subscriptionId: this.formBuilder.control(''),
      subscriptionName: this.formBuilder.control({ value: '', disabled: true }),
      subscriptionStatus: this.formBuilder.control({
        value: '',
        disabled: true
      }),
      planId: this.formBuilder.control({ value: '', disabled: true }),
      subscriptionPlan: this.formBuilder.control({ value: '', disabled: true }),
      offerId: this.formBuilder.control({ value: '', disabled: true }),
      deviceCountLimit: this.formBuilder.control({ value: '', disabled: true })
    });

    if (this.user && this.user.subscriptionInformation) {
      this.formGroup.patchValue(this.user.subscriptionInformation);
    }
  }

  updatSubscription(formGroup: UntypedFormGroup) {
    // console.log('formGroup ', formGroup);
  }
}
