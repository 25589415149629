import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doorStatus'
})
export class DoorStatusPipe implements PipeTransform {
  transform(door: boolean): string {
    const status =
      door === null || door === undefined
        ? 'Unknown'
        : door
        ? '<i class="fas fa-door-open"></i> Opened'
        : '<i class="fas fa-door-closed"></i> Closed';

    return `<p><strong>Door<br/>State:</strong></p><p>${status}</p>`;
  }
}
