export interface MenuItem {
  id: number;
  name: string;
  icon?: string;
  url?: string;
  childMenuItems: MenuItem[];
}

export enum MenuItems {
  SwitchCompany = 5,
  ContactUs = 7,
  Administration = 10
}
