import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { tap } from 'rxjs/operators';
import { GlobalSettingsService } from 'src/app/services/global-settings.service';
import { RuntimeConfiguration } from 'src/app/models/global-settings/runtime-configuration';
import { config, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lana-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent implements OnInit {
  constructor(
    private gSettingsService: GlobalSettingsService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService
  ) {}

  settings: any;
  emailFormGroup: UntypedFormGroup;
  controlGroup: {} = {};
  runtimeConfigurations: RuntimeConfiguration[];

  ngOnInit(): void {
    this.gSettingsService
      .getGlobalSettings()
      .pipe(
        tap((resp: RuntimeConfiguration[]) => {
          this.runtimeConfigurations = resp;
          for (const config of this.runtimeConfigurations) {
            this.controlGroup[config.configurationKey] = new UntypedFormControl(
              config.configurationValue || ''
            );
          }
          this.emailFormGroup = this.formBuilder.group(this.controlGroup);
        })
      )
      .subscribe();
  }

  saveConfigurations() {
    let configs = [];
    for (const control of Object.keys(this.controlGroup)) {
      const value = this.emailFormGroup.get(control).value;
      configs.push({ key: control, value: value });
    }
    this.gSettingsService.updateGlobalSettings(configs).subscribe((ret) => {
      if (ret) {
        this.toastr.success('Successfully saved configurations');
      } else {
        this.toastr.error('Failed to save configurations');
      }
    });
  }
}
