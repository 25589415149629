import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { AuthService } from './../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.accessToken$.pipe(
      first(),
      map((response) => response !== null),
      tap((response) => {
        if (!response) {
          const w: any = window;
          if (!w.Cypress) {
            this.router.navigateByUrl('/login');
          }
          //this.authService.logoutAndReauthenticate();
        }
      })
    );
  }
}
