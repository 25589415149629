import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { Constants } from './../constants';
import {
  MapCloudGeozone,
  MapCloudGeozoneGrouping
} from './../models/geozone/map-cloud-geozone';
import { AuthService } from './auth.service';
import { group } from 'console';
import { MapAsset } from './../models/asset/map-asset';

@Injectable({
  providedIn: 'root'
})
export class MapCloudGeozoneService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private readonly bounds = new BehaviorSubject<google.maps.LatLngBounds>(null);
  private readonly mapCloudGeozones = new BehaviorSubject<MapCloudGeozone[]>(
    null
  );
  private readonly mapCloudGeozoneGroupings = new BehaviorSubject<
    MapCloudGeozoneGrouping[]
  >(null);
  private readonly mapCloudGeozoneGrouping = new BehaviorSubject<
    MapCloudGeozoneGrouping[]
  >(null);
  private readonly url = `${Constants.Api}/MapCloudGeozones`;
  public readonly geozoneDirections: { [key: string]: Object }[] = [
    { name: 'Enter Geozone', id: '0', value: 'enters' },
    { name: 'Exit Geozone', id: '1', value: 'exits' },
    { name: 'Enter or Exit Geozone', id: '2', value: 'either enters or exits' },
    { name: 'Inside Geozone', id: '3', value: 'is inside' },
    { name: 'Outside Geozone', id: '4', value: 'is outside' }
  ];

  bounds$ = this.bounds.asObservable();
  mapCloudGeozones$ = this.mapCloudGeozones.pipe(
    filter((mapCloudGeozones) => mapCloudGeozones !== null)
  );
  mapCloudGeozoneGroupings$ = this.mapCloudGeozoneGroupings.pipe(
    filter((mapCloudGeozoneGroupings) => mapCloudGeozoneGroupings !== null)
  );
  mapCloudGeozoneGrouping$ = this.mapCloudGeozoneGrouping.pipe(
    filter((mapCloudGeozoneGrouping) => mapCloudGeozoneGrouping !== null)
  );

  private sortMapCloudGeozoneGroups(
    a: MapCloudGeozone,
    b: MapCloudGeozone
  ): number {
    return b.geozoneGroupName
      ? b.geozoneGroupName > a.geozoneGroupName
        ? -1
        : 1
      : -100;
  }

  private sortMapCloudGeozones(a: MapCloudGeozone, b: MapCloudGeozone): number {
    return b.created ? (b.created > a.created ? -1 : 1) : -100;
  }

  private sortMapCloudGeozonesByName(
    a: MapCloudGeozone,
    b: MapCloudGeozone
  ): number {
    return b.name
      ? b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()
        ? -1
        : 1
      : -100;
  }

  getMapCloudGeozones(
    includeSubCompany = false
  ): Observable<MapCloudGeozone[]> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<MapCloudGeozone[]>(
          `${this.url}?companyContext=${companyId}&includeSubcompany=${includeSubCompany}`
        )
      ),
      catchError((_) => of([] as MapCloudGeozone[])),
      tap((response) => this.mapCloudGeozones.next(response))
    );
  }

  getMapCloudGeozoneGroupingsForMap(
    response
  ): Observable<MapCloudGeozoneGrouping[]> {
    return of(response).pipe(
      map((response) =>
        response.reduce((working, next) => {
          const geozoneId = next.geozoneGroupId || -1;
          const value = working.find(
            ({ geozoneGroupId }) => geozoneId === geozoneGroupId
          );
          if (value) {
            value.geozones = [...value.geozones, next];
          } else {
            working.push({
              geozoneGroupId: geozoneId,
              geozoneGroupName: next.geozoneGroupName,
              geozones: [next]
            });
          }
          return working;
        }, [] as MapCloudGeozoneGrouping[])
      ),
      tap((response) => {
        response.sort((a, b) =>
          b.geozoneGroupName
            ? b.geozoneGroupName > a.geozoneGroupName
              ? -1
              : 1
            : -100
        );
        response.map((geozone) =>
          geozone?.geozoneGroupName
            ? geozone?.geozones.sort(this.sortMapCloudGeozones)
            : geozone?.geozones.sort(this.sortMapCloudGeozonesByName)
        );
        response.map((mapCloudGeozone) =>
          mapCloudGeozone.geozones.map((geozone) =>
            this.mapCloudGeozone(geozone)
          )
        );

        let mergeGeozones = [];

        for (let i = 0; i < response.length; i++) {
          const geozoneGroupInfo = {
            groupName:
              typeof response[i]['geozoneGroupName'] !== 'undefined'
                ? response[i]['geozoneGroupName']
                : 'Ungrouped',
            groupCount: response[i]['geozones'].length
          };
          const geozones = response[i]['geozones'];

          geozones.unshift(geozoneGroupInfo);

          mergeGeozones = [...mergeGeozones, ...geozones];
        }
        this.mapCloudGeozoneGroupings.next(mergeGeozones);
      })
    );
  }

  getMapCloudGeozoneGroupings(): Observable<MapCloudGeozoneGrouping[]> {
    return this.getMapCloudGeozones().pipe(
      map((response) =>
        response.reduce((working, next) => {
          const geozoneId = next.geozoneGroupId || -1;
          const value = working.find(
            ({ geozoneGroupId }) => geozoneId === geozoneGroupId
          );
          if (value) {
            value.geozones = [...value.geozones, next];
          } else {
            working.push({
              geozoneGroupId: geozoneId,
              geozoneGroupName: next.geozoneGroupName,
              geozones: [next]
            });
          }
          return working;
        }, [] as MapCloudGeozoneGrouping[])
      ),
      tap((response) => {
        response.sort((a, b) =>
          b.geozoneGroupName
            ? b.geozoneGroupName > a.geozoneGroupName
              ? -1
              : 1
            : -100
        );
        response.map((geozone) =>
          geozone?.geozoneGroupName
            ? geozone?.geozones.sort(this.sortMapCloudGeozones)
            : geozone?.geozones.sort(this.sortMapCloudGeozonesByName)
        );
        response.map((mapCloudGeozone) =>
          mapCloudGeozone.geozones.map((geozone) =>
            this.mapCloudGeozone(geozone)
          )
        );

        let mergeGeozones = [];

        for (let i = 0; i < response.length; i++) {
          const geozoneGroupInfo: any = {
            groupName:
              typeof response[i]['geozoneGroupName'] !== 'undefined'
                ? response[i]['geozoneGroupName']
                : 'Ungrouped',
            groupCount: response[i]['geozones'].length
          };
          const geozones = response[i]['geozones'];

          geozones.unshift(geozoneGroupInfo);

          mergeGeozones = [...mergeGeozones, ...geozones];
        }
        this.mapCloudGeozoneGroupings.next(mergeGeozones);
        this.mapCloudGeozoneGrouping.next(response);
      })
    );
  }

  getMapCloudGeozoneById(
    mapCloudGeozoneId: number
  ): Observable<MapCloudGeozone> {
    return this.authService.companyId$.pipe(
      switchMap((_) =>
        this.httpClient.get<MapCloudGeozone>(`${this.url}/${mapCloudGeozoneId}`)
      ),
      map((mapCloudGeozone) => this.mapCloudGeozone(mapCloudGeozone))
    );
  }

  getCachedMapCloudGeozoneById(
    mapCloudGeozoneId: number,
    visibleMapAssets: MapAsset[] | null = null
  ): Observable<MapCloudGeozone> {
    return this.mapCloudGeozones$.pipe(
      map((mapCloudGeozones) =>
        this.mapCloudGeozone(
          mapCloudGeozones.find(({ id }) => id === mapCloudGeozoneId)
        )
      ),
      tap((mapCloudGeozone) => {
        if (mapCloudGeozone) {
          const [addressLng, addressLat] =
            mapCloudGeozone.addressPoint.coordinates;
          const bounds = new google.maps.LatLngBounds({
            lat: addressLat,
            lng: addressLng
          });
          const polyCoords = mapCloudGeozone.polygonCoordinates;
          if (polyCoords) {
            const [first] = polyCoords.coordinates;
            first.reduce(
              (working, [polyLng, polyLat]) =>
                working.extend({ lat: polyLat, lng: polyLng }),
              bounds
            );
          }

          if (visibleMapAssets && visibleMapAssets.length > 0) {
            visibleMapAssets.map((asset) => {
              if (asset.lastLatitude && asset.lastLongitude) {
                bounds.extend(
                  new google.maps.LatLng(
                    asset.lastLatitude,
                    asset.lastLongitude
                  )
                );
              }
            });
          }

          setTimeout(() => this.bounds.next(bounds), 0);
        }
      })
    );
  }

  resetBounds(): void {
    this.bounds.next(null);
  }

  private mapCloudGeozone(mapCloudGeozone: MapCloudGeozone): MapCloudGeozone {
    if (mapCloudGeozone) {
      const properties = { color: '#009ede' };
      mapCloudGeozone.addressPointGeoJson = {
        type: 'Feature',
        geometry: mapCloudGeozone.addressPoint
      };
      mapCloudGeozone.paths =
        mapCloudGeozone?.polygonCoordinates?.coordinates.map((values) =>
          Array.isArray(values)
            ? values.map(([lng, lat]) => ({ lat, lng }))
            : null
        );
      mapCloudGeozone.color = '#009ede';
      if (mapCloudGeozone.polygonCoordinates) {
        mapCloudGeozone.polygonCoordinatesGeoJson = {
          type: 'FeatureCollection',
          features: [
            {
              geometry: mapCloudGeozone.polygonCoordinates,
              type: 'Feature',
              properties
            }
          ]
        };
        mapCloudGeozone.polygonStyle = (feature: any) => {
          const color = feature.getProperty('color');
          return {
            clickable: false,
            fillColor: color,
            strokeWeight: 2,
            strokeColor: color
          };
        };
        const bounds = mapCloudGeozone.polygonCoordinates.coordinates.reduce(
          (working, next) =>
            Array.isArray(next)
              ? next.reduce((w, [lg, la]) => {
                  if (typeof lg === 'number' && typeof la === 'number') {
                    return w.extend({ lat: la, lng: lg });
                  }
                  return null;
                }, working)
              : null,
          new google.maps.LatLngBounds()
        );
        if (bounds) {
          const { lat, lng } = bounds.getCenter();
          const latitude = lat();
          const longitude = lng();
          mapCloudGeozone.latitude = latitude;
          mapCloudGeozone.longitude = longitude;
        }
      }
    }
    return mapCloudGeozone;
  }
}
