import { WidgetCategory } from 'src/app/models/dashboard/widgets';

export const WidgetCategories: WidgetCategory[] = [
  {
    name: 'Chart widgets',
    key: 'chart',
    // icon: 'fas fa-chart-line',
    icon: '/assets/dashboard/chart-widgets.png',
    description:
      'Display timeseries data using customizable line and bar charts. Use various pie charts to display latest values.',
    url: 'WidgetCharts'
  },
  // {
  //   name: 'Alert widgets',
  //   key: 'small',
  //   icon: '/assets/dashboard/table.png',
  //   description: 'Visualization of alerts for assets, trackers and other entities.',
  //   url: 'WidgetSmallTiles',
  // },
  {
    name: 'Report widgets',
    key: 'report',
    icon: '/assets/dashboard/report-icon.png',
    description:
      'Historical and analytical reports in tables that can be sorted and exported.',
    url: 'WidgetSmallTiles'
  },
  {
    name: 'Gauge widgets',
    key: 'gauge',
    icon: '/assets/dashboard/gauge-widgets.png',
    description:
      'Display temperature, humidity, and speed with the latest values in gauge widgets.',
    url: 'WidgetTables'
  },
  {
    name: 'LANA Vision widgets',
    key: 'vision',
    icon: '/assets/dashboard/vision-widgets.png',
    description: 'Display LANA Vision images and telemetry data.',
    url: 'WidgetVision'
  }
  // {
  //   name: 'Map widgets',
  //   key: 'map',
  //   icon: '/assets/dashboard/map-widgets.png',
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  //   url: 'WidgetMaps',
  // },
];

export const WidgetCharts: WidgetCategory[] = [
  // {
  //   name: 'Pie Chart',
  //   key: 'pie',
  //   icon: '/assets/dashboard/pie-chart.png',
  //   description: 'Display latest values on various pie charts.',
  // },
  {
    name: 'Line Chart',
    key: 'line',
    // icon: 'fas fa-chart-line',
    icon: '/assets/dashboard/line-chart.png',
    description: 'Display timeseries data using customizable line charts.'
  },
  {
    name: 'Bar Chart',
    key: 'bar',
    icon: '/assets/dashboard/bar-chart.png',
    // icon: 'fas fa-chart-bar',
    description: 'Display timeseries data using customizable bar charts.'
  }
];

export const WidgetGuages: WidgetCategory[] = [
  {
    name: 'Thermometer Gauge',
    key: 'thermometer',
    icon: '/assets/dashboard/thermo-meter.png',
    description:
      'Preconfigured gauge to display temperature similiar to a thermometer.'
  },

  {
    name: 'Speedometer Gauge',
    key: 'speedometer',
    icon: '/assets/dashboard/speedo-meter.png',
    description: 'Preconfigured gauge to display speed.'
  },
  {
    name: 'Humidity Gauge',
    key: 'humiditymeter',
    icon: '/assets/dashboard/humidity-icon.png',
    description:
      'Preconfigured gauge to display humidity similiar to a thermometer.'
  },
  {
    name: 'Fill Percentage Gauge',
    key: 'fillpercentage',
    icon: '/assets/dashboard/fill-percentage.png',
    description: 'Preconfigured gauge to display full or empty percentage.'
  }
];

export const WidgetReports: WidgetCategory[] = [
  {
    name: 'Alert History Report',
    key: 'alertreport',
    icon: '/assets/dashboard/alert-history.png',
    description:
      'Displays your Alert History Report with ability of full text search and pagination.'
  },
  {
    name: 'Asset Awareness Report',
    key: 'asset-awareness-report',
    icon: '/assets/dashboard/map-widgets.png',
    description:
      'Displays your Asset Awareness Report with ability of full text search and pagination.'
  },
  {
    name: 'Data Log',
    key: 'datalog-report',
    icon: '/assets/dashboard/data-log-icon.png',
    description:
      'Displays your Data Log with ability of full text search and pagination.'
  },
  {
    name: 'Command History Report',
    key: 'command-history-report',
    icon: '/assets/dashboard/command-history.png',
    description:
      'Displays your Command History Report with ability of full text search and pagination.'
  },
  {
    name: 'Cargo History Report',
    key: 'cargo-history-report',
    icon: '/assets/dashboard/cargo-history-report.png',
    description:
      'Displays your Cargo History Report with ability of full text search and pagination.'
  },
  {
    name: 'Periodic Events',
    key: 'periodic-events-report',
    icon: '/assets/dashboard/periodic-event.png',
    description:
      'Displays your Periodic Events Report with ability of full text search and pagination.'
  },
  {
    name: 'Dwell And Demurrage',
    key: 'dwell-and-demurrage-report',
    icon: '/assets/dashboard/dwel.jpg',
    description:
      'Displays your Dwell And Demurrage Report with ability of full text search and pagination.'
  },
  {
    name: 'Climate',
    key: 'climate-report',
    icon: '/assets/dashboard/climate.png',
    description:
      'Displays your Climate Report with ability of full text search and pagination.'
  },
  {
    name: 'Mileage',
    key: 'mileage-report',
    icon: '/assets/dashboard/mileage.png',
    description:
      'Displays your Mileage Report with ability of full text search and pagination.'
  }
];

export const PieCharts: WidgetCategory[] = [
  {
    name: 'Temperature Pie Chart',
    key: 'pie',
    icon: 'fas fa-chart-line',
    description: 'Display timeseries data using customizable line charts.'
  }
];

export const LineCharts: WidgetCategory[] = [
  {
    name: 'Temperature Line Chart',
    key: 'line',
    icon: 'fas fa-chart-line',
    description: 'Display timeseries data using customizable line charts.'
  }
];

export const BarCharts: WidgetCategory[] = [
  {
    name: 'Temperature Bar Chart',
    key: 'bar',
    icon: 'fas fa-chart-line',
    description: 'Display timeseries data using customizable line charts.'
  }
];

export const LanaVision: WidgetCategory[] = [
  {
    name: 'LANA Vision',
    key: 'vision',
    icon: '/assets/dashboard/vision-widgets.png',
    description: 'Display LANA Vision images and telemetry data.'
  }
];
