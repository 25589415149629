import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageFull'
})
export class PercentageFullPipe implements PipeTransform {
  transform(full: number): string {
    if (full === null || full === undefined) {
      return 'Calibrating';
    } else {
      return parseFloat(full.toFixed(1)) + '%';
    }
  }
}
