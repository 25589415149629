import { Column } from '@syncfusion/ej2-grids';

export class ZeroFormatter {
  static getValue<T>(prop: string) {
    return (_: Column, model: T) => {
      const loweredProp = prop.toLowerCase();

      if (
        loweredProp.includes('assetbattery') ||
        loweredProp.includes('trackerbattery')
      ) {
        const isWired = model['isWiredAsset'];
        const battery = model[prop];

        if (typeof battery !== 'undefined' && battery !== null) {
          return Math.round(battery * 10) / 10;
        } else if (isWired) {
          return 'No Data Available';
        } else {
          return "<span style='color: #A9A9A9'>N/A</span>";
        }
      }
      return typeof model[prop] !== 'undefined' ? model[prop] : 0;
    };
  }
}
