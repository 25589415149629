import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Constants } from './../constants';
import { CreateMetaAssetMake, MetaAssetMake } from './../models/asset/asset';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AssetMakeService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private odataUrl = `${Constants.Odata}/MetaAssetMakes?$expand=MetaAssetType`;
  private url = `${Constants.Api}/MetaAssetMake`;

  getAssetMakes(metaAssetTypeId: number): Observable<MetaAssetMake[]> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<MetaAssetMake>(
          `${this.odataUrl}&companyContext=${companyId}&$filter=MetaAssetType/Id eq ${metaAssetTypeId}`
        )
      ),
      map((response) => response['value']),
      catchError((_) => of({ value: [] }))
    );
  }

  createAssetMake(model: CreateMetaAssetMake): Observable<MetaAssetMake> {
    return this.httpClient.post<MetaAssetMake>(this.url, model);
  }
}
