import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import { MapCloudGeozone } from './../../models/geozone/map-cloud-geozone';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'lana-cloud-based-geozone-list-item',
  templateUrl: './cloud-based-geozone-list-item.component.html',
  styleUrls: ['./cloud-based-geozone-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloudBasedGeozoneListItemComponent {
  @Input() geozone: MapCloudGeozone;
  @Input() mapCloudGeozoneGroupings: any;
  @Input() scrollToIndex: number;
  @Input() currentlyShowingHoverGeozoneData: MapCloudGeozone;
  @Output() showGeozoneOnMap = new EventEmitter<MapCloudGeozone>();
  @Output() hideGeozoneOnMap = new EventEmitter<MapCloudGeozone>();

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  ngOnChanges() {
    if (this.scrollToIndex && this.viewPort) {
      this.viewPort.scrollToIndex(this.scrollToIndex, 'smooth');
    }
  }
}
