import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lana-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() fullscreen = false;
  @Input() padding = 'p-5';
}
