import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ɵstringify as stringify
} from '@angular/core';
import { AdministrationService } from '@app/services/administration.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Roles } from './../../models/auth/role';
import { AuthService } from './../../services/auth.service';

@Directive({
  selector: '[lanaHasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input('moduleType') moduleType: string;
  @Input('accessType') accessType: string;
  administration: any;
  constructor(
    private elementRef: ElementRef,
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    private administrationService: AdministrationService
  ) {}
  ngOnInit() {
    this.administrationService.administration.subscribe((res) => {
      this.administration = res;
    });
    this.elementRef.nativeElement.style.display = 'none';
    this.checkAccess();
  }
  checkAccess() {
    const accessControls: any = this.administration;
    let module: any;
    accessControls.find((access) => {
      if (access.children.length > 0) {
        module = access.children.find((n) => n.moduleType === this.moduleType);
        if (module !== undefined)
          this.elementRef.nativeElement.style.display = module[this.accessType]
            ? 'block'
            : 'none';
      } else {
        if (access.moduleType === this.moduleType) {
          module = access;
          if (module !== undefined)
            this.elementRef.nativeElement.style.display = module[
              this.accessType
            ]
              ? 'block'
              : 'none';
        }
      }
    });
  }
}
