import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ScheduleModalComponent } from './schedule-modal/schedule-modal.component';

@Component({
  selector: 'lana-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() priorityLevels;

  public fields: Object = { text: 'name', value: 'id' };
  public dropdownValue: number = 2;

  @Output() alertSchedule = new EventEmitter<any>();
  @Output() alertTemplatePriorityLevelId = new EventEmitter<number>();
  @ViewChild(ScheduleModalComponent)
  scheduleModal: ScheduleModalComponent;

  constructor() {}

  ngOnInit(): void {
    this.dropdownValue =
      this.formGroup.get('alertTemplatePriorityLevelId').value ?? 2;
  }

  open() {
    this.scheduleModal.openModal();
  }

  valueChange(value) {
    this.dropdownValue = value;
    this.alertTemplatePriorityLevelId.emit(value);
  }
}
