import { Column } from '@syncfusion/ej2-grids';

function index(obj, i) {
  return obj[i];
}

export class EmptyFormatter {
  static getValue<T>(prop: string) {
    return (_: Column, model: T) => {
      const arr = prop.split('.');
      const isPercent = prop.toLowerCase().includes('percent');

      if (typeof model[arr[0]] !== 'undefined') {
        try {
          const val = arr.reduce(index, model);
          return val
            ? isPercent
              ? `${val}%`
              : val
            : "<span style='color: #A9A9A9'>N/A</span>";
        } catch (e) {
          return "<span style='color: #A9A9A9'>N/A</span>";
        }
      }
      return "<span style='color: #A9A9A9'>N/A</span>";
    };
  }
}
