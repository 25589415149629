<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Contact Us</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="dismiss(modal)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-3">
        <div class="card">
          <div class="card-body">
            <p class="card-text"><small class="text-muted">Phone</small></p>
            <p class="card-text">(877) 324-0999</p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <p class="card-text"><small>Email</small></p>
            <p class="card-text">
              <a href="mailto:support@lanaasset.com">support@lanaasset.com</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="card">
          <div class="card-body">
            <p class="card-text"><small>Address</small></p>
            <p class="card-text">
              4592 Ulmerton RD Suite 201<br />Clearwater FL, 33762
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a
      href="mailto:support@lanaasset.com"
      target="_blank"
      class="btn btn-primary float-right"
      >Create a Support Ticket</a
    >
  </div>
</ng-template>
