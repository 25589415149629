import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lana-password-display',
  templateUrl: './password-display.component.html',
  styleUrls: ['./password-display.component.scss']
})
export class PasswordDisplayComponent implements OnInit {
  constructor() {}

  @Input() passwordFormGroup: UntypedFormGroup;
  @Input() buttonText: string = 'Update Password';
  @Input() buttonStyle: string = 'width: 100%';
  @Output() passwordButton = new EventEmitter<any>();
  @ViewChild('length') length;
  @ViewChild('letter') letter;
  @ViewChild('number') number;
  @ViewChild('character') character;
  @ViewChild('confirmPassword') confirmPassword;
  @ViewChild('match') match;

  validCount = 0;
  passwordsMatch = false;

  regExp = {
    containsNumber: /\d+/,
    containsAlphabet: /[a-zA-Z]/,
    containsSpecial: /[~`!#@$%\^&*+=\-\[\]\\:<>\?]/
  };

  validate(value: string) {
    this.length.nativeElement.style = '';
    this.letter.nativeElement.style = '';
    this.number.nativeElement.style = '';
    this.character.nativeElement.style = '';
    this.validCount = 0;

    if (value.length > 6) {
      this.length.nativeElement.style =
        'text-decoration: line-through; color: #23b123';
      this.validCount++;
    }
    if (this.regExp.containsNumber.test(value)) {
      this.number.nativeElement.style =
        'text-decoration: line-through; color: #23b123';
      this.validCount++;
    }
    if (this.regExp.containsAlphabet.test(value)) {
      this.letter.nativeElement.style =
        'text-decoration: line-through; color: #23b123';
      this.validCount++;
    }
    if (this.regExp.containsSpecial.test(value)) {
      this.character.nativeElement.style =
        'text-decoration: line-through; color: #23b123';
      this.validCount++;
    }
    // revalidate match
    this.validateMatch(value, this.confirmPassword.nativeElement.value);
  }

  validateMatch(password: string, confirmPassword: string) {
    this.passwordsMatch = false;
    this.match.nativeElement.style = '';
    if (confirmPassword.length > 0 && password === confirmPassword) {
      this.passwordsMatch = true;
      this.match.nativeElement.style =
        'text-decoration: line-through; color: #23b123';
    }
  }

  ngOnInit(): void {}
}
