<form [formGroup]="passwordFormGroup">
  <ng-container *ngIf="passwordFormGroup.get('oldPassword')">
    <div class="form-group">
      <label>Old Password</label>
      <div class="form-row">
        <div class="col-11">
          <input
            type="password"
            class="form-control"
            placeholder="Old Password"
            formControlName="oldPassword" />
          <lana-error-display
            [control]="passwordFormGroup.get('oldPassword')"
            label="Old Password">
          </lana-error-display>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="form-group">
    <label>New Password</label>
    <div class="form-row">
      <div class="col-11">
        <input
          [style]="validCount === 4 ? 'border-color: #23b123' : ''"
          type="password"
          class="form-control"
          placeholder="New Password"
          formControlName="password"
          (keyup)="validate(password.value)"
          #password />
      </div>
      <div class="col-1" [hidden]="validCount < 4">
        <i class="fas fa-check-circle"></i>
      </div>
    </div>
    <small id="passwordHelpBlock" class="form-text">
      <ul>
        <li #length>Must be at least 6 characters long</li>
        <li #letter>Must contain at least one letter</li>
        <li #number>Must contain at least one number</li>
        <li #character>
          Must contain at least one special character
          <br />(!,@,#,$,%,^,&,*,(,),.,?,<,>,~)
        </li>
      </ul>
    </small>
    <lana-error-display
      [control]="passwordFormGroup.get('password')"
      label="New Password">
    </lana-error-display>
  </div>
  <div class="form-group">
    <label>Confirm New Password</label>
    <div class="form-row">
      <div class="col-11">
        <input
          type="password"
          class="form-control"
          placeholder="Confirm New Password"
          formControlName="confirmPassword"
          (keyup)="validateMatch(password.value, confirmPassword.value)"
          #confirmPassword
          [style]="
            confirmPassword.value.length > 0 && passwordsMatch
              ? 'border-color: #23b123'
              : ''
          " />
        <small id="passwordHelpBlock" class="form-text">
          <ul>
            <li #match>New password and confirm password match</li>
          </ul>
        </small>
        <lana-error-display
          [control]="passwordFormGroup.get('confirmPassword')"
          label="Confirm New Password">
        </lana-error-display>
        <lana-error-display
          [control]="passwordFormGroup"
          [labels]="['Confirm New Password', 'New Password']">
        </lana-error-display>
      </div>
      <div class="col-1" [hidden]="!passwordsMatch">
        <i class="fas fa-check-circle"></i>
      </div>
    </div>
  </div>
  <hr />
  <button
    type="button"
    class="btn btn-primary float-right"
    (click)="passwordButton.emit(passwordFormGroup)"
    [style]="buttonStyle">
    {{ buttonText }}
  </button>
</form>
