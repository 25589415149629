import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    this.injector.get<LoggingService>(LoggingService).logException(error);
    console.error(error);
  }
}
