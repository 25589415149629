import {
  Asset,
  MetaAssetMake,
  MetaAssetModel,
  MetaAssetType
} from './../../models/asset/asset';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  skip,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';

import * as moment from 'moment';

import { AssetMakeService } from './../../services/asset-make.service';
import { AssetModelService } from './../../services/asset-model.service';
import { AssetService } from './../../services/asset.service';
import { AssetTypeService } from './../../services/asset-type.service';
import { AuthService } from './../../services/auth.service';
import { ContactUsComponent } from './../contact-us/contact-us.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Roles } from 'src/app/models/auth/role';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lana-asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.scss']
})
export class AssetFormComponent implements OnInit, OnDestroy {
  constructor(
    private assetMakeService: AssetMakeService,
    private assetModelService: AssetModelService,
    private assetService: AssetService,
    private assetTypeService: AssetTypeService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private ngbModel: NgbModal,
    private router: Router,
    private toastr: ToastrService
  ) {}

  private readonly destroy = new Subject<void>();
  private readonly reload = new BehaviorSubject<void>(null);
  readonly roles = Roles;
  readonly loading = new BehaviorSubject(false);

  @Input() asset: Asset;
  @Input() standalone = true;
  @Input() submitClicked: Subject<void>;
  @Input() trackerId: number;

  @Output() assetCreated = new EventEmitter<Asset>();
  @ViewChild(ContactUsComponent) contactUs: ContactUsComponent;

  assetTypeFormControl: UntypedFormControl;
  assetMakeFormControl: UntypedFormControl;
  assetModelFormControl: UntypedFormControl;
  assetTypes: Observable<MetaAssetType[]>;
  assetMakes: Observable<MetaAssetMake[]>;
  assetModels: Observable<MetaAssetModel[]>;
  formGroup: UntypedFormGroup;
  selectedAssetType: Observable<MetaAssetType>;
  selectedAssetMake: Observable<MetaAssetMake>;
  selectedAssetModel: Observable<MetaAssetModel>;
  showConfirm = false;

  createAssetType(
    assetTypeFormControl: UntypedFormControl,
    modal: NgbActiveModal
  ): void {
    if (assetTypeFormControl.valid) {
      this.authService.companyId$
        .pipe(
          first(),
          concatMap((companyId) =>
            this.assetTypeService.createAssetModel({
              name: assetTypeFormControl.value,
              companyId
            })
          ),
          first(),
          tap((_) => this.reload.next()),
          tap((response) =>
            this.formGroup.get('metaAssetTypeId').setValue(response.id)
          ),
          tap((_) => modal.close()),
          tap((_) => assetTypeFormControl.reset())
        )
        .subscribe();
    }
  }

  createAssetMake(
    assetMakeFormControl: UntypedFormControl,
    metaAssetTypeId: number,
    modal: NgbActiveModal
  ): void {
    if (assetMakeFormControl.valid) {
      const model = { name: assetMakeFormControl.value, metaAssetTypeId };

      this.assetMakeService
        .createAssetMake(model)
        .pipe(
          first(),
          tap((_) => this.reload.next()),
          tap((response) =>
            this.formGroup.get('metaAssetMakeId').setValue(response.id)
          ),
          tap((_) => modal.close()),
          tap((_) => assetMakeFormControl.reset())
        )
        .subscribe();
    }
  }

  createAssetModel(
    assetModelFormControl: UntypedFormControl,
    metaAssetMakeId: number,
    modal: NgbActiveModal
  ): void {
    if (assetModelFormControl.valid) {
      const model = { name: assetModelFormControl.value, metaAssetMakeId };

      this.assetModelService
        .createAssetModel(model)
        .pipe(
          first(),
          tap((_) => this.reload.next()),
          tap((response) =>
            this.formGroup.get('metaAssetModelId').setValue(response.id)
          ),
          tap((_) => modal.close()),
          tap((_) => assetModelFormControl.reset())
        )
        .subscribe();
    }
  }

  deleteAsset(assetId: number): void {
    this.loading.next(true);
    this.assetService
      .removeAsset(assetId)
      .pipe(
        first(),
        tap(() => this.router.navigateByUrl('/management/assets')),
        tap((_) => this.loading.next(false))
      )
      .subscribe();
  }

  openModal(content: any) {
    this.ngbModel.open(content);
  }

  toggleConfirm(): void {
    this.showConfirm = !this.showConfirm;
  }

  showSuccess() {
    this.toastr.success('Changes saved successfully', 'Success!');
  }

  showError() {
    this.toastr.error(
      'If you continue to get this error, please <span class="toast-contact-us">contact us</span> and let us know',
      'An error has occurred',
      {
        timeOut: 5000,
        enableHtml: true
      }
    );

    const elements = document.getElementsByClassName('toast-contact-us');

    setTimeout(() => {
      Array.from(elements).forEach((element: any) => {
        element.addEventListener('click', () => {
          this.contactUs.openModel();
        });
      });
    }, 0);
  }

  private handleError(
    error: any,
    formGroup: UntypedFormGroup
  ): Observable<never> {
    const errors = error?.error?.errors;

    if (
      error instanceof HttpErrorResponse &&
      error.status === 400 &&
      errors &&
      Object.keys(errors).length > 0
    ) {
      Object.keys(errors)
        .filter((key) => Array.isArray(errors[key]) && formGroup.contains(key))
        .forEach((key) => formGroup.get(key).setErrors({ [key]: errors[key] }));
    } else {
      this.showError();
    }

    return throwError(error);
  }

  submit(formGroup: UntypedFormGroup, updateAsset: boolean): void {
    formGroup.markAllAsTouched();

    if (formGroup.valid) {
      this.loading.next(true);
      if (updateAsset) {
        this.assetService
          .updateAsset(formGroup.value)
          .pipe(
            first(),
            tap((_) => this.showSuccess()),
            tap((_) => this.loading.next(false)),
            catchError((error) => {
              this.handleError(error, formGroup);
              this.loading.next(false);
              return throwError(error);
            })
          )
          .subscribe();
      } else {
        this.assetService
          .createAsset(formGroup.value)
          .pipe(
            first(),
            tap((response) =>
              this.standalone
                ? this.router.navigateByUrl(`/management/assets/${response.id}`)
                : this.assetCreated.emit(response)
            ),
            tap((_) => this.showSuccess()),
            tap((_) => this.loading.next(false)),
            catchError((error) => {
              this.handleError(error, formGroup);
              this.loading.next(false);
              return throwError(error);
            })
          )
          .subscribe();
      }
    }
  }

  validDate(date) {
    return new Date().getFullYear() - 100 < new Date(date).getFullYear();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      id: this.formBuilder.control(null),
      trackerId: this.formBuilder.control(null),
      companyId: this.formBuilder.control(null, Validators.required),
      name: this.formBuilder.control(
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)])
      ),
      metaAssetTypeId: this.formBuilder.control(null, Validators.required),
      metaAssetMakeId: this.formBuilder.control(null, Validators.required),
      metaAssetModelId: this.formBuilder.control(null, Validators.required),
      assetYear: this.formBuilder.control(
        null,
        Validators.compose([
          Validators.min(new Date().getFullYear() - 100),
          Validators.max(new Date().getFullYear() + 3)
        ])
      ),
      isWiredAsset: this.formBuilder.control(null),
      licensePlate: this.formBuilder.control('', Validators.maxLength(100)),
      vin: this.formBuilder.control('', Validators.maxLength(100)),
      inspectionDueDate: this.formBuilder.control(null),
      dateDamaged: this.formBuilder.control(null),
      comment: this.formBuilder.control(''),
      initialEngineHours: this.formBuilder.control(null)
    });

    const metaAssetTypeId$ = this.formGroup
      .get('metaAssetTypeId')
      .valueChanges.pipe(
        startWith(
          this.asset && this.asset.metaAssetDetails
            ? this.asset.metaAssetDetails.metaAssetTypeId
            : null
        ),
        distinctUntilChanged(),
        shareReplay(1)
      );
    const metaAssetMakeId$ = this.formGroup
      .get('metaAssetMakeId')
      .valueChanges.pipe(
        startWith(
          this.asset && this.asset.metaAssetDetails
            ? this.asset.metaAssetDetails.metaAssetMakeId
            : null
        ),
        distinctUntilChanged(),
        shareReplay(1)
      );

    const metaAssetModelId$ = this.formGroup
      .get('metaAssetModelId')
      .valueChanges.pipe(
        startWith(
          this.asset && this.asset.metaAssetDetails
            ? this.asset.metaAssetDetails.metaAssetModelId
            : null
        ),
        distinctUntilChanged(),
        shareReplay(1)
      );

    this.assetTypes = this.reload.pipe(
      switchMap(() => this.assetTypeService.getAssetTypes()),
      map((res) => {
        try {
          return res.sort((a: MetaAssetType, b: MetaAssetType) => {
            return a.name < b.name ? -1 : 1;
          });
        } catch (e) {
          return res;
        }
      }),
      shareReplay(1)
    );
    this.assetMakes = metaAssetTypeId$.pipe(
      tap((_) => this.formGroup.get('metaAssetMakeId').disable()),
      map((value) => +value),
      switchMap((metaAssetTypeId) =>
        metaAssetTypeId
          ? this.reload.pipe(
              switchMap(() =>
                this.assetMakeService.getAssetMakes(metaAssetTypeId)
              )
            )
          : of([])
      ),
      map((res) => {
        try {
          return res.sort((a: MetaAssetMake, b: MetaAssetMake) => {
            return a.name < b.name ? -1 : 1;
          });
        } catch (e) {
          return res;
        }
      }),
      tap((_) => this.formGroup.get('metaAssetMakeId').enable()),
      shareReplay(1)
    );
    this.assetModels = metaAssetMakeId$.pipe(
      tap((_) => this.formGroup.get('metaAssetModelId').disable()),
      map((value) => +value),
      switchMap((metaAssetMakeId) =>
        metaAssetMakeId
          ? this.reload.pipe(
              switchMap(() =>
                this.assetModelService.getAssetModels(metaAssetMakeId)
              )
            )
          : of([])
      ),
      map((res) => {
        try {
          return res.sort((a: MetaAssetModel, b: MetaAssetModel) => {
            return a.name < b.name ? -1 : 1;
          });
        } catch (e) {
          return res;
        }
      }),
      tap((_) => this.formGroup.get('metaAssetModelId').enable()),
      shareReplay(1)
    );

    this.selectedAssetType = metaAssetTypeId$.pipe(
      switchMap((metaAssetTypeId) =>
        this.assetTypes.pipe(
          map((assetTypes) =>
            assetTypes.find((assetType) => assetType.id === metaAssetTypeId)
          )
        )
      ),
      distinctUntilChanged()
    );

    this.selectedAssetMake = metaAssetMakeId$.pipe(
      switchMap((metaAssetMakeId) =>
        this.assetMakes.pipe(
          map((assetMakes) =>
            assetMakes.find((assetMake) => assetMake.id === metaAssetMakeId)
          )
        )
      ),
      distinctUntilChanged()
    );

    this.selectedAssetModel = metaAssetModelId$.pipe(
      switchMap((metaAssetModelId) =>
        this.assetModels.pipe(
          map((assetModels) =>
            assetModels.find((assetModel) => assetModel.id === metaAssetModelId)
          )
        )
      ),
      distinctUntilChanged()
    );

    if (this.asset) {
      this.formGroup.patchValue(this.asset);
      this.formGroup.get('companyId').setValue(this.asset.companySummary.id);

      if (this.asset.inspectionDueDate) {
        this.formGroup
          .get('inspectionDueDate')
          .setValue(moment(this.asset.inspectionDueDate).format('YYYY-MM-DD'));
      }
      if (this.asset.dateDamaged) {
        this.formGroup
          .get('dateDamaged')
          .setValue(moment(this.asset.dateDamaged).format('YYYY-MM-DD'));
      }

      if (this.asset.metaAssetDetails) {
        this.formGroup
          .get('metaAssetTypeId')
          .setValue(this.asset.metaAssetDetails.metaAssetTypeId);
        this.formGroup
          .get('metaAssetMakeId')
          .setValue(this.asset.metaAssetDetails.metaAssetMakeId);
        this.formGroup
          .get('metaAssetModelId')
          .setValue(this.asset.metaAssetDetails.metaAssetModelId);
      }
    } else {
      this.authService.companyId$
        .pipe(
          first(),
          tap((companyId) =>
            this.formGroup.get('companyId').setValue(companyId)
          )
        )
        .subscribe();
    }

    if (this.trackerId) {
      this.formGroup.get('trackerId').setValue(this.trackerId);
    }

    metaAssetTypeId$
      .pipe(
        skip(1),
        takeUntil(this.destroy),
        tap((_) => this.formGroup.get('metaAssetMakeId').reset()),
        tap((_) => this.formGroup.get('metaAssetModelId').reset())
      )
      .subscribe();

    metaAssetMakeId$
      .pipe(
        skip(1),
        takeUntil(this.destroy),
        tap((_) => this.formGroup.get('metaAssetModelId').reset())
      )
      .subscribe();

    this.assetTypeFormControl = this.formBuilder.control(
      '',
      Validators.required
    );
    this.assetMakeFormControl = this.formBuilder.control(
      '',
      Validators.required
    );
    this.assetModelFormControl = this.formBuilder.control(
      '',
      Validators.required
    );

    if (this.submitClicked) {
      this.submitClicked
        .pipe(
          takeUntil(this.destroy),
          tap(() => this.submit(this.formGroup, !!this.asset))
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
