<div class="row">
  <div class="col">
    <h5 class="card-title">When would you like to receive these alerts?</h5>
  </div>
</div>
<div class="row">
  <div class="col">
    <button class="btn btn-outline-primary" (click)="open()">
      <i class="fas fa-calendar-alt"></i> Alert Schedule
    </button>
  </div>
</div>
<div class="row mt-3">
  <div class="col">
    <h5 class="card-title">What is the priority level of this alert?</h5>
  </div>
</div>
<div class="row">
  <div class="col-4">
    <div class="form-group">
      <ng-template #loadingTemplate>
        <lana-loading></lana-loading>
      </ng-template>
      <ng-container
        *ngIf="priorityLevels | async as data; else loadingTemplate">
        <ejs-dropdownlist
          [dataSource]="data"
          [fields]="fields"
          [value]="dropdownValue"
          [cssClass]="'dd-priority'"
          (change)="valueChange(dropdown.value)"
          #dropdown></ejs-dropdownlist>
      </ng-container>
    </div>
  </div>
</div>
<lana-schedule-modal
  (alertSchedule)="alertSchedule.emit($event)"
  [formGroup]="formGroup"></lana-schedule-modal>
