import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  DropDownListComponent,
  MultiSelectComponent,
  RemoveEventArgs,
  SelectEventArgs
} from '@syncfusion/ej2-angular-dropdowns';
import {
  MetaAssetMake,
  MetaAssetModel,
  MetaAssetType
} from 'src/app/models/asset/asset';
import { animate, style, transition, trigger } from '@angular/animations';

import { AssetMakeService } from 'src/app/services/asset-make.service';
import { AssetModelService } from 'src/app/services/asset-model.service';
import { AssetTypeService } from 'src/app/services/asset-type.service';

@Component({
  selector: 'lana-asset-category-browser',
  templateUrl: './asset-category-browser.component.html',
  styleUrls: ['./asset-category-browser.component.scss'],
  animations: [
    trigger('enterExitLeft', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-200px)' }),
        animate(
          '300ms ease-in',
          style({ opacity: 1, transform: 'translateX(0)' })
        )
      ]),
      transition(':leave', [
        animate(
          '300ms ease-in',
          style({ opacity: 0, transform: 'translateX(-200px)' })
        )
      ])
    ]),
    trigger('fadeSlideDown', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' }))
      ])
    ])
  ]
})
export class AssetCategoryBrowserComponent {
  @Input() header: string;
  @Input() assetTypes: MetaAssetType[];

  private selectedAssetTypeList: MetaAssetType[];

  get selectedAssetTypes(): MetaAssetType[] {
    return this.selectedAssetTypeList;
  }

  @Input()
  set selectedAssetTypes(selectedAssetTypes: MetaAssetType[]) {
    this.assetTypeService.getAssetTypes().subscribe((r) => {
      this.selectedAssetTypeList = r?.filter((i) =>
        selectedAssetTypes.some((t) => t.id === i.id)
      );

      // TODO: check if we should automatically select asset type if there is only one.
      // if (1 === this.selectedAssetTypeList.length) {
      // this.assetTypeList.text = this.selectedAssetTypeList[0].name;
      // }
    });
  }

  private selectedMakeList: MetaAssetMake[];

  get selectedMakes(): MetaAssetMake[] {
    return this.selectedMakeList;
  }

  @Input()
  set selectedMakes(selectedMakes: MetaAssetMake[]) {
    this.selectedMakeList = selectedMakes;
  }

  private selectedModelList: MetaAssetModel[];

  get selectedModels(): MetaAssetModel[] {
    return this.selectedModelList;
  }

  @Input()
  set selectedModels(selectedModels: MetaAssetModel[]) {
    this.selectedModelList = selectedModels;
  }

  @Output() modelAdd = new EventEmitter<any>();
  @Output() modelRemove = new EventEmitter<any>();

  @ViewChild('assetType') assetTypeList: DropDownListComponent;
  @ViewChild('make') makeList: DropDownListComponent;
  @ViewChild('model') modelList: MultiSelectComponent;

  assetTypeWatermark: string = 'Please select an asset type';

  makes: MetaAssetMake[];
  makeWatermark: string = 'Please select a make';

  models: MetaAssetModel[];
  modelWatermark: string = 'Please select a model';

  public fields: Object = { text: 'name', id: 'id' };

  constructor(
    private assetTypeService: AssetTypeService,
    private assetMakeService: AssetMakeService,
    private assetModelService: AssetModelService
  ) {}

  assetTypeChange(e: SelectEventArgs): void {
    this.modelList.enabled = false;
    this.modelList.text = null;

    this.assetMakeService
      .getAssetMakes(this.assetTypes[this.assetTypeList.index].id)
      .subscribe((r) => {
        this.makes = r;

        this.makeList.enabled = true;

        const name = r.filter((i) =>
          this.selectedMakes?.some((t) => t.id === i.id)
        )[0]?.name;
        this.makeList.text = name ?? null;
      });
  }

  makeChange(e: SelectEventArgs): void {
    this.modelList.text = null;
    this.modelList.enabled = false;

    if (this.makeList.index) {
      const makeId = this.makes[this.makeList.index]?.id;

      if (makeId) {
        this.assetModelService.getAssetModels(makeId).subscribe((r) => {
          this.models = r;
          this.modelList.enabled = true;
          this.modelList.value = e.isInteracted
            ? this.selectedModels
                ?.filter((model) => model.metaAssetMake?.id === makeId)
                .map((m) => m.name)
            : this.selectedModels
                ?.filter((i) => this.models?.some((t) => t.id === i.id))
                .map((it) => it.name);
        });
      }
    }
  }

  modelRemoved(e: RemoveEventArgs): void {
    this.modelRemove.emit({
      parent: this.assetTypeList.getDataByValue(this.assetTypeList.value),
      ...(e.itemData as MetaAssetModel)
    });
  }

  modelSelect(e: SelectEventArgs): void {
    this.modelAdd.emit({
      parent: this.assetTypeList.getDataByValue(this.assetTypeList.value),
      ...(e.itemData as MetaAssetModel)
    });
  }
}
