<form [formGroup]="formGroup">
  <div class="form-group">
    <label>Title</label>
    <input class="form-control" formControlName="title" />
    <label>Sub-Title</label>
    <input class="form-control" formControlName="subTitle" />
    <label>Data Types</label>
    <ng-container *ngIf="dataTypes$ | async as dataTypes">
      <select class="form-control" formControlName="dataTypes">
        <option disabled [ngValue]="null">Select Data Type(s)</option>
        <option [ngValue]="type.assetName" *ngFor="let type of dataTypes">
          {{ type.headerText }}
        </option>
      </select>
    </ng-container>
  </div>
</form>
