<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Company</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="dismiss(modal)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-template #loadingTemplate>
      <lana-loading></lana-loading>
    </ng-template>
    <ng-container *ngIf="fields | async as fields; else loadingTemplate">
      <form [formGroup]="formGroup">
        <div class="form-group">
          <label>Filter</label>
          <select class="form-control" formControlName="platform">
            <option disabled [ngValue]="null">Select Platform</option>
            <option
              [ngValue]="platform.value"
              *ngFor="let platform of platforms">
              {{ platform.name }}
            </option>
          </select>
          <!-- <lana-error-display
            [control]="formGroup.get('assetFilter')"
            label="Asset Filter"
          ></lana-error-display> -->
        </div>
        <div class="form-group">
          <input
            class="form-control"
            placeholder="Search Companies"
            [formControl]="searchFormControl" />
        </div>
      </form>

      <!-- <div class="form-group">
        <input
          class="form-control"
          placeholder="Search Companies"
          [formControl]="searchFormControl" />
      </div> -->
      <ejs-treeview
        #treeview
        [fields]="fields"
        (nodeSelected)="nodeSelected($event.nodeData)"
        (dataBound)="setExpandedNodes(treeview)">
        <ng-template #nodeTemplate="" let-data="">
          <div class="d-flex">
            <div class="mr-2">
              <i class="fas fa-building"></i>
            </div>
            <div>
              {{ data.name }}
            </div>
          </div>
        </ng-template>
      </ejs-treeview>
      <ng-container *ngIf="treeview?.fields?.dataSource?.length === 0">
        <div class="text-center p-3">
          <p class="mb-0">
            No companies found for
            <strong>'{{ searchFormControl.value }}'</strong>.
          </p>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <div *ngIf="selected" class="">
      {{ selected.name }}
    </div>
    <div class="ml-auto">
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!selected"
        (click)="selectCompany(selected, modal)">
        Select Company
      </button>
    </div>
  </div>
</ng-template>
