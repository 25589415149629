<div class="settings-header">
  <h4>Global Development Settings</h4>
</div>
<div class="container">
  <form
    *ngIf="emailFormGroup"
    [formGroup]="emailFormGroup"
    (ngSubmit)="saveConfigurations()">
    <div class="form-group">
      <label
        >Enter what email address(es) you want emails to be sent to, comma
        separated. ex: user@domain.com,user2@otherdomain.com</label
      >
    </div>
    <div *ngFor="let config of runtimeConfigurations" class="form-group">
      <label>{{ config.configurationDescription }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="{{ config.configurationDescription }}"
        formControlName="{{ config.configurationKey }}" />
    </div>
    <button type="submit" class="btn btn-primary ml-2">Save Changes</button>
  </form>
</div>
