export class Constants {
  static readonly Api = '/api';
  static readonly Odata = '/api/odata';
  static readonly LandingPage = '/callpass/dashboard';
  static readonly DefaultPageSize = 10;
  static readonly DefaultDetailPageSize = 10;
  static readonly DefaultRecentlyViewedPageSize = 3;
  static readonly DefaultFilterSize = 100;
  static readonly DefaultLatitude = 38.9734885;
  static readonly DefaultLongitude = -95.2509269;
  static readonly DefaultZoom = 5;
  static readonly ReportVersion = 6;
}
