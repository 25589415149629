<ng-container
  [ngTemplateOutlet]="
    asset?.lanaVision
      ? vision
      : asset?.temperature || asset?.humidity
      ? chill
      : default
  "></ng-container>
<ng-template #vision>
  <h5>
    <img class="header-asset-type" src="/assets/asset-types/vision.svg" />
    Asset: {{ asset.name }}
  </h5>
</ng-template>
<ng-template #chill>
  <h5>
    <img class="header-asset-type" src="/assets/asset-types/chill.svg" /> Asset:
    {{ asset.name }}
  </h5>
</ng-template>
<ng-template #default>
  <h5><i class="fa fa-cube"></i> Asset: {{ asset.name }}</h5>
</ng-template>
<ng-container
  *ngIf="
    asset?.lanaVision &&
    (asset?.lastOccupancySensorVolumePercentFull === null ||
      asset?.lastOccupancySensorVolumePercentFull === undefined)
  ">
  <div class="alert alert-danger mt-3 ml-3 mr-3 mb-0" role="alert">
    <i class="fas fa-sync-alt calibrating"></i> CALIBRATING
  </div>
</ng-container>
<div class="d-flex align-items-stretch">
  <div class="flex-shrink-1 pt-3">
    <p *ngIf="asset?.entityBreadcrumb?.includes('>>>')">
      <strong>Sub-Company:</strong>
      {{ asset.entityBreadcrumb | companyBreadcrumb }}
    </p>
    <p>
      <strong>Date/Time:</strong>
      {{
        asset.lastReportTimestamp
          | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
      }}
    </p>
    <p *ngIf="asset?.lastHeadingInDegrees && asset?.lastSpeedInMph > 0">
      <strong>Heading:</strong>
      {{ asset.lastHeadingInDegrees | heading : true }}
    </p>
    <p *ngIf="asset?.lastSpeedInMph > 0">
      <strong>Speed:</strong> {{ asset.lastSpeedInMph | number : '1.0-0' }} MPH
    </p>
    <!-- <p><strong>Asset Battery:</strong> {{ asset?.lastAssetBatteryVoltage >= 0 ? (asset.lastAssetBatteryVoltage | number :
  '1.0-1') : asset?.isWiredAsset ? "No Data Available" : "N/A" }}</p>
<p *ngIf="asset?.lastTrackerBatteryVoltage > 0"><strong>Tracker Battery:</strong> {{asset.lastTrackerBatteryVoltage |
  number : '1.0-1'}}</p> -->
    <p *ngIf="asset?.trackerSerialNumber">
      <strong>Tracker Serial Number:</strong> {{ asset.trackerSerialNumber }}
    </p>
    <p><strong>Address:</strong> {{ asset.lastFullAddress }}</p>
    <p>
      <strong>Coordinates:</strong> {{ asset.lastLatitude }},{{
        asset.lastLongitude
      }}
    </p>
    <p *ngIf="asset?.lastEventCodeDescription">
      <strong>Event Type:</strong> {{ asset.lastEventCodeDescription }}
    </p>
    <!-- Asset Battery -->
    <ng-template #defaultAssetBattery>
      <p>
        <strong>Asset Battery:</strong>
        {{
          asset?.lastAssetBatteryVoltage >= 0
            ? (asset.lastAssetBatteryVoltage | number : '1.0-1')
            : asset?.isWiredAsset
            ? 'No Data Available'
            : 'N/A'
        }}
      </p>
    </ng-template>
    <ng-container
      *ngIf="
        asset?.lastAssetBatteryVoltage &&
        asset?.lastAssetBatteryVoltageTimestamp
      ">
      <hr />
      <div class="d-flex align-items-stretch">
        <div class="flex-shrink-1">
          <p>
            <strong>Asset Battery</strong>:
            {{
              asset?.lastAssetBatteryVoltage >= 0
                ? (asset.lastAssetBatteryVoltage | number : '1.0-1')
                : asset?.isWiredAsset
                ? 'No Data Available'
                : 'N/A'
            }}
          </p>
          <p *ngIf="asset?.lastAssetBatteryVoltageTimestamp">
            <strong>Last Received: </strong>
            {{
              asset?.lastAssetBatteryVoltageTimestamp
                | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
            }}
          </p>
        </div>
      </div>
    </ng-container>
    <!-- Tracker Battery -->
    <ng-template #defaultTrackerBattery>
      <p *ngIf="asset?.lastTrackerBatteryVoltage > 0">
        <strong>Tracker Battery:</strong>
        {{ asset.lastTrackerBatteryVoltage | number : '1.0-1' }}
      </p>
    </ng-template>
    <ng-container
      *ngIf="
        asset?.lastTrackerBatteryVoltage &&
        asset?.lastTrackerBatteryVoltageTimestamp
      ">
      <hr />
      <div class="d-flex align-items-stretch">
        <div class="flex-shrink-1">
          <p>
            <strong>Tracker Battery</strong>:
            {{ asset.lastTrackerBatteryVoltage | number : '1.0-1' }}
          </p>
          <p *ngIf="asset?.lastTrackerBatteryVoltageTimestamp">
            <strong>Last Received: </strong>
            {{
              asset?.lastTrackerBatteryVoltageTimestamp
                | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
            }}
          </p>
        </div>
      </div>
    </ng-container>
    <!-- Temperature and Humidity -->
    <div moduleType="LANA Chill" accessType="isActive" lanaHasPermission>
      <ng-container *ngIf="asset?.temperature || asset?.humidity">
        <hr />
        <div class="d-flex align-items-stretch">
          <div class="flex-shrink-1">
            <p style="text-decoration: underline">
              <strong>Temperature and Humidity</strong>
            </p>
            <ng-container *ngIf="asset?.lastSensorsData?.length == 0">
              <div class="row">
                <div class="col-6">
                  <p *ngIf="asset?.temperature">
                    <span [innerHTML]="asset | trackerSensors : true"></span>
                  </p>
                </div>
                <div class="col-6">
                  <p *ngIf="asset?.humidity">
                    <span [innerHTML]="asset | trackerSensors"></span>
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="asset?.lastSensorsData?.length > 0">
              <ng-container *ngFor="let data of asset?.lastSensorsData">
                <div class="row container-fluid">
                  <div class="col-12">
                    <p>
                      <span
                        [ngbTooltip]="
                          data?.temperature?.companyDefined
                            ? data?.temperature?.companyDefined
                            : data?.temperature?.default
                        "
                        [innerHTML]="
                          asset | multipletrackerSensors : data : true
                        "></span>
                      &nbsp;
                      <span
                        [ngbTooltip]="
                          data?.temperature?.companyDefined
                            ? data?.temperature?.companyDefined
                            : data?.temperature?.default
                        "
                        *ngIf="asset?.humidity"
                        [innerHTML]="
                          asset | multipletrackerSensors : data
                        "></span>
                    </p>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <p *ngIf="asset?.lastSensorBulkSamplesTimestamp">
              <strong>Last Received: </strong>
              {{
                asset.lastSensorBulkSamplesTimestamp
                  | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
              }}
            </p>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- NON Vision Door State -->
    <ng-template #defaultDoorStateTemplate>
      <ng-container
        *ngIf="asset?.sensorIsDoorOpen || asset?.sensorBackupBattery">
        <hr />
        <div class="d-flex align-items-stretch">
          <div class="flex-shrink-1">
            <p style="text-decoration: underline">
              <strong>Door Sensor</strong>
            </p>
            <div class="row">
              <div class="col-6" *ngIf="asset?.sensorIsDoorOpen">
                <p>
                  <span
                    [innerHTML]="asset?.sensorIsDoorOpen | doorStatus"></span>
                </p>
              </div>
              <div class="col-6" *ngIf="asset?.sensorBackupBattery">
                <p>
                  <strong>Battery:</strong>
                </p>
                <p>
                  <i class="fas fa-battery-full"></i>
                  {{ asset?.sensorBackupBattery | number : '1.0-1' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <div moduleType="LANA Vision" accessType="isActive" lanaHasPermission>
      <ng-container *ngIf="asset?.lanaVision; else defaultDoorStateTemplate">
        <hr />
        <div class="d-flex align-items-stretch">
          <div class="flex-shrink-1 pb-1">
            <p style="text-decoration: underline">
              <ng-template #defaultTemplate>
                <strong>LANA Vision</strong>
              </ng-template>
              <ng-container
                *ngIf="theme | async as theme; else defaultTemplate">
                <strong>{{ theme | cargoVision }}</strong>
              </ng-container>
            </p>
            <div class="row lana-vision-values">
              <div class="col-3 pr-0 m-0">
                <p>
                  <strong>Percentage<br />Full:</strong>
                </p>
                <p class="custom-p pl-3">
                  <ng-container
                    *ngIf="
                      asset?.lastOccupancySensorVolumePercentFull !== null &&
                      asset?.lastOccupancySensorVolumePercentFull !== undefined
                    ">
                    <svg-icon
                      src="/assets/icons/trailer.svg"
                      [svgStyle]="{
                        'width.px': 43,
                        fill: 'rgb(var(--primary))'
                      }"></svg-icon> </ng-container
                  >{{
                    asset?.lastOccupancySensorVolumePercentFull | percentageFull
                  }}
                </p>
              </div>
              <div class="col-3 p-0 m-0">
                <span
                  [innerHTML]="
                    asset?.lastOccupancySensorIsDoorOpen | doorStatus
                  "></span>
              </div>
              <div
                class="col-3 p-0 m-0"
                *ngIf="asset?.lastOccupancySensorBackupBattery">
                <p>
                  <strong>Door<br />Battery:</strong>
                </p>
                <p>
                  <i class="fas fa-battery-full"></i>
                  {{
                    asset?.lastOccupancySensorBackupBattery | number : '1.0-1'
                  }}
                </p>
              </div>
              <div class="col-3 p-0 m-0">
                <span
                  [innerHTML]="
                    asset?.lastVisionTemperature | temperature
                  "></span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="flex-grow-1 lana-vision">
    <!-- LANA Vision image on right side -->
    <div moduleType="LANA Vision" accessType="isActive" lanaHasPermission>
      <ng-container *ngIf="asset?.lanaVision">
        <ng-container *ngIf="imageUrl | withLoading | async as imageUrl">
          <ng-template [ngIf]="imageUrl.value"
            ><img [src]="imageUrl.value" (click)="openModal()"
          /></ng-template>
          <ng-template [ngIf]="imageUrl.error">
            <div class="broken-image">
              <span class="icon"><i class="fa fa-image"></i></span>
              <p>Image Unknown</p>
            </div>
          </ng-template>
          <ng-template [ngIf]="imageUrl.loading">
            <lana-loading></lana-loading>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<lana-vision-modal></lana-vision-modal>
