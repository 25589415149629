import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'heading'
})
export class HeadingPipe implements PipeTransform {
  private possibleHeadingValues = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW'
  ];
  private possibleHeadingValuesLong = [
    'North',
    'NNE',
    'Northeast',
    'ENE',
    'East',
    'ESE',
    'Southeast',
    'SSE',
    'South',
    'SSW',
    'Southwest',
    'WSW',
    'West',
    'WNW',
    'Northwest',
    'NNW'
  ];

  transform(value: number, ...args: any[]): string {
    const [long] = args;
    const val = Math.floor(value / 22.5 + 0.5);
    const values = long
      ? this.possibleHeadingValuesLong
      : this.possibleHeadingValues;

    return values[Math.round(val % 16)];
  }
}
