import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cargoVision'
})
export class CargoVisionPipe implements PipeTransform {
  transform(customer: string): string {
    return customer.includes('lana') ? 'LANA Vision' : 'Cargo Vision';
  }
}
