<ng-template #content let-modal>
  <div class="modal-header" *ngIf="vision">
    <h5 class="modal-title">
      <img class="header-asset-type" src="/assets/asset-types/vision.svg" />
      Asset:
      {{ vision.name }}
    </h5>
    <!-- <h4 class="modal-title" id="modal-basic-title">{{ vision?.name }}</h4> -->
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="vision">
      <ng-container
        *ngIf="
          vision?.occupancySensorVolumePercentFull === null ||
          vision?.occupancySensorVolumePercentFull === undefined
        ">
        <div class="alert alert-danger m-3" role="alert">
          <i class="fas fa-sync-alt calibrating"></i> CALIBRATING
        </div>
      </ng-container>
      <div class="d-flex align-items-stretch">
        <div class="flex-shrink-1">
          <div class="card shadow p-0 rounded-0 position">
            <div class="card-body p-2">
              <p>
                <strong>Date/Time:</strong>
                {{
                  vision.timestamp
                    | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timezone
                }}
              </p>
              <p *ngIf="vision?.trackerBatteryVoltage > 0">
                <strong>Tracker Battery:</strong>
                {{ vision.trackerBatteryVoltage | number : '1.1-1' }}
              </p>
              <p *ngIf="vision?.trackerSerialNumber">
                <strong>Tracker Serial Number:</strong>
                {{ vision.trackerSerialNumber }}
              </p>
              <p *ngIf="vision?.fullAddress">
                <strong>Address:</strong> {{ vision.fullAddress }}
              </p>
              <p *ngIf="vision?.latitude && vision?.longitude">
                <strong>Coordinates:</strong> {{ vision.latitude }},{{
                  vision.longitude
                }}
              </p>
              <p *ngIf="vision?.eventCodeDescription">
                <strong>Event Type:</strong>
                {{ vision.eventCodeDescription }}
              </p>
              <p>
                <strong>Percentage Full:</strong>
                {{ vision.occupancySensorVolumePercentFull | percentageFull }}
              </p>
              <p>
                <strong>Door Status:</strong>
                {{
                  vision?.occupancySensorIsDoorOpen === null ||
                  vision?.occupancySensorIsDoorOpen === undefined
                    ? 'Unknown'
                    : vision?.occupancySensorIsDoorOpen
                    ? 'Opened'
                    : 'Closed'
                }}
              </p>
              <p>
                <strong>Ambient Temperature:</strong>
                {{ vision?.temperature ? getTemp(vision?.temperature) : 'N/A' }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <ng-container *ngIf="images | withLoading | async as images">
            <ng-template [ngIf]="images.value">
              <ng-container
                *ngFor="
                  let image of images.value;
                  let i = index;
                  first as isFirst
                ">
                <ng-container *ngIf="isFirst">
                  <div class="d-flex align-items-center justify-content-center">
                    <img [src]="image" alt="Slide {{ i + 1 }}" />
                  </div>
                </ng-container>
              </ng-container>
            </ng-template>
            <ng-template [ngIf]="images.error"
              ><div class="broken-image">
                <span class="icon"><i class="fa fa-image"></i></span>
                <p>Image Unknown</p>
              </div></ng-template
            >
            <ng-template [ngIf]="images.loading">
              <lana-loading></lana-loading
            ></ng-template>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
