import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Operator } from 'src/app/models/alerts/alerts';
import {
  animate,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AlertsService } from 'src/app/services/alerts.service';

@Component({
  selector: 'lana-alert-chain',
  templateUrl: './alert-chain.component.html',
  styleUrls: ['./alert-chain.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' }))
      ])
    ]),
    trigger('fadeInGrow', [
      transition(':enter', [
        query(':enter', [
          style({ opacity: 0, transform: 'scale(0.8)' }),
          group([
            animate('1000ms', style({ opacity: 1 })),
            animate('500ms ease-in', style({ transform: 'scale(1)' }))
          ])
        ])
      ])
    ]),
    trigger('slideLeft', [
      state('true', style({ marginLeft: '0' })),
      transition('false <=> true', animate(500))
    ]),
    trigger('assetsSummarySlide', [
      transition(':enter', [
        query(
          ':enter',
          [
            style({ opacity: 0 }),
            group([
              animate('2000ms', style({ opacity: 1 })),
              animate(
                '1500ms ease-in',
                style({ maxWidth: '33.3333333%', flex: '0 0 33.3333333%' })
              )
            ])
          ],
          { optional: true }
        )
      ])
    ])
  ]
})
export class AlertChainComponent implements OnInit {
  @Input() operators: Operator[];
  @Input() assets: any[] = [];
  @Output() removeOperator = new EventEmitter<any>();
  @Output() removeAsset = new EventEmitter<any>();
  @Output() operatorValueChange = new EventEmitter<any>();
  @Output() addAnother = new EventEmitter<any>();
  content: any;

  constructor(private alertsService: AlertsService) {}

  operatorValue($event, i): void {
    this.operatorValueChange.emit({ $event, i });
  }

  ngOnInit(): void {
    this.content = this.alertsService.content;
  }
}
