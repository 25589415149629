import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Constants } from './../constants';
import {
  CreatePhoneNumber,
  PhoneNumber,
  UpdatePhoneNumber
} from './../models/user/user';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberService {
  constructor(private httpClient: HttpClient) {}

  private readonly url = `${Constants.Api}/PhoneNumber`;

  createPhoneNumber(model: CreatePhoneNumber): Observable<PhoneNumber> {
    return this.httpClient.post<PhoneNumber>(this.url, model);
  }

  updatePhoneNumber(model: UpdatePhoneNumber): Observable<PhoneNumber> {
    return this.httpClient.put<PhoneNumber>(this.url, model);
  }

  deletePhoneNumber(id: number): Observable<void> {
    return this.httpClient.request<void>('delete', this.url, { body: { id } });
  }
}
