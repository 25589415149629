import { Asset } from './../asset/asset';
import { CompanySummary } from './../company/company-summary';

export interface AssetGroup {
  id: number;
  companySummary: CompanySummary;
  companyName: string;
  breadcrumb: string;
  name: string;
  assetCount: number;
  activeAssetCount: number;
  isPublic: boolean;
  isPublicEditable: boolean;
  assets: Asset[];
  entityBreadcrumb?: string;
  parentAssetId?: number;
}

export interface UpdateAssetGroupAsset {
  assetGroupId: number;
  assetId: number;
}

export interface AssetGroupAssets {
  id: number;
}

export interface PostAssetGroupAsset extends UpdateAssetGroupAsset {}

export interface DeleteAssetGroupAsset extends UpdateAssetGroupAsset {}

export interface AssignableAssetGroup extends AssetGroup {
  canRemoveFromGroup: boolean;
}
