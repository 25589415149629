import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconClass'
})
export class IconClassPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    let icon = 'fa-cube';

    if (value?.lanaVision) {
      icon = 'fa-camera';
    } else if (value?.lastSensorBulkSamples) {
      icon = 'fa-thermometer-half';
    }
    return `fa fa-fw grow ${icon}`;
  }
}
