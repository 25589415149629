import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewportService {
  check() {
    const mobile = /iphone|blackberry|mini|windows\sce|palm/i.test(
      navigator.userAgent.toLowerCase()
    );
    const portrait = window.innerHeight > window.innerWidth;
    if (mobile) {
      return 'mobile';
    } else if (mobile || portrait) {
      return 'portrait';
    }
    return '';
  }
}
