<agm-marker
  *ngFor="let marker of data"
  [latitude]="marker?.latitude"
  [longitude]="marker?.longitude"
  [iconUrl]="{
    path: 0,
    scale: 12,
    strokeColor: '#FFFFFF',
    strokeWeight: 2,
    fillColor: '#009ede',
    fillOpacity: 1
  }"
  [zIndex]="zIndex"
  [visible]="marker?.id !== currentlyShowing?.id"
  (mouseOver)="show.emit(marker)"
  (mouseOut)="hide.emit()">
</agm-marker>
