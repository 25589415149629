<div class="card shadow">
  <div class="card-header d-flex align-items-center">
    <div class="mr-auto font-weight-bold">
      <i class="fas fa-comments fa-fw mr-2"></i> Comments
    </div>
    <div>
      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Add Comment</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formGroup">
            <div class="form-group">
              <label>Comment</label>
              <textarea
                class="form-control"
                formControlName="comment"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="confirmAddComment(formGroup, modal)">
            Add Comment
          </button>
        </div>
      </ng-template>
      <button
        (click)="addComment(content)"
        type="button"
        class="btn btn-sm btn-primary"
        *lanaHasRole="[roles.Administrator]">
        Add Comment
      </button>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <lana-loading></lana-loading>
  </ng-template>
  <ng-container *ngIf="comments | async as comments; else loadingTemplate">
    <div class="card-body p-0">
      <ul class="list-group list-group-flush">
        <ng-template #detaultComment>
          <li class="list-group-item p-5 text-center">
            <p class="mb-0">No comments</p>
          </li>
        </ng-template>
        <ng-container *ngIf="comments.length > 0; else detaultComment">
          <li class="list-group-item" *ngFor="let comment of comments">
            <div class="d-flex align-items-center mb-2 small">
              <!-- <svg class="mr-3 rounded" width="32" height="32" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 32x32">
                <title>Placeholder</title>
                <rect width="100%" height="100%" fill="#e1e2e3"></rect>
              </svg> -->
              <div>
                <p class="font-weight-bold mb-0">
                  <a [routerLink]="'/management/users/' + comment.createdById"
                    >{{ comment.createdByFirstName }}
                    {{ comment.createdByLastName }}</a
                  >
                </p>
                <p class="mb-0">
                  {{
                    comment.created
                      | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timezone
                  }}
                </p>
              </div>
            </div>
            <p class="mb-0 bg-light pl-3 pr-3 pt-2 pb-2 rounded">
              {{ comment.commentText }}
            </p>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</div>
