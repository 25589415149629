import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
  BehaviorSubject,
  interval,
  Observable,
  of,
  Subscription,
  throwError,
  Subject,
  forkJoin
} from 'rxjs';
import { catchError, map, switchMap, tap, combineLatest } from 'rxjs/operators';
import { Constants } from '../constants';
import { DateFormatter } from '../formatters/date.formatter';
import { LanaColumnModel } from '../models/column';
import { AlertHistory } from '../models/dashboard/alerts';
import { WidgetCategory } from '../models/dashboard/widgets';
import { AssetService } from './asset.service';
import {
  OdataResponse,
  OdataResponseTransform
} from './../models/odata-response';
import { MapAsset } from './../models/asset/map-asset';
import {
  BarCharts,
  LineCharts,
  PieCharts,
  WidgetCharts,
  WidgetGuages,
  WidgetReports,
  LanaVision
} from '../pages/dashboard/widgets-available';
import { AuthService } from './auth.service';
import { ODataHelperService } from './o-data-helper.service';
import { WidgetProperty } from 'src/app/models/dashboard/widgets';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private oDataHelperService: ODataHelperService,
    private assetService: AssetService
  ) {}

  private readonly url = `${Constants.Api}/Dashboard`;
  private readonly climateUrl = `${Constants.Api}/TemperatureSensor/Report`;
  private readonly headersUrl = `${Constants.Api}/ColumnsHeader`;
  private readonly odataUrl = `${Constants.Odata}`;
  private readonly telemetryUrl = `${Constants.Api}/Telemetry/Asset`;

  public maxEndDate = moment().toDate();
  public readonly dateRange = new BehaviorSubject<string>('24h');
  public readonly interval = new BehaviorSubject<boolean>(false);
  public readonly includeSubCompany = new BehaviorSubject<boolean>(false);
  public readonly dragResize = new BehaviorSubject<boolean>(false);
  public readonly autoRefresh = new BehaviorSubject<boolean>(false);
  public readonly widgetHeight = new BehaviorSubject<number>(0);
  public readonly widgetWidth = new BehaviorSubject<number>(0);
  public readonly widgetId = new BehaviorSubject<string>('');
  public readonly widgetChanges = new BehaviorSubject<{
    width: number;
    height: number;
    id: string;
  }>({ width: 0, height: 0, id: '' });
  public readonly gridsterResize$ = new BehaviorSubject<boolean>(false);
  public readonly lastUpdated = new BehaviorSubject<Date>(null);
  public readonly openWidgetEditForm = new Subject<WidgetProperty>();
  public updateSubscription: Subscription;
  public widgetApiTypes = [];
  public chartInstances = {};

  readonly columns: LanaColumnModel<AlertHistory>[] = [
    { field: 'alertName', headerText: 'Alert Name', autoFit: true },
    //{ field: 'alertState', headerText: 'Alert State', autoFit: true },
    { field: 'priorityLevel', headerText: 'Priority Level', autoFit: true },
    { field: 'trackerId', headerText: 'Tracker Id', autoFit: true },
    { field: 'assetId', headerText: 'Asset Id', autoFit: true },
    // { field: "headingInDegrees", headerText: "Heading", autoFit: true, formatter: HeadingFormatter.getValue<AssetPeriodicEventsReport>('headingInDegrees') },
    {
      field: 'processed',
      headerText: 'Processed',
      formatter: DateFormatter.getValue<AlertHistory>(
        'processed',
        false,
        this.authService.currentUser$
      ),
      autoFit: true
    }
  ];

  calculateDateRange() {
    const val = this.dateRange.value;
    const end = moment().format();
    let start = moment().subtract(24, 'hours').format();

    switch (val) {
      case '6h':
        start = moment().subtract(6, 'hours').format();
        break;
      case '12h':
        start = moment().subtract(12, 'hours').format();
        break;
      case 'week':
        start = moment().subtract(1, 'week').format();
        break;
      case 'month':
        start = moment().subtract(1, 'month').format();
        break;
      default:
        start = moment().subtract(24, 'hours').format();
    }
    return { start, end };
  }

  getDeviceList() {
    this.httpClient
      .get(`${Constants.Api}/igotcha/Device/?CompanyId=14826`)
      .pipe(tap((response) => console.log(response)))
      .subscribe();
  }

  getData(urlType: string): Observable<AlertHistory> {
    const { start, end } = this.calculateDateRange();
    const lastUpdated = moment().toDate();
    this.lastUpdated.next(lastUpdated);

    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<AlertHistory>(
          `${
            this.url
          }/${urlType}?companyContext=${companyId}&startTime=${encodeURIComponent(
            start
          )}&endTime=${encodeURIComponent(end)}${
            this.includeSubCompany.getValue() ? '&includeSubcompany=true' : ''
          }`
        )
      )
    );
  }

  getDetailData(params): Observable<any> {
    const date = params.date ?? '24h';
    this.dateRange.next(date);
    const { start, end } = this.calculateDateRange();

    const filter = params?.filterValue
      ? `(${params.filter} eq '${params.filterValue}') and `
      : '';

    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<any>(
          `${this.odataUrl}/${
            params.api
          }?companyContext=${companyId}&$filter=${filter} ${
            params.includeSubCompany
              ? ''
              : '(CompanyId eq ' + companyId + ') and '
          } (ResponseDateTime gt ${encodeURIComponent(
            start
          )}) and (ResponseDateTime lt ${encodeURIComponent(
            end
          )})&$count=true&$expand=Asset,Tracker`
        )
      ),
      map((response) => (response?.value.length > 0 ? response.value : [])),
      catchError((_) => of([]))
    );
  }

  getMapAssets(params): Observable<OdataResponseTransform<MapAsset>> {
    const { includeSubCompany } = params;
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<MapAsset>>(
          `${
            this.odataUrl
          }/MapAssets?companyContext=${companyId}&$orderby=lastReportTimestamp%20desc${
            includeSubCompany ? '' : '&$filter=companyId eq ' + companyId
          }&assetId=15694`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [] as MapAsset[], count: 0 }))
    );
  }

  setInterval() {
    this.interval.getValue()
      ? this.autoRefresh.next(true)
      : this.autoRefresh.next(false);
  }

  pollData(url: string): Observable<any> {
    const minutes = 60000 * 15;
    return interval(minutes).pipe(switchMap((x) => this.getData(url)));
  }

  getCategorySelection(category: WidgetCategory): Observable<WidgetCategory[]> {
    switch (category.key) {
      case 'chart':
        return of(WidgetCharts);
      case 'gauge':
        return of(WidgetGuages);
      case 'report':
        return of(WidgetReports);
      case 'vision':
        return of(LanaVision);
    }
  }

  getWidgetSelection(widget: WidgetCategory): Observable<WidgetCategory[]> {
    switch (widget.key) {
      case 'pie':
        return of(PieCharts);
      case 'line':
        return of(LineCharts);
      case 'bar':
        return of(BarCharts);
    }
  }

  getTemperatureReport(assets: number[], includeSubCompany = false) {
    const { start, end } = this.calculateDateRange();
    return this.authService.companyId$.pipe(
      switchMap((companyId) => {
        return this.httpClient.get<any>(
          `${
            this.climateUrl
          }?companyContext=${companyId}&startTime=${encodeURIComponent(
            start
          )}&endTime=${encodeURIComponent(end)}&assetIdList=${assets}${
            includeSubCompany ? '' : '&$filter=companyId eq ' + companyId
          }`
        );
      }),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getTelemetryData(assets: number[], includeSubCompany = false) {
    const { start, end } = this.calculateDateRange();

    return this.authService.companyId$.pipe(
      switchMap((companyId) => {
        const requests = assets.map((asset) => {
          return this.httpClient.get<any>(
            `${
              this.telemetryUrl
            }?companyId=${companyId}&startTime=${encodeURIComponent(
              start
            )}&endTime=${encodeURIComponent(end)}&assetId=${asset}${
              includeSubCompany ? '' : '&$filter=companyId eq ' + companyId
            } `
          );
        });

        return forkJoin(requests).pipe(
          map((results) => {
            let mapAssets: MapAsset[] = [];
            results.forEach((result) => {
              mapAssets = [...mapAssets, ...result];
            });
            return mapAssets;
          })
        );
      }),
      catchError((_) => of([]))
    );
  }

  getColumns(): any {
    const fields = this.columns.map((column) => column.field);
    return this.httpClient
      .get<Headers>(`${this.headersUrl}/TemperatureReport`)
      .pipe(
        map((items: any) =>
          items.filter((item) => fields.indexOf(item.field) === -1)
        )
      );
  }

  getAssetsWithFilter() {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.assetService.getAssetsForWidgetsDropDown({
          filterQuery: `&$filter=CompanySummary/Id eq ${companyId}`,
          pageQuery: '',
          sortQuery: ''
        })
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  getAssets() {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.assetService.getAssets({
          filterQuery: `&$filter=CompanySummary/Id eq ${companyId}`,
          pageQuery: '',
          sortQuery: '&$orderby=name'
        })
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getAssetHistoryDataTypes() {
    const dataTypes: { field: string; headerText: string }[] = [
      { field: 'speedInMph', headerText: 'speedInMph' },
      { field: 'backupBatteryVoltage', headerText: 'backupBatteryVoltage' },
      { field: 'internalBatteryVoltage', headerText: 'internalBatteryVoltage' },
      { field: 'temperatureFahrenheit', headerText: 'temperatureFahrenheit' },
      { field: 'temperatureCelsius', headerText: 'temperatureCelsius' }
    ];

    return dataTypes;
  }
}
