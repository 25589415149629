import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { CompanySelectorComponent } from './../company-selector/company-selector.component';

@Component({
  selector: 'lana-company-selector-select',
  templateUrl: './company-selector-select.component.html',
  styleUrls: ['./company-selector-select.component.scss']
})
export class CompanySelectorSelectComponent {
  @Input() control: UntypedFormControl;
  @Input() selectedCompany: { id: number; name: string };
  @Input() internalCompany: boolean = false;

  @ViewChild(CompanySelectorComponent)
  companySelector: CompanySelectorComponent;

  setCompanySelected(company: { id: number; name: string }): void {
    this.selectedCompany = company;
    this.control.setValue(company.id);
  }

  openCompanySelectorModal(): void {
    this.selectedCompany = null;
    this.companySelector.openModel();
    this.control.setValue(null);
    this.control.markAsTouched();
  }
}
