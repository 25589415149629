import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'lana-map-marker-cluster',
  templateUrl: './map-marker-cluster.component.html',
  styleUrls: ['./map-marker-cluster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapMarkerClusterComponent {
  @Input() data: any;
  @Input() currentlyShowing: any;
  @Input() zIndex: number = 1;

  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();
}
