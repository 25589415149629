<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center">
    <lana-loading></lana-loading>
  </div>
</ng-template>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change Status</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="flex-grow-1 mr-2 form-group">
      <label>Enable/Disable</label>
      <select class="form-control" formControlName="changeStatus">
        <option [ngValue]="status.value" *ngFor="let status of changeStatus">
          {{ status.name }}
        </option>
      </select>
      <lana-error-display
        [control]="formGroup.get('changeStatus')"
        label="change Status"></lana-error-display>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('cancel click')">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
<ng-container *ngIf="data | async as data; else loadingTemplate">
  <ng-template #mainGrid></ng-template>
  <div class="form-group d-flex" [formGroup]="formGroup">
    <ng-container *ngIf="isSearchAble">
      <div class="flex-grow-1 mr-2">
        <label>From</label>
        <div class="input-group">
          <div class="input-group-prepend border-1">
            <span class="input-group-text">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            placeholder="Search Company..."
            class="form-control"
            formControlName="searchFrom" />
        </div>
      </div>
      <div class="flex-grow-1 mr-2">
        <label>To</label>
        <div class="input-group">
          <div class="input-group-prepend border-1">
            <span class="input-group-text">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            placeholder="Search Company..."
            class="form-control"
            formControlName="searchTo" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isStatusFilter">
      <div class="flex-grow-1 mr-2">
        <label>From</label>
        <div class="input-group">
          <div class="input-group-prepend border-1">
            <span class="input-group-text">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            placeholder="Search Company..."
            class="form-control"
            formControlName="searchFrom" />
        </div>
      </div>
      <div class="flex-grow-1 mr-2 form-group">
        <label>Status</label>
        <select class="form-control" formControlName="statusFilter">
          <option disabled [ngValue]="null">Select Filter Type</option>
          <option [ngValue]="filter.value" *ngFor="let filter of filterTypes">
            {{ filter.name }}
          </option>
        </select>
        <lana-error-display
          [control]="formGroup.get('statusFilter')"
          label="Asset Filter"></lana-error-display>
      </div>
    </ng-container>
    <ng-container *ngIf="isCompanyAndSearch">
      <div class="flex-grow-1 mr-2">
        <label>Company</label>
        <div class="company-list">
          <lana-company-selector-select
            [control]="formGroup.get('companyId')"
            [internalCompany]="true"
            [selectedCompany]="selectedCompany">
          </lana-company-selector-select>
          <lana-error-display
            [control]="formGroup.get('companyId')"
            label="Company"></lana-error-display>
        </div>
      </div>
      <div class="flex-grow-1 mr-2">
        <label>Search</label>
        <div class="input-group">
          <div class="input-group-prepend border-1">
            <span class="input-group-text">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            placeholder="Search Company..."
            class="form-control"
            formControlName="searchUser" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isDeviceReport">
      <div class="flex-grow-1 mr-2">
        <label>Company</label>
        <div class="company-list">
          <lana-company-selector-select
            [control]="formGroup.get('companyId')"
            [internalCompany]="true"
            [selectedCompany]="selectedCompany">
          </lana-company-selector-select>
          <lana-error-display
            [control]="formGroup.get('companyId')"
            label="Company"></lana-error-display>
        </div>
      </div>
      <div class="flex-grow-1 mr-2">
        <label>Filter</label>
        <div class="input-group">
          <div class="input-group-prepend border-1">
            <span class="input-group-text">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            placeholder="Filter Devices"
            class="form-control"
            formControlName="searchUser" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isAuditReport">
      <div class="flex-grow-1 mr-2">
        <label>Company</label>
        <div class="company-list">
          <lana-company-selector-select
            [control]="formGroup.get('companyId')"
            [internalCompany]="true"
            [selectedCompany]="selectedCompany">
          </lana-company-selector-select>
          <lana-error-display
            [control]="formGroup.get('companyId')"
            label="Company"></lana-error-display>
        </div>
      </div>
      <div class="flex-grow-1 mr-2">
        <label>Froms</label>
        <div class="company-list">
          <lana-company-selector-select
            [control]="formGroup.get('froms')"
            [internalCompany]="true"
            [selectedCompany]="selectedCompanyFroms">
          </lana-company-selector-select>
          <lana-error-display
            [control]="formGroup.get('froms')"
            label="Company"></lana-error-display>
        </div>
      </div>

      <div class="flex-grow-1 mb-2">
        <div class="form-group">
          <label>Date</label>
          <ejs-datetimepicker
            placeholder="Start Date/Time"
            formControlName="startDateTime"></ejs-datetimepicker>
        </div>
      </div>
      <div class="flex-grow-1 mb-2">
        <div class="form-group">
          <label>Date</label>
          <ejs-datetimepicker
            placeholder="Start Date/Time"
            formControlName="endDateTime"></ejs-datetimepicker>
        </div>
      </div>
    </ng-container>
    <div
      class="pd-t-25"
      *ngIf="
        isCompanyAndSearch || isDeviceReport || isSearchAble || isStatusFilter
      ">
      <a class="btn btn-default"
        ><img src="../../../assets/icons/Filter.png"
      /></a>
    </div>
    <div class="pd-t-25" *ngIf="isCompanyAndSearch">
      <a class="btn btn-primary" (click)="open(content)">Change Status</a>
    </div>
    <div class="pd-t-25" *ngIf="isDeviceReport">
      <a class="btn btn-primary">Change Status</a>
    </div>
    <div class="pd-t-25" *ngIf="isSearchAble">
      <a class="btn btn-primary">Transfer</a>
    </div>
    <div class="pd-t-25" *ngIf="isStatusFilter || isAuditReport">
      <a class="btn btn-primary">Report</a>
    </div>
  </div>

  <ng-container *ngIf="includeSubCompanyText && showSubCompanyCheckbox">
    <div class="d-flex justify-content-end">
      <div class="form-group form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="includeSubCompany"
          [formControl]="includeSubCompanyFormControl" />
        <label class="form-check-label" for="includeSubCompany"
          >Include sub-company {{ includeSubCompanyText }} in result set</label
        >
      </div>
    </div>
  </ng-container>
  <div class="shadow-sm">
    <form
      [formGroup]="formGroup"
      class="d-flex align-items-center"
      *ngIf="filterSettings.columns.length > 0">
      <div class="form-group mb-0 flex-grow-1">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            class="form-control-search"
            formControlName="search"
            [placeholder]="placeholder" />
        </div>
      </div>
    </form>
    <ejs-grid
      #grid
      [allowPaging]="true"
      [toolbar]="toolbar"
      [allowExcelExport]="true"
      [allowSorting]="true"
      [showColumnChooser]="true"
      [dataSource]="data"
      [filterSettings]="filterSettings"
      [pageSettings]="pageOptions"
      [className]="customClass"
      [selectionSettings]="selectionOptions"
      [sortSettings]="sortSettings"
      [childGrid]="childGrid"
      [enableHover]="false"
      (toolbarClick)="toolbarClick($event)"
      (excelQueryCellInfo)="exportQueryCellInfo($event)"
      (excelExportComplete)="excelExportComplete()"
      (dataStateChange)="dataStateChange($event, includeSubCompanyFormControl)"
      (rowSelecting)="rowSelecting($event)"
      (headerCellInfo)="headerCellInfo($event)"
      (rowDeselecting)="rowSelected.emit(null)"
      (load)="load($event)"
      (created)="created($event)"
      (queryCellInfo)="tooltip($event)">
      <e-columns>
        <e-column *ngIf="isSelectable" type="checkbox" width="60"></e-column>

        <ng-container *ngFor="let column of columns">
          <ng-template #defaultColumnTemplate>
            <e-column
              [field]="column.field"
              [headerText]="column.headerText"
              [visible]="column.visible"
              [allowSorting]="column.allowSorting"
              [autoFit]="column.autoFit"
              [width]="column.width"
              clipMode="EllipsisWithTooltip">
              <ng-template #template let-data>
                <ng-template #defaultTemplate>
                  {{ data | columnParser : data.column.field }}
                </ng-template>
                <ng-container
                  *ngIf="
                    !!column.generateUrl ||
                      !!column.generateLocation ||
                      !!column.generateStatus;
                    else defaultTemplate
                  ">
                  <ng-container *ngIf="!!column.generateUrl">
                    <ng-container *ngIf="column.generateUrl(data) as url">
                      <ng-template #defaultUrlTemplate>
                        <a [routerLink]="url">{{
                          data | columnParser : data.column.field
                        }}</a>
                      </ng-template>
                      <ng-container
                        *ngIf="
                          data.column.field === companySummaryNameField;
                          else defaultUrlTemplate
                        ">
                        <lana-company-summary
                          [isEntity]="displayEntityBreadcrumb"
                          [summary]="data | columnParser : companySummaryField"
                          [url]="url"></lana-company-summary>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!!column.generateLocation">
                    <ng-template #defaultLocationTemplate>
                      <ng-container
                        *ngIf="column.generateLocation(data) as location">
                        <ng-template #noLocation
                          ><span style="color: #a9a9a9">N/A</span></ng-template
                        >
                        <ng-container *ngIf="location; else noLocation">
                          <a [routerLink]="" (click)="locationModal(data)">{{
                            location
                          }}</a>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                    <ng-container
                      *ngIf="
                        column.field === 'fullAddress' ||
                          column.field === 'lastKnownFullAddress';
                        else defaultLocationTemplate
                      ">
                      <ng-container
                        *ngIf="column.generateLocation(data) as location">
                        <ng-template #noLocation
                          ><span style="color: #a9a9a9">N/A</span></ng-template
                        >
                        <ng-container
                          *ngIf="location[column.field]; else noLocation">
                          <a [routerLink]="" (click)="locationModal(data)">{{
                            location[column.field]
                          }}</a>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!!column.generateStatus">
                    <ng-container *ngIf="column.generateStatus(data) as status">
                      <ng-template #defaultStatus>{{
                        status.userStatusName
                      }}</ng-template>
                      <ng-container
                        *lanaHasRole="column.roles; else defaultStatus">
                        <ng-container
                          *ngIf="
                            status.userStatusId === 3 ||
                              status.userStatusId === 4;
                            else defaultStatus
                          ">
                          <a
                            class="mr-2"
                            [routerLink]=""
                            (click)="statusModal(data, 'resend')"
                            >Re-send Invite</a
                          >
                          <a
                            [routerLink]=""
                            (click)="statusModal(data, 'cancel')"
                            >Cancel Invite</a
                          >
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>
            </e-column>
          </ng-template>
          <ng-template #defaultVisible>
            <ng-template #defaultColumnFormatterTemplate>
              <ng-container
                *ngIf="column.formatter; else defaultColumnTemplate">
                <ng-template #defaultColumnFormatter>
                  <e-column
                    [field]="column.field"
                    [headerText]="column.headerText"
                    [formatter]="column.formatter"
                    [disableHtmlEncode]="false"
                    [allowSorting]="column.allowSorting"
                    [autoFit]="column.autoFit"
                    [width]="column.width"
                    clipMode="EllipsisWithTooltip"></e-column>
                </ng-template>
                <ng-container
                  *ngIf="
                    column.field === 'lanaVisionEvent.imageId';
                    else defaultColumnFormatter
                  ">
                  <e-column
                    [field]="column.field"
                    [headerText]="column.headerText"
                    [width]="column.width"
                    [allowSorting]="false">
                    <ng-template #template let-data>
                      <lana-image-data
                        [field]="column.field"
                        [data]="data"
                        [timeZone]="tz"></lana-image-data>
                    </ng-template>
                  </e-column>
                </ng-container>
              </ng-container>
            </ng-template>

            <ng-container
              *ngIf="column.format; else defaultColumnFormatterTemplate">
              <e-column
                [field]="column.field"
                [headerText]="column.headerText"
                [format]="column.format"
                [disableHtmlEncode]="false"
                [allowSorting]="column.allowSorting"
                [autoFit]="column.autoFit"
                [width]="column.width"
                clipMode="EllipsisWithTooltip"></e-column>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="column.generateVisibility; else defaultVisible">
            <ng-container *ngIf="column.generateVisibility(data) as history">
              <e-column
                [field]="column.field"
                [headerText]="column.headerText"
                [visible]="checkVisibility(data)"
                [disableHtmlEncode]="false"
                [allowSorting]="column.allowSorting"
                [autoFit]="column.autoFit"
                [width]="column.width"
                clipMode="EllipsisWithTooltip"></e-column>
            </ng-container>
          </ng-container>
        </ng-container>
      </e-columns>
    </ejs-grid>
  </div>
  <ng-container *ngIf="loading | async; else mainGrid">
    <div class="d-flex justify-content-center">
      <lana-loading [fullscreen]="true"></lana-loading>
    </div>
  </ng-container>
</ng-container>
