<ng-container
  [ngTemplateOutlet]="
    eventData?.lanaVisionEvent?.imageId
      ? vision
      : eventData?.sensorBulkSamples && eventData.sensorBulkSamples.length > 0
      ? chill
      : default
  "></ng-container>
<ng-template #vision>
  <h5>
    <img class="header-asset-type" src="/assets/asset-types/vision.svg" />
    History:
    {{ eventData?.assetName }}
  </h5>
</ng-template>
<ng-template #chill>
  <h5>
    <img class="header-asset-type" src="/assets/asset-types/chill.svg" />
    History:
    {{ eventData?.assetName }}
  </h5>
</ng-template>
<ng-template #default>
  <h5>
    <i class="fa fa-calendar-alt"></i> History: {{ eventData?.assetName }}
  </h5>
</ng-template>
<ng-container
  *ngIf="
    eventData?.lanaVisionEvent &&
    (eventData?.lanaVisionEvent?.occupancySensorVolumePercentFull === null ||
      eventData?.lanaVisionEvent?.occupancySensorVolumePercentFull ===
        undefined)
  ">
  <div class="alert alert-danger mt-3 ml-3 mr-3 mb-0" role="alert">
    <i class="fas fa-sync-alt calibrating"></i> CALIBRATING
  </div>
</ng-container>
<div class="d-flex align-items-stretch">
  <div class="flex-shrink-1 pt-3">
    <p *ngIf="eventData?.entityBreadcrumb?.includes('>>>')">
      <strong>Sub-Company:</strong>
      {{ eventData.entityBreadcrumb | companyBreadcrumb }}
    </p>
    <p>
      <strong>Date/Time:</strong>
      {{
        eventData.responseDateTime
          | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
      }}
    </p>
    <p *ngIf="eventData?.headingInDegrees && eventData?.speedInMph > 0">
      <strong>Heading:</strong>
      {{ eventData.headingInDegrees | heading : true }}
    </p>
    <p *ngIf="eventData?.speedInMph > 0">
      <strong>Speed:</strong> {{ eventData.speedInMph | number : '1.0-0' }} MPH
    </p>
    <p *ngIf="eventData?.trackerSerialNumber">
      <strong>Tracker Serial Number:</strong>
      {{ eventData.trackerSerialNumber }}
    </p>
    <p><strong>Address:</strong> {{ eventData.fullAddress }}</p>
    <p>
      <strong>Coordinates:</strong> {{ eventData.latitude }},{{
        eventData.longitude
      }}
    </p>
    <p *ngIf="eventData?.eventCodeDescription">
      <strong>Event Type:</strong> {{ eventData.eventCodeDescription }}
    </p>
    <p *ngIf="eventData?.temperature">
      <strong>Temperature: </strong><i class="fas fa-thermometer-half"></i>
      {{ eventData.temperature }}
    </p>
    <p *ngIf="eventData?.humidity">
      <strong>Humidity: </strong><i class="fas fa-tint"></i>
      {{ eventData.humidity }}%
    </p>
    <!-- Asset Battery -->
    <ng-template #defaultAssetBattery>
      <p>
        <strong>Asset Battery:</strong>
        {{
          eventData?.assetBatteryVoltage >= 0
            ? (eventData.assetBatteryVoltage | number : '1.0-1')
            : eventData?.isWiredAsset
            ? 'No Data Available'
            : 'N/A'
        }}
      </p>
    </ng-template>
    <ng-container
      *ngIf="
        eventData?.assetBatteryVoltage &&
        eventData?.assetBatteryVoltageTimestamp
      ">
      <hr />
      <div class="d-flex align-items-stretch">
        <div class="flex-shrink-1">
          <p>
            <strong>Asset Battery</strong>:
            {{
              eventData?.assetBatteryVoltage >= 0
                ? (eventData.assetBatteryVoltage | number : '1.0-1')
                : eventData?.isWiredAsset
                ? 'No Data Available'
                : 'N/A'
            }}
          </p>
          <p *ngIf="eventData?.assetBatteryVoltageTimestamp">
            <strong>Last Received: </strong>
            {{
              eventData?.assetBatteryVoltageTimestamp
                | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
            }}
          </p>
        </div>
      </div>
    </ng-container>
    <!-- Tracker Battery -->
    <ng-template #defaultTrackerBattery>
      <p *ngIf="eventData?.deviceBatteryVoltage > 0">
        <strong>Tracker Battery:</strong>
        {{ eventData.deviceBatteryVoltage | number : '1.0-1' }}
      </p>
    </ng-template>
    <ng-container
      *ngIf="
        eventData?.deviceBatteryVoltage &&
        eventData?.deviceBatteryVoltageTimestamp
      ">
      <hr />
      <div class="d-flex align-items-stretch">
        <div class="flex-shrink-1">
          <p>
            <strong>Tracker Battery</strong>:
            {{ eventData.deviceBatteryVoltage | number : '1.0-1' }}
          </p>
          <p *ngIf="eventData?.deviceBatteryVoltageTimestamp">
            <strong>Last Received: </strong>
            {{
              eventData?.deviceBatteryVoltageTimestamp
                | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
            }}
          </p>
        </div>
      </div>
    </ng-container>
    <!-- Temperature and Humidity -->
    <ng-container
      *ngIf="
        eventData?.sensorBulkSamples && eventData.sensorBulkSamples.length > 0
      ">
      <hr />
      <div class="d-flex align-items-stretch">
        <div class="flex-grow-1">
          <p style="text-decoration: underline">
            <strong>Temperature and Humidity</strong>
          </p>
          <div class="row">
            <div class="col-6">
              <p *ngIf="eventData?.sensorBulkSamples">
                <span [innerHTML]="eventData | trackerSensors : true"></span>
              </p>
            </div>
            <div class="col-6">
              <p *ngIf="eventData?.sensorBulkSamples">
                <span [innerHTML]="eventData | trackerSensors"></span>
              </p>
            </div>
          </div>
          <p *ngIf="eventData?.lastSensorBulkSamplesTimestamp">
            <strong>Last Received: </strong>
            {{
              eventData.lastSensorBulkSamplesTimestamp
                | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
            }}
          </p>
        </div>
      </div>
    </ng-container>

    <!-- NON Vision Door State -->
    <ng-template #defaultDoorStateTemplate>
      <ng-container
        *ngIf="eventData?.sensorIsDoorOpen || eventData?.sensorBackupBattery">
        <hr />
        <div class="d-flex align-items-stretch">
          <div class="flex-shrink-1">
            <p style="text-decoration: underline">
              <strong>Door Sensor</strong>
            </p>
            <div class="row">
              <div class="col-6" *ngIf="eventData?.sensorIsDoorOpen">
                <p>
                  <span
                    [innerHTML]="
                      eventData?.sensorIsDoorOpen | doorStatus
                    "></span>
                </p>
              </div>
              <div class="col-6" *ngIf="eventData?.sensorBackupBattery">
                <p>
                  <strong>Battery:</strong>
                </p>
                <p>
                  <i class="fas fa-battery-full"></i>
                  {{ eventData?.sensorBackupBattery | number : '1.0-1' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-container
      *ngIf="eventData?.lanaVisionEvent; else defaultDoorStateTemplate">
      <hr />
      <div class="d-flex align-items-stretch">
        <div class="flex-shrink-1">
          <p style="text-decoration: underline">
            <ng-template #defaultTemplate>
              <strong>LANA Vision</strong>
            </ng-template>
            <ng-container *ngIf="theme | async as theme; else defaultTemplate">
              <strong>{{ theme | cargoVision }}</strong>
            </ng-container>
          </p>
          <div class="row lana-vision-values">
            <div class="col-3 pr-0 m-0">
              <p>
                <strong>Percentage<br />Full:</strong>
              </p>
              <p class="custom-p pl-3">
                <ng-container
                  *ngIf="
                    eventData?.lanaVisionEvent &&
                    eventData?.lanaVisionEvent
                      ?.occupancySensorVolumePercentFull !== null &&
                    eventData?.lanaVisionEvent
                      ?.occupancySensorVolumePercentFull !== undefined
                  ">
                  <svg-icon
                    src="/assets/icons/trailer.svg"
                    [svgStyle]="{
                      'width.px': 43,
                      fill: 'rgb(var(--primary))'
                    }"></svg-icon>
                </ng-container>

                {{
                  eventData?.lanaVisionEvent?.occupancySensorVolumePercentFull
                    | percentageFull
                }}
              </p>
            </div>
            <div class="col-3 p-0 m-0">
              <span
                [innerHTML]="
                  eventData?.lanaVisionEvent?.occupancySensorIsDoorOpen
                    | doorStatus
                "></span>
            </div>
            <div
              class="col-3 p-0 m-0"
              *ngIf="eventData?.lanaVisionEvent?.occupancySensorBackupBattery">
              <p>
                <strong>Door<br />Battery:</strong>
              </p>
              <p>
                <i class="fas fa-battery-full"></i>
                {{
                  eventData?.lanaVisionEvent?.occupancySensorBackupBattery
                    | number : '1.0-1'
                }}
              </p>
            </div>
            <div class="col-3 p-0 m-0">
              <span
                [innerHTML]="
                  eventData?.temperatureFahrenheit | temperature
                "></span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="flex-grow-1 lana-vision">
    <!-- LANA Vision image on right side -->
    <ng-template #loadingTemplate>
      <lana-loading></lana-loading>
    </ng-template>
    <ng-template #defaultImageTemplate>
      <ng-container *ngIf="eventData?.lanaVisionEvent">
        <div class="broken-image">
          <span class="icon"><i class="fa fa-image"></i></span>
          <p>Image Unknown</p>
        </div>
      </ng-container>
    </ng-template>
    <ng-container
      *ngIf="
        image | withLoading | async as imageUrl;
        else defaultImageTemplate
      ">
      <ng-template [ngIf]="imageUrl.value"
        ><img [src]="imageUrl.value" (click)="openModal(imageUrl)"
      /></ng-template>
      <ng-template [ngIf]="imageUrl.error">
        <div class="broken-image">
          <span class="icon"><i class="fa fa-image"></i></span>
          <p>Image Unknown</p>
        </div>
      </ng-template>
      <ng-template [ngIf]="imageUrl.loading">
        <lana-loading></lana-loading>
      </ng-template>
    </ng-container>
  </div>
</div>
