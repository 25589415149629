<ng-template #loadingTemplate>
  <lana-loading></lana-loading>
</ng-template>
<ng-template #addAssetTypeTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Asset Type</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Enter an Asset Type to add"
          [formControl]="assetTypeFormControl" />
        <div class="input-group-append">
          <button
            (click)="createAssetType(assetTypeFormControl, modal)"
            type="button"
            class="btn btn-primary">
            Add Asset Type
          </button>
        </div>
      </div>
    </form>
    <ng-container
      *ngIf="assetTypes | async as assetTypes; else loadingTemplate">
      <ul class="list-group small">
        <ng-template #defaultAssetTypeTemplate>
          <li class="list-group-item text-center">No Asset Types Found</li>
        </ng-template>
        <ng-container
          *ngIf="assetTypes.length > 0; else defaultAssetTypeTemplate">
          <li *ngFor="let assetType of assetTypes" class="list-group-item">
            {{ assetType.name }}
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</ng-template>
<ng-template #addAssetMakeTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Asset Make</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container
      *ngIf="assetMakes | async as assetMakes; else loadingTemplate">
      <ng-container *ngIf="selectedAssetType | async as selectedAssetType">
        <form>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter an Asset Make to add"
              [formControl]="assetMakeFormControl" />
            <div class="input-group-append">
              <button
                (click)="
                  createAssetMake(
                    assetMakeFormControl,
                    selectedAssetType.id,
                    modal
                  )
                "
                type="button"
                class="btn btn-primary">
                Add Asset Make
              </button>
            </div>
          </div>
        </form>
        <ul class="list-group small">
          <ng-template #defaultAssetMakeTemplate>
            <li class="list-group-item text-center">No Asset Makes Found</li>
          </ng-template>
          <ng-container
            *ngIf="assetMakes.length > 0; else defaultAssetMakeTemplate">
            <li *ngFor="let assetMake of assetMakes" class="list-group-item">
              {{ selectedAssetType.name }} > {{ assetMake.name }}
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #addAssetModelTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Asset Model</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container
      *ngIf="assetModels | async as assetModels; else loadingTemplate">
      <ng-container
        *ngIf="
          selectedAssetMake | async as selectedAssetMake;
          else loadingTemplate
        ">
        <form class="mb-3">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Enter an Asset Model to add"
              [formControl]="assetModelFormControl" />
            <div class="input-group-append">
              <button
                (click)="
                  createAssetModel(
                    assetModelFormControl,
                    selectedAssetMake.id,
                    modal
                  )
                "
                type="button"
                class="btn btn-primary">
                Add Asset Model
              </button>
            </div>
          </div>
          <lana-error-display
            [control]="assetModelFormControl"
            label="Asset Model"></lana-error-display>
        </form>
        <ul class="list-group small">
          <ng-template #defaultAssetModelTemplate>
            <li class="list-group-item text-center">No Asset Models Found</li>
          </ng-template>
          <ng-container
            *ngIf="assetModels.length > 0; else defaultAssetModelTemplate">
            <li *ngFor="let assetModel of assetModels" class="list-group-item">
              {{ selectedAssetMake.name }} > {{ assetModel.name }}
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #assetDisplayTemplate>
  <p>Test</p>
  <ng-container *ngIf="asset as value">
    <dl>
      <dt>Name</dt>
      <dd>{{ value.name }}</dd>
      <ng-container *ngIf="value?.metaAssetDetails?.metaAssetTypeName">
        <dt>Asset Type</dt>
        <dd>{{ value.metaAssetDetails.metaAssetTypeName }}</dd>
      </ng-container>
      <ng-container *ngIf="value?.metaAssetDetails?.metaAssetMakeName">
        <dt>Asset Make</dt>
        <dd>{{ value.metaAssetDetails.metaAssetMakeName }}</dd>
      </ng-container>
      <ng-container *ngIf="value?.metaAssetDetails?.metaAssetModelName">
        <dt>Asset Model</dt>
        <dd>{{ value.metaAssetDetails.metaAssetModelName }}</dd>
      </ng-container>
      <ng-container *ngIf="formGroup.get('assetYear').value">
        <dt>Asset Year</dt>
        <dd>{{ formGroup.get('assetYear').value }}</dd>
      </ng-container>
      <ng-container *ngIf="value?.licensePlate">
        <dt>License Plate</dt>
        <dd>{{ value.licensePlate }}</dd>
      </ng-container>
      <ng-container *ngIf="value?.vin">
        <dt>VIN</dt>
        <dd>{{ value.vin }}</dd>
      </ng-container>
      <ng-container
        *ngIf="value?.inspectionDueDate && validDate(value.inspectionDueDate)">
        <dt>Inspection Due Date</dt>
        <dd>
          {{
            value.inspectionDueDate | momentDate : 'MMM D, YYYY, h:mm:ss a z'
          }}
        </dd>
      </ng-container>
      <ng-container *ngIf="value?.dateDamaged && validDate(value.dateDamaged)">
        <dt>Date Damaged</dt>
        <dd>
          {{ value.dateDamaged | momentDate : 'MMM D, YYYY, h:mm:ss a z' }}
        </dd>
      </ng-container>
      <dt>Asset is Hard-Wired (Display Asset Battery)</dt>
      <dd>{{ value?.isWiredAsset ? 'Yes' : 'No' }}</dd>
    </dl>
  </ng-container>
</ng-template>
<ng-container *lanaHasRole="[roles.Administrator]; else assetDisplayTemplate">
  <form [formGroup]="formGroup">
    <div class="form-group">
      <label>Name</label>
      <input
        type="text"
        class="form-control"
        placeholder="Name"
        formControlName="name" />
      <lana-error-display
        [control]="formGroup.get('name')"
        label="Name"></lana-error-display>
    </div>
    <div class="form-group">
      <label class="d-flex">
        <div class="flex-grow-1">Asset Type</div>
        <div>
          <a (click)="openModal(addAssetTypeTemplate)" href="javascript:void(0)"
            ><i class="fas fa-plus mr-2"></i> Add Type</a
          >
        </div>
      </label>
      <select class="form-control" formControlName="metaAssetTypeId">
        <option disabled [ngValue]="null">Select Asset Type</option>
        <option
          *ngFor="let assetType of assetTypes | async"
          [ngValue]="assetType.id">
          {{ assetType.name }}
        </option>
      </select>
      <lana-error-display
        [control]="formGroup.get('metaAssetTypeId')"
        label="Asset Type"></lana-error-display>
    </div>
    <div class="form-group" *ngIf="formGroup.get('metaAssetTypeId')?.value">
      <label class="d-flex">
        <div class="flex-grow-1">Asset Make</div>
        <div>
          <a (click)="openModal(addAssetMakeTemplate)" href="javascript:void(0)"
            ><i class="fas fa-plus mr-2"></i> Add Make</a
          >
        </div>
      </label>
      <select class="form-control" formControlName="metaAssetMakeId">
        <option disabled [ngValue]="null">Select Asset Make</option>
        <option
          *ngFor="let assetMake of assetMakes | async"
          [ngValue]="assetMake.id">
          {{ assetMake.name }}
        </option>
      </select>
      <lana-error-display
        [control]="formGroup.get('metaAssetMakeId')"
        label="Asset Make"></lana-error-display>
    </div>
    <div class="form-group" *ngIf="formGroup.get('metaAssetMakeId')?.value">
      <label class="d-flex">
        <div class="flex-grow-1">Asset Model</div>
        <div>
          <a
            (click)="openModal(addAssetModelTemplate)"
            href="javascript:void(0)"
            ><i class="fas fa-plus mr-2"></i> Add Model</a
          >
        </div>
      </label>
      <select class="form-control" formControlName="metaAssetModelId">
        <option disabled [ngValue]="null">Select Asset Model</option>
        <option
          *ngFor="let assetModel of assetModels | async"
          [ngValue]="assetModel.id">
          {{ assetModel.name }}
        </option>
      </select>
      <lana-error-display
        [control]="formGroup.get('metaAssetModelId')"
        label="Asset Model"></lana-error-display>
    </div>
    <div class="form-group">
      <label>Asset Year</label>
      <input
        type="number"
        class="form-control"
        placeholder="Asset Year"
        formControlName="assetYear" />
      <lana-error-display
        [control]="formGroup.get('assetYear')"
        label="Asset Year"></lana-error-display>
    </div>
    <div class="form-group">
      <label>License Plate</label>
      <input
        type="text"
        class="form-control"
        placeholder="License Plate"
        formControlName="licensePlate" />
      <lana-error-display
        [control]="formGroup.get('licensePlate')"
        label="License Plate"></lana-error-display>
    </div>
    <div class="form-group">
      <label>VIN</label>
      <input
        type="text"
        class="form-control"
        placeholder="VIN"
        formControlName="vin" />
      <lana-error-display
        [control]="formGroup.get('vin')"
        label="VIN"></lana-error-display>
    </div>
    <div class="form-group">
      <label>Inspection Due Date</label>
      <input
        type="date"
        class="form-control"
        placeholder="Inspection Due Date"
        formControlName="inspectionDueDate" />
      <lana-error-display
        [control]="formGroup.get('inspectionDueDate')"
        label="Inspection Due Date"></lana-error-display>
    </div>
    <div class="form-group">
      <label>Date Damaged</label>
      <input
        type="date"
        class="form-control"
        placeholder="Date Damaged"
        formControlName="dateDamaged" />
      <lana-error-display
        [control]="formGroup.get('dateDamaged')"
        label="Date Damaged"></lana-error-display>
    </div>
    <div class="form-group">
      <label>Initial Engine Hours</label>
      <input
        type="text"
        class="form-control"
        placeholder="Initial Engine Hours"
        formControlName="initialEngineHours" />
      <lana-error-display
        [control]="formGroup.get('initialEngineHours')"
        label="Initial Engine Hours"></lana-error-display>
    </div>
    <div class="form-group ml-4">
      <input
        type="checkbox"
        class="form-check-input"
        formControlName="isWiredAsset" />
      <label>Asset is Hard-Wired (Display Asset Battery)</label>
      <lana-error-display
        [control]="formGroup.get('isWiredAsset')"
        label="Asset is Hard-Wired"></lana-error-display>
    </div>
    <div class="form-group" *ngIf="!asset">
      <label>Comment</label>
      <textarea class="form-control" formControlName="comment"></textarea>
    </div>
  </form>
</ng-container>
<ng-container *lanaHasRole="[roles.Administrator]">
  <ng-container *ngIf="standalone">
    <hr />
    <div class="d-flex">
      <ng-template #confirmTemplate>
        <div class="d-flex flex-column">
          <div>
            <p>
              Are you sure you wish to delete <strong>{{ asset.name }}</strong
              >?
            </p>
          </div>
          <div class="d-flex">
            <button
              type="button"
              class="btn btn-outline-danger mr-2"
              (click)="toggleConfirm()">
              No, Nevermind
            </button>
            <lana-loading-button
              [loading]="loading | async"
              [noMargin]="true"
              [success]="true"
              (buttonPressed)="deleteAsset(asset.id)"
              >Yes, Delete Asset</lana-loading-button
            >
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="!showConfirm; else confirmTemplate">
        <button
          *ngIf="!!asset"
          type="button"
          class="btn btn-outline-danger mr-auto"
          (click)="toggleConfirm()">
          Delete Asset
        </button>
        <lana-loading-button
          [loading]="loading | async"
          [noMargin]="true"
          [primary]="true"
          (buttonPressed)="submit(formGroup, !!asset)"
          >{{ asset ? 'Save Changes' : 'Create Asset' }}</lana-loading-button
        >
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<lana-contact-us></lana-contact-us>
