import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';
import { AlertsService } from 'src/app/services/alerts.service';

@Component({
  selector: 'lana-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit {
  assetName = 'trailer123';
  @Input() alertName = 'My Alert';
  @Input() alertMessage = 'My Alert Description';
  alertDate = new Date();
  alertAddress = '4592 Ulmerton Rd, Suite 201, Clearwater, FL 33762';
  htmlData = null;

  constructor(
    private alertsService: AlertsService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const model = {
      templateType: 2,
      tokens: {
        '##AlertMessage##': this.alertMessage,
        '##AssetName##': this.assetName,
        '##AlertName##': this.alertName,
        '##AlertAddress##': this.alertAddress,
        '##AlertDate##': this.alertDate
      }
    };
    this.alertsService
      .getEmailTemplate(model)
      .pipe(
        tap((html) => {
          this.htmlData = this.sanitizer.bypassSecurityTrustHtml(html);
        })
      )
      .subscribe();
  }
}
