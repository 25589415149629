<div class="card" @fadeSlideDown>
  <div class="card-header">{{ header }}</div>
  <div class="card-body">
    <ng-template #loadingTemplate>
      <lana-loading></lana-loading>
    </ng-template>
    <ejs-dropdownlist
      #assetType
      id="assetType"
      [dataSource]="assetTypes"
      [fields]="fields"
      (change)="assetTypeChange($event)"
      [placeholder]="assetTypeWatermark"></ejs-dropdownlist>

    <ejs-dropdownlist
      #make
      id="make"
      [dataSource]="makes"
      [fields]="fields"
      (change)="makeChange($event)"
      [placeholder]="makeWatermark"
      [enabled]="false"></ejs-dropdownlist>

    <ejs-multiselect
      #model
      id="model"
      [dataSource]="models"
      [fields]="fields"
      [placeholder]="modelWatermark"
      [enabled]="false"
      (select)="modelSelect($event)"
      (removed)="modelRemoved($event)">
    </ejs-multiselect>
  </div>
  <div class="card-footer text-muted text-center">
    Total: <strong>{{ assetTypes.length }}</strong>
  </div>
</div>
