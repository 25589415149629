import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { Constants } from './../constants';
import { CommentList } from './../models/asset/comment';
import { LanaColumnModel } from './../models/column';
import { Company } from './../models/company/company';
import { CompanyComment } from './../models/company/company-comment';
import { CompanyHierarchy } from './../models/company/company-hierarchy';
import { SubCompany } from './../models/company/sub-company';
import { FilterSettings } from './../models/filter-settings';
import { Lookup } from './../models/lookup';
import {
  OdataResponse,
  OdataResponseTransform
} from './../models/odata-response';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private readonly currentlyViewingCompany = new BehaviorSubject<Company>(null);
  private readonly odataUrl = `${Constants.Odata}/Companies`;
  private readonly url = `${Constants.Api}`;

  readonly columns: LanaColumnModel<Company>[] = [
    {
      field: 'name',
      headerText: 'Company Name',
      generateUrl: (company) => `/management/sub-companies/${company.id}`
    },
    { field: 'assetGroupCount', headerText: 'Group Count' },
    { field: 'cumulativeAssetCount', headerText: 'Total Assets' },
    {
      field: 'directlyAssignedAssetCount',
      headerText: 'Directly Assigned Assets'
    }
  ];

  readonly filterSettings: FilterSettings = {
    columns: [
      {
        field: 'name',
        headerText: 'Company Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  currentlyViewingCompany$ = this.currentlyViewingCompany.asObservable();

  getCompany(companyId: number): Observable<Company> {
    this.currentlyViewingCompany.next(null);

    return companyId
      ? this.httpClient
          .get<Company>(`${this.url}/${companyId}`)
          .pipe(tap((response) => this.currentlyViewingCompany.next(response)))
      : of(null).pipe(tap((_) => this.currentlyViewingCompany.next(null)));
  }

  getCompanies({
    filterQuery,
    pageQuery,
    sortQuery
  }): Observable<OdataResponseTransform<Company>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<Company>>(
          `${this.odataUrl}?companyContext=${companyId}&${pageQuery}${filterQuery}${sortQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getCompaniesExport({
    filterQuery
  }): Observable<OdataResponseTransform<Company>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<Company>>(
          `${this.odataUrl}?companyContext=${companyId}&${filterQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getComments(companyId: number): Observable<CommentList> {
    const url = `${this.url}/${companyId}/Comments`;

    return this.httpClient.get<CommentList>(url).pipe(
      map((response) => {
        response.comments = response.comments.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
        return response;
      })
    );
  }

  createComment(companyComment: CompanyComment): Observable<CommentList> {
    const url = `${this.url}/Comment`;

    return this.httpClient.post<CommentList>(url, companyComment);
  }

  getCompanySubCompanies(companyId: number): Observable<SubCompany> {
    const url = `${this.url}/${companyId}/sub-companies`;

    return this.httpClient.get<SubCompany>(url);
  }

  getSubCompanyBreadcrumbs(): Observable<Lookup[]> {
    return this.authService.companyId$.pipe(
      filter((companyId) => companyId !== null),
      switchMap((companyId) => this.getCompanySubCompanies(companyId)),
      map((response) => this.mapCompanySubCompaniesResponse(response))
    );
  }

  updateAssetCountForCompany(): void {
    const currentValue = this.currentlyViewingCompany.getValue();

    if (currentValue) {
      currentValue.assetCount = currentValue.assetCount - 1;

      this.currentlyViewingCompany.next(currentValue);
    }
  }

  createCompany(model: Partial<Company>): Observable<Company> {
    return this.httpClient.post<Company>(this.url, model);
  }

  updateCompany(model: Partial<Company>): Observable<Company> {
    return this.httpClient
      .put<Company>(this.url, model)
      .pipe(tap((response) => this.currentlyViewingCompany.next(response)));
  }

  deleteCompany(id: number, reclaimEntities: boolean): Observable<void> {
    return this.httpClient.request<void>('delete', this.url, {
      body: { id, reclaimEntities }
    });
  }

  // NEW
  // {
  //   "subCompanies": null,
  //   "id": 726,
  //   "name": "MAF(OASIS CAR FINANCE)",
  //   "parentCompanyId": 1
  // },

  // OLD
  // {
  //   "id": 3002,
  //   "parentCompanyId": 2,
  //   "name": " Foundation of Hysterical Hippopotamus Rescue of the Arctic",
  //   "directlyAssignedAssetCount": 0,
  //   "cumulativeAssetCount": 0,
  //   "subCompanies": []
  // },

  private flattenCompanyHierarch(response: CompanyHierarchy[]): {
    id: number;
    parentCompanyId: number;
    name: string;
    hasChild: boolean;
    filter: string;
  }[] {
    return response.reduce((working, next) => {
      // console.log('working -->', working);

      working =
        working.length > 0
          ? working.concat({
              id: next.id,
              parentCompanyId: next.parentCompanyId,
              name: next.name,
              hasChild: next?.subCompanies?.length > 0,
              filter: next.name
            })
          : [];

      // console.log('next -->', next?.subCompanies);
      if (next?.subCompanies?.length > 0) {
        working = working.concat(
          this.flattenCompanyHierarch(next.subCompanies)
        );
        next.subCompanies = [];
        // console.log('working concat -->', working);
      }
      return working;
    }, [] as { id: number; parentCompanyId: number; name: string; hasChild: boolean; filter: string }[]);
  }

  flattenNestedArray(arr) {
    if (Array.isArray(arr)) {
      return arr.reduce((acc, val) => {
        acc.push({
          id: val.id,
          parentCompanyId: val.parentCompanyId,
          name: val.name,
          hasChild: val?.subCompanies?.length > 0
        });

        if (val.subCompanies.length > 0) {
          acc.push(...this.flattenNestedArray(val));
        }
        return acc;
      }, [] as { id: number; parentCompanyId: number; name: string; hasChild: boolean }[]);
    }
    return [];
  }

  getCompanyHierarchy(): Observable<
    {
      id: number;
      parentCompanyId?: number;
      name: string;
      hasChild?: boolean;
      filter: string;
    }[]
  > {
    return this.authService.currentPlatform$.pipe(
      switchMap((currentPlatform) =>
        combineLatest([
          of(currentPlatform),
          this.httpClient.get<CompanyHierarchy>(
            `${this.url}/${currentPlatform}/Company/hierarchy`
          )
        ])
      ),
      map(([_, response]) => this.flattenNestedArray(response))
    );
  }

  private mapCompanySubCompaniesResponse(response: SubCompany): Lookup[] {
    const getList = (working: SubCompany[], subCompany: SubCompany) => {
      if (subCompany.subCompanies.length === 0) {
        return working.reduce((w, n, i) => {
          const prev = w[i - 1];

          return prev
            ? [
                ...w,
                {
                  name: [prev.name, n.name].join(' > '),
                  id: n.id
                }
              ]
            : [{ name: n.name, id: n.id }];
        }, []);
      } else {
        return subCompany.subCompanies
          .map((sc) => getList([...working, sc], sc))
          .reduce((w, n) => [...w, ...n], [])
          .reduce(
            (w, n) => (!!w.find((v) => v.id === n.id) ? w : [...w, n]),
            []
          );
      }
    };

    return getList([], response);
  }
}
