<ng-template #defaultTemplate>
  {{ summary.name }}
</ng-template>
<ng-container *ngIf="url; else defaultTemplate">
  <a [routerLink]="url">{{ summary.name }}</a>
</ng-container>
<div class="small" *ngIf="summary.companyBreadcrumb && !isEntity">
  {{ summary.companyBreadcrumb | companyBreadcrumb }}
</div>
<div class="small" *ngIf="summary.entityBreadcrumb && isEntity">
  {{ summary.entityBreadcrumb | companyBreadcrumb }}
</div>
