import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  EvaluationCriteriaModel,
  Operator
} from 'src/app/models/alerts/alerts';
import { MapCloudGeozoneService } from 'src/app/services/map-cloud-geozone.service';

@Component({
  selector: 'lana-evaluation-tile',
  templateUrl: './evaluation-tile.component.html',
  styleUrls: ['./evaluation-tile.component.scss'],
  animations: [
    trigger('enterExitLeft', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-200px)' }),
        animate(
          '300ms ease-in',
          style({ opacity: 1, transform: 'translateX(0)' })
        )
      ]),
      transition(':leave', [
        animate(
          '300ms ease-in',
          style({ opacity: 0, transform: 'translateX(-200px)' })
        )
      ])
    ]),
    trigger('fadeSlideDown', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' }))
      ])
    ])
  ]
})
export class EvaluationTileComponent implements OnInit {
  @Input() operator: Operator;
  @Input() criterionId: number;
  @Input() operators: Operator[];
  @Input() hideOperator: boolean = true;
  @Input() typeName: string;
  @Input() evaluationDetailsTypeId: number;
  @Input() lastTrigger: boolean = false;
  @Input() defaultThreshold: string;
  @Input() step: boolean = false;
  @Input() readonly: boolean = false;
  @Input() criterionUnit: string;
  @Input() inputDataType: string = 'string';
  @Output() formValue = new EventEmitter<any>();
  @Output() addAnother = new EventEmitter<any>();
  @Output() removeOperator = new EventEmitter<boolean>();
  formGroup: UntypedFormGroup;
  evaluationCriteriaModels: EvaluationCriteriaModel;
  isGeozone = false;
  public geozoneDirections: { [key: string]: Object }[] =
    this.mapCloudGeozoneService.geozoneDirections;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngbModal: NgbModal,
    private mapCloudGeozoneService: MapCloudGeozoneService
  ) {}

  addOperator(content: any): void {
    this.isGeozone =
      this.isGeozone || (this.typeName && this.typeName.includes('Geozone'));
    if (this.readonly) {
      this.confirm(this.formGroup, null);
    } else {
      from(this.ngbModal.open(content).result).pipe(first()).subscribe();
    }
  }

  confirm(formGroup: UntypedFormGroup, modal: NgbActiveModal): void {
    const [model] = this.operator?.evaluationCriteriaModels
      ? this.operator?.evaluationCriteriaModels.map((model) => model)
      : [null];

    const fullObj = {
      ...this.operator,
      parentEvaluationDetail: null,
      operatorId: formGroup.value.operatorId,
      childEvaluationDetailModels: [],
      evaluationCriteriaModels: []
    };
    const validGeozone =
      this.isGeozone &&
      (formGroup.value.includeAllGeozones ||
        formGroup.value?.geozoneIds.length > 0) &&
      formGroup.value?.geozoneDirection;
    const common = {
      operatorId: this.operator.id ?? model.operatorId,
      criterionId: this.criterionId ?? model.criterionId
    };

    if (validGeozone) {
      fullObj['evaluationCriteriaModels'] = [
        {
          ...common,
          thresholdValue: JSON.stringify({
            includeAllGeozones: formGroup.value?.includeAllGeozones ?? false,
            geozoneIds: formGroup.value?.geozoneIds ?? [],
            geozoneDirection: formGroup.value?.geozoneDirection ?? null
          })
        }
      ];
    } else if (formGroup.valid) {
      fullObj['evaluationCriteriaModels'] = [
        {
          ...common,
          thresholdValue: formGroup.value.thresholdValue ?? model.thresholdValue
        }
      ];
    }

    if (typeof this.typeName !== 'undefined') {
      fullObj['typeName'] = this.typeName;
    }
    if (typeof this.evaluationDetailsTypeId !== 'undefined') {
      fullObj['evaluationDetailsTypeId'] = this.evaluationDetailsTypeId;
    }
    if (typeof this.readonly !== 'undefined') {
      fullObj['readonly'] = this.readonly;
    }
    if (typeof this.criterionUnit !== 'undefined') {
      fullObj['criterionUnit'] = this.criterionUnit;
    }
    this.formValue.emit(fullObj);

    if (modal) {
      modal.close();
    }
  }

  remove(modal: NgbActiveModal): void {
    this.removeOperator.emit(true);
    modal.close();
  }

  changeOperator(formGroup: UntypedFormGroup, id: number): void {
    const toggleOperator = id === 7 ? 8 : 7;
    formGroup.get('operatorId').setValue(toggleOperator);

    this.confirm(formGroup, null);
  }

  displayValue(value: string) {
    const json = JSON.parse(value);
    let retValue = `Threshold value: ${value}`;

    // geozone specific
    if (json?.geozoneDirection || json?.geozoneDirection === 0) {
      const [direction]: any[] = this.geozoneDirections.filter(
        (direction) => direction.id === json.geozoneDirection + ''
      );
      const directionValue =
        direction?.value || direction?.value === 0
          ? direction.value
          : 'either enters or exits';
      retValue = json?.includeAllGeozones
        ? `An alert will be sent when an asset ${directionValue} any geozone.`
        : `An alert will be sent when an asset ${directionValue} any of the selected geozones.`;
    }

    return retValue;
  }

  ngOnInit(): void {
    this.evaluationCriteriaModels = this.operator?.evaluationCriteriaModels
      ? this.operator.evaluationCriteriaModels[0]
      : null;
    const parsedThresholdValue = this.evaluationCriteriaModels
      ? JSON.parse(this.evaluationCriteriaModels?.thresholdValue)
      : null;
    this.isGeozone =
      parsedThresholdValue?.includeAllGeozones ||
      (parsedThresholdValue?.geozoneIds &&
        parsedThresholdValue.geozoneIds.length > 0);

    this.formGroup = this.formBuilder.group({
      thresholdValue: this.formBuilder.control(
        parsedThresholdValue
          ? parsedThresholdValue
          : this.readonly
          ? this.defaultThreshold
          : null,
        Validators.required
      ),
      operatorId: this.formBuilder.control(
        this.operator?.operatorId ? this.operator.operatorId : 7
      ),
      includeAllGeozones: this.formBuilder.control(
        typeof parsedThresholdValue?.includeAllGeozones === 'undefined'
          ? true
          : parsedThresholdValue?.includeAllGeozones
      ),
      geozoneIds: this.formBuilder.control(
        parsedThresholdValue?.geozoneIds ? parsedThresholdValue.geozoneIds : []
      ),
      geozoneDirection: this.formBuilder.control(
        parsedThresholdValue?.geozoneDirection + '' ?? '2'
      )
    });
  }
}
