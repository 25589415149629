import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'temperature'
})
export class TemperaturePipe implements PipeTransform {
  transform(tempVal: number): string {
    return (
      '<p><strong>Ambient Temperature:</strong></p><p>' +
      (tempVal || tempVal === 0
        ? `<i class="fas fa-thermometer-half"></i> ${
            Math.round(tempVal * 10) / 10
          }\xB0F<span>`
        : 'N/A') +
      '</p>'
    );
  }
}
