import { Component, Input, OnInit } from '@angular/core';
import { ToolbarItems } from '@syncfusion/ej2-angular-pivotview';
import { GridSettings } from '@syncfusion/ej2-pivotview/src/pivotview/model/gridsettings';

@Component({
  selector: 'lana-pivot-report',
  templateUrl: './pivot-report.component.html',
  styleUrls: ['./pivot-report.component.scss']
})
export class PivotReportComponent implements OnInit {
  @Input() dataSourceSettings;
  @Input() gridSettings: GridSettings;
  @Input() width;
  @Input() allowExcelExport = false;
  @Input() showToolbar = false;
  @Input() toolbarOptions: ToolbarItems[];
  ngOnInit(): void {}
}
