import { AgmMap } from '@agm/core';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lana-reports-modal-map',
  templateUrl: './reports-modal-map.component.html',
  styleUrls: ['./reports-modal-map.component.scss']
})
export class ReportsModalMapComponent {
  @Input() child;
  @ViewChild('content') content;
  @ViewChild(AgmMap) map: AgmMap;

  latitude: number;
  longitude: number;
  fullAddress: string;
  defaultZoomInLevel = 15;
  data: any;

  constructor(private modalService: NgbModal) {}

  open(data) {
    this.data = data;
    this.modalService.dismissAll();
    const latitude = data?.latitude ? data.latitude : data?.lastKnownLatitude;
    const longitude = data?.longitude
      ? data.longitude
      : data?.lastKnownLongitude;
    const fullAddress = data?.fullAddress
      ? data.fullAddress
      : data?.lastKnownFullAddress;
    if (latitude && longitude) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.fullAddress = fullAddress;
      this.modalService.open(this.content);
    }
  }
}
