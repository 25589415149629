import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trackerSensors'
})
export class TrackerSensorsPipe implements PipeTransform {
  alarmState(state: number) {
    switch (state) {
      case 2:
        return 'blue';
      case 3:
        return 'red';
      case 1:
      default:
        return 'green';
    }
  }

  transform(asset: any, ...args: unknown[]): unknown {
    const [temp] = args;
    let samplesArray = [];

    if (asset?.lastSensorBulkSamples) {
      const samples: any = asset.lastSensorBulkSamples
        .replace(/[\[\]']+/g, '')
        .replace(/['"]+/g, '');
      samplesArray = samples.split(',');
    } else if (asset?.sensorBulkSamples) {
      samplesArray = asset.sensorBulkSamples;
    }
    const temperatureAlarmState = asset?.temperatureAlarmState
      ? this.alarmState(asset.temperatureAlarmState)
      : 'green';
    const humidityAlarmState = asset?.humidityAlarmState
      ? this.alarmState(asset.humidityAlarmState)
      : 'green';
    let retVal = '';

    if (temp && typeof samplesArray[1] !== 'undefined') {
      const temperatureInC = samplesArray[1];
      const temperature = (temperatureInC * 9) / 5 + 32;
      retVal += `<span class="tracker-sensor ${temperatureAlarmState}"><i class="fas fa-thermometer-half"></i> ${
        Math.round(temperature * 10) / 10
      }\xB0F</span>`;
    }
    if (
      !temp &&
      typeof samplesArray[2] !== 'undefined' &&
      parseFloat(samplesArray[2]) > 0
    ) {
      retVal += ` <span class="tracker-sensor ${humidityAlarmState}"><i class="fas fa-tint"></i> ${
        Math.round(samplesArray[2] * 10) / 10
      }%<span>`;
    }
    return retVal;
  }
}
