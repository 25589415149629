import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil,
  tap
} from 'rxjs/operators';

import { CompanyHierarchy } from './../../models/company/company-hierarchy';
import { CompanyService } from './../../services/company.service';
import { SignalRService } from './../../services/signalr.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lana-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanySelectorComponent implements OnInit, OnDestroy {
  constructor(
    private companyService: CompanyService,
    private formBuilder: UntypedFormBuilder,
    private signalRService: SignalRService,
    private ngbModal: NgbModal,
    private router: Router
  ) {}

  @Output() companySelected = new EventEmitter<{ id: number; name: string }>();
  @Input() internalCompany = false;
  @Input() closed = true;

  @ViewChild('content') content: TemplateRef<any>;

  platforms: { value: string; name: string }[] = [
    {
      value: 'callpass',
      name: 'Callpass'
    },
    {
      value: 'lana',
      name: 'LANA'
    },
    {
      value: 'igotcha',
      name: 'iGotcha'
    }
  ];

  private destroy = new Subject<void>();

  fields: Observable<{
    dataSource: any[];
    id: string;
    parentID: string;
    text: string;
    hasChildren: string;
    expanded: string;
  }>;
  searchFormControl: UntypedFormControl;
  selected: { id: number; name: string };
  treeview: TreeViewComponent;
  formGroup: UntypedFormGroup;
  showText = 'callpass';

  dismiss(modal: NgbActiveModal): void {
    modal.dismiss();
    this.selected = null;
  }

  openModel(): void {
    this.ngbModal.open(this.content, {
      backdrop: 'static',
      size: 'lg',
      scrollable: true
    });
  }

  nodeSelected({
    id,
    text
  }: {
    id: string;
    pid: number;
    text: string;
    hasChild: boolean;
  }): void {
    this.selected = { id: +id, name: text };
  }

  selectCompany(
    selected: { id: number; name: string },
    modal: NgbActiveModal
  ): void {
    this.companySelected.emit(selected);
    modal.close();
    this.selected = null;
    this.searchFormControl.reset();
  }

  private searchNodes(value: string, data: CompanyHierarchy[]) {
    this.selected = null;

    const search = (value || '').trim();
    const predicates = [];
    const array = [];
    const filter = [];

    if (search === '') {
      this.changeDataSource(data, this.treeview);
    } else {
      // const wholePredicate = new Predicate('name', 'contains', search, true);
      const noWhiteSpacePredicate = new Predicate(
        'filter',
        'contains',
        search,
        true
      );
      // const filteredList = new DataManager(data).executeLocal(new Query().where(wholePredicate).where(noWhiteSpacePredicate));
      const filteredList = new DataManager(data).executeLocal(
        new Query().where(noWhiteSpacePredicate)
      );

      for (let j = 0; j < filteredList.length; j++) {
        filter.push(filteredList[j]['id']);

        const filters = this.getFilterItems(filteredList[j], data);

        for (let i = 0; i < filters.length; i++) {
          if (array.indexOf(filters[i]) === -1 && filters[i] != null) {
            array.push(filters[i]);
            predicates.push(new Predicate('id', 'equal', filters[i], false));
          }
        }
      }

      if (predicates.length === 0) {
        this.changeDataSource([], this.treeview);
      } else {
        const query = new Query().where(Predicate.or(predicates));
        const newList = new DataManager(data).executeLocal(query);
        this.changeDataSource(newList, this.treeview);
        setTimeout(() => this.treeview.expandAll(), 100);
      }
    }
  }

  setExpandedNodes(treeview: TreeViewComponent): void {
    this.treeview = treeview;
    treeview.expandAll();
  }

  private changeDataSource(data: any[], treeview: TreeViewComponent): void {
    treeview.fields = {
      dataSource: data,
      id: 'id',
      text: 'name',
      child: 'subCompanies'
    };
  }

  private getFilterItems(fList: any, list: any): any {
    const nodes = [];
    nodes.push(fList['id']);
    const query2 = new Query().where('id', 'equal', fList['pid'], false);
    const fList1 = new DataManager(list).executeLocal(query2);

    if (fList1.length > 0) {
      const pNode = this.getFilterItems(fList1[0], list);

      for (let i = 0; i < pNode.length; i++) {
        if (nodes.indexOf(pNode[i]) === -1 && pNode[i] != null) {
          nodes.push(pNode[i]);
        }
      }

      return nodes;
    }

    return nodes;
  }

  ngOnInit(): void {
    this.searchFormControl = this.formBuilder.control('');

    let formValue = 'igotcha';

    if (this.router.url.includes('lana')) {
      formValue = 'lana';
      this.showText = 'lana';
    } else {
      formValue = 'igotcha';
      this.showText = 'igotcha';
    }

    this.formGroup = this.formBuilder.group({
      platform: this.formBuilder.control(formValue)
    });

    this.fields = this.companyService.getCompanyHierarchy().pipe(
      map((dataSource) => ({
        dataSource,
        id: 'id',
        parentID: 'parentCompanyId',
        text: 'name',
        hasChildren: 'hasChild',
        expanded: 'expanded'
      })),
      shareReplay(1)
    );

    const valueChanges$ = this.searchFormControl.valueChanges.pipe(
      map((value) => ((value as string) || '').trim()),
      distinctUntilChanged(),
      debounceTime(300)
    );

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250),
        tap((value) => (this.showText = value.platform))
      )
      .subscribe();

    combineLatest([valueChanges$, this.fields])
      .pipe(
        takeUntil(this.destroy),
        tap(([value, { dataSource }]) => this.searchNodes(value, dataSource))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
