import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  LastLocation,
  NegotiateResponse
} from './../models/signalr/negotiate-response';
import { AuthService } from './auth.service';
import { ConfigsLoaderService } from './configs-loader.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService implements OnDestroy {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private configService: ConfigsLoaderService
  ) {
    this.init();
  }

  private readonly lastLocation = new BehaviorSubject<LastLocation>(null);
  private readonly commandsData = new BehaviorSubject<any>(null);
  readonly commandsData$ = this.commandsData.asObservable();
  private url = this.configService?.configs?.signalr;
  private commandsUrl = this.configService?.configs?.commandsUrl;
  readonly lastLocation$ = this.lastLocation.asObservable();

  public commandReceived = new BehaviorSubject<boolean>(null);
  public commandReceived$ = this.commandReceived.asObservable();
  public companyId: number;
  public adToken: string;
  public adHeader: HttpHeaders;
  public connection: signalR.HubConnection;
  public commandsConnection: signalR.HubConnection;

  private init(): void {
    this.authService.adToken$
      .pipe(
        tap((data) => {
          this.adToken = data;
        })
      )
      .subscribe();
    this.adHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.adToken
    });
  }

  negotiateEventTracking() {
    this.lastLocation.next(null);
    return this.authService.companyId$.pipe(
      tap((companyId) => (this.companyId = companyId)),
      switchMap((companyId) =>
        this.httpClient.post<NegotiateResponse>(
          `${this.url}/${companyId}/negotiate?clientId=lana-ui`,
          null,
          {
            headers: this.adHeader
          }
        )
      ),
      tap((data) => this.createConnection(data)),
      switchMap(() =>
        this.httpClient.post(
          `${this.url}/subscribe?clientId=lana-ui`,
          {
            companyId: this.companyId
          },
          {
            headers: this.adHeader
          }
        )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  negotiateCommandTracking() {
    return this.authService.companyId$.pipe(
      tap((companyId) => (this.companyId = companyId)),
      switchMap((companyId) =>
        this.httpClient.post<NegotiateResponse>(
          // new url
          `${this.commandsUrl}/${companyId}/negotiate?clientId=lana-ui`,
          null,
          {
            headers: this.adHeader
          }
        )
      ),
      tap((data) => this.createCommandsConnection(data)),
      switchMap(() =>
        // new url
        this.httpClient.post(
          `${this.commandsUrl}/subscribe?clientId=lana-ui`,
          {
            companyId: this.companyId
          },
          {
            headers: this.adHeader
          }
        )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  private createCommandsConnection(data) {
    this.commandsConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(data.url, { accessTokenFactory: () => data.accessToken })
      .withAutomaticReconnect()
      .build();

    this.commandsConnection
      .start()
      .then(function () {
        // console.log('%cConnected!', 'color:green');
      })
      .catch((error) => {
        console.error(error);
      });

    this.commandsConnection.on('commandResponse', (data: string) => {
      const json = JSON.parse(data);
      this.commandsData.next(json);
    });
  }
  negotiateCommandsUnsubscribe() {
    const company = this.companyId
      ? this.companyId
      : localStorage.getItem('selectedCompanyId');
    if (this.connection) {
      this.connection.stop();
    }
    //new url
    return this.httpClient.post(
      `${this.commandsUrl}/unsubscribe?clientId=lana-ui`,
      {
        companyId: +company
      },
      {
        headers: this.adHeader
      }
    );
  }

  negotiateUnsubscribe() {
    const company = this.companyId
      ? this.companyId
      : localStorage.getItem('selectedCompanyId');
    if (this.connection) {
      this.connection.stop();
    }
    return this.httpClient.post(
      `${this.url}/unsubscribe?clientId=lana-ui`,
      {
        companyId: +company
      },
      {
        headers: this.adHeader
      }
    );
  }

  private createConnection(data) {
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(data.url, { accessTokenFactory: () => data.accessToken })
      .withAutomaticReconnect()
      .build();

    this.connection
      .start()
      .then(function () {
        // console.log('%cConnected!', 'color:green');
      })
      .catch((error) => {
        console.error(error);
      });

    this.connection.on('lastLocation', (data: string) => {
      const json: LastLocation = JSON.parse(data);
      this.lastLocation.next(json);
    });
  }

  ngOnDestroy(): void {
    this.negotiateUnsubscribe();
  }
}
