import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'lana-widget-details',
  templateUrl: './widget-details.component.html',
  styleUrls: ['./widget-details.component.scss']
})
export class WidgetDetailsComponent implements OnInit {
  formGroup: UntypedFormGroup;
  dataTypes$: Observable<any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      title: this.formBuilder.control('', Validators.required),
      subTitle: this.formBuilder.control('', Validators.required),
      dataTypes: this.formBuilder.control('', Validators.required)
    });

    this.dataTypes$ = this.dashboardService.getColumns();
  }
}
