<div class="virtual-scroll">
  <cdk-virtual-scroll-viewport class="list" autosize itemSize="90">
    <ng-container *cdkVirtualFor="let geozone of mapCloudGeozoneGroupings">
      <ng-template #defaultGeozones>
        <div
          routerLink="./"
          [queryParams]="{ categorySearchResultId: geozone.id }"
          queryParamsHandling="merge">
          <lana-cloud-based-geozone-list-detail-item
            [geozone]="geozone"
            (showGeozoneOnMap)="showGeozoneOnMap.emit(geozone)"
            (hideGeozoneOnMap)="hideGeozoneOnMap.emit()"
            [selected]="
              currentlyShowingHoverGeozoneData?.id === geozone?.id
            "></lana-cloud-based-geozone-list-detail-item>
        </div>
      </ng-template>
      <ng-container
        *ngIf="geozone?.groupName !== undefined; else defaultGeozones">
        <div
          class="d-flex align-items-center bg-light-blue font-weight-bold pl-3 pr-2 pt-2 pb-2">
          <div>
            {{ geozone.groupName }}
          </div>
          <div class="ml-auto small">
            {{ geozone.groupCount | number }} Geozone<ng-container
              *ngIf="geozone.groupCount !== 1"
              >s</ng-container
            >
          </div>
        </div>
      </ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
