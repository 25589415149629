import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { ListView } from '@syncfusion/ej2-angular-lists';

@Component({
  selector: 'lana-multi-select-list-view',
  templateUrl: './multi-select-list-view.component.html',
  styleUrls: ['./multi-select-list-view.component.scss'],
  animations: [
    trigger('enterExitLeft', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-200px)' }),
        animate(
          '300ms ease-in',
          style({ opacity: 1, transform: 'translateX(0)' })
        )
      ]),
      transition(':leave', [
        animate(
          '300ms ease-in',
          style({ opacity: 0, transform: 'translateX(-200px)' })
        )
      ])
    ]),
    trigger('fadeSlideDown', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' }))
      ])
    ])
  ]
})
export class MultiSelectListViewComponent implements AfterViewInit {
  @Input() header: string;
  @Input() data: any;
  @Input() showButtons = false;

  private selectedItems: any;

  get selected(): any {
    return this.selectedItems;
  }

  @Input()
  set selected(selected: any) {
    this.selectedItems = selected;

    this.data.forEach((item) => {
      var itemToCheck = selected.find((d) =>
        typeof d === 'number' ? item.id === d : d.id === item.id
      );
      if (typeof item !== 'number') {
        item['isChecked'] = undefined !== itemToCheck;
      }

      undefined === itemToCheck
        ? this.listview?.uncheckItem(item)
        : this.listview?.checkItem(item);
    });
  }

  @ViewChild('listview') listview: ListView;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() count = new EventEmitter<number>();

  public fields: Object = { text: 'name', id: 'id' };

  ngAfterViewInit(): void {
    this.selectedItems.forEach((item) => {
      var itemToCheck = this.data.find((d) =>
        typeof d === 'number' ? item.id === d : d.id === item.id
      );
      if (itemToCheck) {
        itemToCheck['isChecked'] = true;
      }
    });
  }

  onSelect(event) {
    this.selectionChange.emit([{ ...event.data, isChecked: event.isChecked }]);

    const data: any = this.listview.getSelectedItems().data;
    this.selected = data.map((asset) => asset.id);

    this.selectionChange.emit(this.selected);
    this.count.emit(this.selected.length);
  }

  checkAllItems(): void {
    this.listview?.checkAllItems();
    this.updateSelection();
  }

  uncheckAllItems(): void {
    this.listview?.uncheckAllItems();
    this.updateSelection();
  }

  updateSelection(): void {
    const data: any = this.listview.getSelectedItems().data;

    this.selected = data;
    this.selectionChange.emit(this.data);
    this.count.emit(this.selected.length);

    this.selectionChange.emit(data.map((asset) => asset.id));
  }
}
