import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  ɵstringify as stringify
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Roles } from './../../models/auth/role';
import { AuthService } from './../../services/auth.service';

@Directive({
  selector: '[lanaHasRole]'
})
export class HasRoleDirective implements OnDestroy {
  constructor(
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    templateRef: TemplateRef<LanaHasRoleContext>
  ) {
    this.thenTemplateRef = templateRef;
  }

  private readonly destroy = new Subject<void>();

  private context = new LanaHasRoleContext();
  private thenTemplateRef: TemplateRef<LanaHasRoleContext> = null;
  private elseTemplateRef: TemplateRef<LanaHasRoleContext> = null;
  private thenViewRef: EmbeddedViewRef<LanaHasRoleContext> = null;
  private elseViewRef: EmbeddedViewRef<LanaHasRoleContext> = null;

  static lanaHasRoleUseIfTypeGuard: void;

  static ngTemplateGuard_lanaHasRole: 'binding';

  static ngTemplateContextGuard(
    dir: LanaHasRoleContext,
    ctx: any
  ): ctx is HasRoleDirective {
    return true;
  }

  @Input()
  set lanaHasRole(rolesToCheck: Roles[]) {
    this.context.lanaHasRole = rolesToCheck || [];

    this.authService.roles$
      .pipe(
        takeUntil(this.destroy),
        tap((roles) => {
          // const hasRoles =
          //   typeof roles === 'string' && roles !== ''
          //     ? this.context.lanaHasRole.includes(roles)
          //     : roles.some(role => this.context.lanaHasRole.includes(role));
          this.context.$implicit = null;
          this.updateView();
        })
      )
      .subscribe();
  }

  @Input()
  set lanaHasRoleThen(templateRef: TemplateRef<LanaHasRoleContext> | null) {
    assertTemplate('lanaHasRoleThen', templateRef);
    this.thenTemplateRef = templateRef;
    this.thenViewRef = null;
    this.updateView();
  }

  @Input()
  set lanaHasRoleElse(templateRef: TemplateRef<LanaHasRoleContext> | null) {
    assertTemplate('lanaHasRoleElse', templateRef);
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null;
    this.updateView();
  }

  private updateView() {
    if (this.context.$implicit) {
      if (!this.thenViewRef) {
        this.viewContainer.clear();
        this.elseViewRef = null;
        if (this.thenTemplateRef) {
          this.thenViewRef = this.viewContainer.createEmbeddedView(
            this.thenTemplateRef,
            this.context
          );
        }
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.thenViewRef = null;
        if (this.elseTemplateRef) {
          this.elseViewRef = this.viewContainer.createEmbeddedView(
            this.elseTemplateRef,
            this.context
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}

export class LanaHasRoleContext {
  public $implicit: Roles[] = null!;
  public lanaHasRole: Roles[] = null!;
}

function assertTemplate(
  property: string,
  templateRef: TemplateRef<any> | null
): void {
  const isTemplateRefOrNull = !!(
    !templateRef || templateRef.createEmbeddedView
  );

  if (!isTemplateRefOrNull) {
    throw new Error(
      `${property} must be a TemplateRef, but received '${stringify(
        templateRef
      )}'.`
    );
  }
}
