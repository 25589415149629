import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Constants } from '../constants';

import { AuthService } from './../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<boolean> {
    const requiredRoles = (next.data?.requiredRoles as string[]) || [];

    return this.authService.roles$.pipe(
      take(1),
      map((roles) =>
        Array.isArray(roles)
          ? roles.some((role) => requiredRoles.includes(role))
          : false
      ),
      tap((canActivate) => {
        if (!canActivate) {
          this.router.navigateByUrl(`/${Constants.LandingPage}`);
        }
      })
    );
  }
}
