<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center">
    <lana-loading></lana-loading>
  </div>
</ng-template>
<ng-container>
  <ejs-grid
    #grid
    id="Grid"
    [dataSource]="data"
    [allowExcelExport]="true"
    (toolbarClick)="toolbarClick($event)"
    [allowPaging]="true"
    [pageSettings]="pageOptions"
    [toolbar]="toolbarOptions"
    (dataStateChange)="pageChanged($event)">
    <e-columns>
      <e-column
        field="assetName"
        headerText="Asset Name"
        [autoFit]="true"></e-column>
      <ng-container *ngFor="let date of allDates">
        <e-column
          [field]="date.summaryDateRaw"
          [headerText]="date.summaryDate"
          [autoFit]="true"></e-column>
      </ng-container>
      <e-column
        field="timeframeTotalEngineHours"
        headerText="Time-frame Engine Hours"
        [autoFit]="true"></e-column>
      <e-column
        field="lifetimeEngineHours"
        headerText="Lifetime Engine Hours"
        [autoFit]="true"></e-column>
    </e-columns>
  </ejs-grid>
</ng-container>
