import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { LayoutComponent } from './components/layout/layout.component';
import { Constants } from './constants';
import { AuthGuard } from './guards/auth.guard';
import { CypressGuard } from './guards/cypress.guard';
import { RoleGuard } from './guards/role.guard';
import { Roles } from './models/auth/role';
import { ProtectedComponent } from './pages/protected/protected.component';

const windowObject: any = window;

const routes: Routes = [
  {
    path: '',
    canActivate: windowObject.Cypress ? [AuthGuard] : [MsalGuard],
    component: LayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: Constants.LandingPage },
      { path: 'map', pathMatch: 'full', redirectTo: Constants.LandingPage },
      {
        path: 'logout',
        loadChildren: () =>
          import('./pages/logout/logout.module').then((m) => m.LogoutModule)
      },
      {
        path: 'management',
        loadChildren: () =>
          import('./pages/management/management.module').then(
            (m) => m.ManagementModule
          )
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'global-settings',
        loadChildren: () =>
          import('./pages/global-settings/global-settings-routing.module').then(
            (m) => m.GlobalSettingsRoutingModule
          )
      },
      {
        path: 'callpass',
        loadChildren: () =>
          import('./pages/platforms/callpass/callpass.module').then(
            (m) => m.CallpassModule
          )
      },
      {
        path: 'lana',
        loadChildren: () =>
          import('./pages/platforms/lana/lana.module').then((m) => m.LanaModule)
      },
      {
        path: 'igotcha',
        loadChildren: () =>
          import('./pages/platforms/igotcha/igotcha.module').then(
            (m) => m.IgotchaModule
          )
      }
    ]
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      )
  },
  {
    path: 'reset-password/:guid',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      )
  },
  {
    path: 'create-password/:guid',
    loadChildren: () =>
      import('./pages/create-password/create-password.module').then(
        (m) => m.CreatePasswordModule
      )
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [CypressGuard]
  },
  {
    path: 'protected',
    component: ProtectedComponent,
    canActivate: [RoleGuard],
    data: { requiredRoles: [Roles.InternalAdministrator] }
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
