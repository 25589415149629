import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';

import { Constants } from './../constants';
import { MapAsset } from './../models/asset/map-asset';
import {
  OdataResponse,
  OdataResponseTransform
} from './../models/odata-response';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MapAssetService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private readonly bounds = new BehaviorSubject<google.maps.LatLngBounds>(null);
  private readonly mapAssets = new BehaviorSubject<MapAsset[]>(null);
  private readonly odataUrl = `${Constants.Odata}/MapAssets`;

  readonly routeResumeOrStop$ = new BehaviorSubject<string>('resume');

  readonly bounds$ = this.bounds.asObservable();
  readonly mapAssets$ = this.mapAssets.pipe(
    filter((mapAssets) => mapAssets !== null)
  );

  reloadBounds(): void {
    this.bounds.next(this.bounds.getValue());
  }

  getMapAssets(
    includeSubCompany = false
  ): Observable<OdataResponseTransform<MapAsset>> {
    return this.authService.companyId$.pipe(
      tap((_) => {
        this.mapAssets.next(null);
        this.bounds.next(null);
      }),
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<MapAsset>>(
          `${
            this.odataUrl
          }?companyContext=${companyId}&$orderby=lastReportTimestamp%20desc${
            includeSubCompany ? '' : '&$filter=companyId eq ' + companyId
          }`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [] as MapAsset[], count: 0 })),
      map((response) => {
        response.result = response.result.map((result) => {
          const isSticky = new BehaviorSubject(false);
          const isHovered = new BehaviorSubject(false);
          const isSelected = new BehaviorSubject(false);
          const isOpen = combineLatest([isSticky, isHovered, isSelected]).pipe(
            map(([sticky, hovered, selected]) => sticky || hovered || selected)
          );

          return { ...result, isSticky, isHovered, isOpen, isSelected };
        });
        return response;
      }),
      tap((response) => {
        this.mapAssets.next(response.result);
        if (response.result.length > 0) {
          const bounds = response.result
            .filter(
              (value) =>
                value.lastLatitude !== null &&
                value.lastLatitude !== 0 &&
                value.lastLongitude !== null &&
                value.lastLongitude !== 0
            )
            .reduce(
              (working, next) =>
                working.extend(
                  new google.maps.LatLng(next.lastLatitude, next.lastLongitude)
                ),
              new google.maps.LatLngBounds()
            );
          setTimeout(() => this.bounds.next(bounds), 0);
        }
      })
    );
  }

  getMapAsset(
    assetId,
    includeSubCompany = false
  ): Observable<OdataResponseTransform<MapAsset>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<MapAsset>>(
          `${
            this.odataUrl
          }?companyContext=${companyId}&$filter=id eq ${assetId}${
            includeSubCompany ? '' : ' and companyId eq ' + companyId
          }`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [] as MapAsset[], count: 0 })),
      map((response) => {
        response.result = response.result.map((result) => {
          const isSticky = new BehaviorSubject(false);
          const isHovered = new BehaviorSubject(false);
          const isSelected = new BehaviorSubject(false);
          const isOpen = combineLatest([isSticky, isHovered, isSelected]).pipe(
            map(([sticky, hovered, selected]) => sticky || hovered || selected)
          );

          return { ...result, isSticky, isHovered, isOpen, isSelected };
        });
        return response;
      })
    );
  }
}
