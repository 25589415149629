<div class="row">
  <div class="col">
    <form [formGroup]="formGroup">
      <div class="form-group">
        <label>Subscription</label>
        <input
          type="text"
          class="form-control"
          placeholder="Subscription"
          formControlName="subscriptionName" />
        <lana-error-display
          [control]="formGroup.get('subscriptionName')"
          label="Subscription Name"></lana-error-display>
      </div>
      <div class="form-group">
        <label>Status</label>
        <input
          type="text"
          class="form-control"
          placeholder="Subscription status"
          formControlName="subscriptionStatus" />
        <lana-error-display
          [control]="formGroup.get('subscriptionStatus')"
          label="Subscription status"></lana-error-display>
      </div>

      <div class="form-group">
        <label>Plan Id</label>
        <input
          type="text"
          class="form-control"
          placeholder="Plan Id"
          formControlName="planId" />
        <lana-error-display
          [control]="formGroup.get('planId')"
          label="Plan Id"></lana-error-display>
      </div>
      <div class="form-group">
        <label>Subscription Plan</label>
        <input
          type="text"
          class="form-control"
          placeholder="Subscription Plan"
          formControlName="subscriptionPlan" />
        <lana-error-display
          [control]="formGroup.get('subscriptionPlan')"
          label="Subscription Plan"></lana-error-display>
      </div>

      <div class="form-group">
        <label>Offer</label>
        <input
          type="text"
          class="form-control"
          placeholder="Offer"
          formControlName="offerId" />
        <lana-error-display
          [control]="formGroup.get('offerId')"
          label="Offer"></lana-error-display>
      </div>

      <div class="form-group">
        <label>Device Count Limit</label>
        <input
          type="text"
          class="form-control"
          placeholder="Device Count Limit"
          formControlName="deviceCountLimit" />
        <lana-error-display
          [control]="formGroup.get('deviceCountLimit')"
          label="deviceCountLimit"></lana-error-display>
      </div>

      <hr />
      <div class="float-right">
        <lana-loading-button
          [loading]="loading | async"
          [noMargin]="true"
          [primary]="true"
          (buttonPressed)="updatSubscription(formGroup)"
          >Update Subscription</lana-loading-button
        >
      </div>
    </form>
  </div>
</div>
