import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { Comment } from './../../../models/asset/comment';
import { AuthService } from 'src/app/services/auth.service';
import { Roles } from 'src/app/models/auth/role';

@Component({
  selector: 'lana-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private ngbModal: NgbModal
  ) {}

  @Input() comments: Observable<Comment[]>;
  @Output() commentAdded = new EventEmitter<{ comment: string }>();
  readonly roles = Roles;

  formGroup: UntypedFormGroup;
  timezone: string;

  addComment(content: any): void {
    from(this.ngbModal.open(content).result).pipe(first()).subscribe();
  }

  confirmAddComment(formGroup: UntypedFormGroup, modal: NgbActiveModal): void {
    if (formGroup.valid) {
      this.commentAdded.emit(formGroup.value);
      modal.close();
    }
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      comment: this.formBuilder.control('', Validators.required)
    });

    this.authService.currentUser$
      .pipe(tap((data) => (this.timezone = data.ianaTimeZone)))
      .subscribe();
  }
}
