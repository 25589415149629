/* tslint:disable:member-ordering */
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[delayedHover]'
})
export class DelayedHoverDirective implements OnInit, OnDestroy {
  constructor(private readonly element: ElementRef) {}

  @Input() defaultColor: string;
  @Input() delay = 200;
  @Input('delayedHover') highlightColor: string;

  @Output('delayedHover') hoverEvent = new EventEmitter();

  ngOnInit() {
    const hide$ = fromEvent(this.element.nativeElement, 'mouseleave').pipe(
      map((_) => false)
    );
    const show$ = fromEvent(this.element.nativeElement, 'mouseenter').pipe(
      map((_) => true)
    );

    merge(hide$, show$)
      .pipe(
        switchMap((show) => {
          if (!show) {
            return of(false);
          }
          return of(true).pipe(delay(this.delay));
        })
      )
      .subscribe((show) => {
        if (show) {
          this.hoverEvent.emit();
        }
      });
  }

  ngOnDestroy() {}
}
