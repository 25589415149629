<form [formGroup]="formGroup">
  <div class="form-group">
    <ejs-dropdownlist
      formControlName="includeAllGeozones"
      name="includeAllGeozones"
      [dataSource]="filterTypes"
      [fields]="fields"
      placeholder="Select Geozone filtering"></ejs-dropdownlist>
    <ng-template #loadingTemplate>
      <lana-loading></lana-loading>
    </ng-template>
    <ng-container *ngIf="!allGeozones">
      <ng-container *ngIf="geozone | async as geozone; else loadingTemplate">
        <label>Geozone Selection</label>
        <ejs-multiselect
          formControlName="geozoneIds"
          name="geozoneIds"
          [dataSource]="geozone"
          [fields]="geozoneFields"
          [placeholder]="'Select geozone(s)'"
          [query]="query"
          sortOrder="Ascending"
          [mode]="mode"
          showSelectAll="true"
          (created)="onCreate($event)"
          #geozoneMultiSelect></ejs-multiselect>
      </ng-container>
    </ng-container>
    <label>Alert Direction</label>
    <ejs-dropdownlist
      formControlName="geozoneDirection"
      name="geozoneDirection"
      [dataSource]="geozoneDirections"
      [fields]="fields"
      placeholder="Select when to get alerted"></ejs-dropdownlist>
  </div>
</form>
