import {
  AssignTracker,
  BulkAssignTrackers,
  ImportTrackersResponse,
  MetaTrackerType,
  OnboardTrackers,
  OnboardTrackersResponse,
  Tracker,
  TrackerTypes,
  UploadTrackers
} from './../models/tracker/tracker';
import {
  BehaviorSubject,
  Observable,
  Subject,
  of,
  pipe,
  throwError
} from 'rxjs';
import {
  HttpClient,
  HttpEventType,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {
  OdataResponse,
  OdataResponseTransform
} from '../models/odata-response';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { CommentList } from './../models/asset/comment';
import { Constants } from './../constants';
import { DateFormatter } from './../formatters/date.formatter';
import { EmptyFormatter } from './../formatters/empty.formatter';
import { FilterSettings } from './../models/filter-settings';
import { Injectable } from '@angular/core';
import { LanaColumnModel } from './../models/column';
import { TemperatureAlertConfiguration } from '../models/tracker/temperature-alert-configuration';
import { TrackerComment } from './../models/tracker/tracker-comment';
import { ZeroFormatter } from './../formatters/zeroFormatter.formatter';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private readonly currentlyViewingTracker = new BehaviorSubject<Tracker>(null);
  private readonly odataUrl = `${Constants.Odata}/Trackers?`;
  private readonly url = `${Constants.Api}/Tracker`;
  private readonly trackerTypesUrl = `${Constants.Api}/MetaTrackerTypes`;

  currentlyViewingTracker$ = this.currentlyViewingTracker.asObservable();

  readonly columns: LanaColumnModel<Tracker>[] = [
    {
      field: 'companySummary.name',
      headerText: 'Company Name',
      generateUrl: (tracker) =>
        tracker.companySummary
          ? `/management/sub-companies/${tracker.companySummary.id}`
          : ''
    },
    {
      field: 'serialNumber',
      headerText: 'Tracker Serial #',
      generateUrl: (tracker) => `/management/trackers/${tracker.id}`
    },
    {
      field: 'currentAsset.name',
      headerText: 'Asset Name',
      generateUrl: (tracker) =>
        tracker.currentAsset
          ? `/management/assets/${tracker.currentAsset.id}`
          : ''
    },
    {
      field: 'lastKnownAssetBatteryVoltage',
      headerText: 'Asset Battery',
      formatter: ZeroFormatter.getValue<Tracker>('lastKnownAssetBatteryVoltage')
    },
    { field: 'trackerTypeName', headerText: 'Tracker Type' },
    { field: 'imei', headerText: 'IMEI' },
    { field: 'simOne.carrier.name', headerText: 'Carrier' },
    {
      field: 'simOne.iccid',
      headerText: 'SIM #',
      formatter: EmptyFormatter.getValue<Tracker>('simOne.iccid')
    },
    {
      field: 'trackerBatteryVoltage',
      headerText: 'Tracker Battery',
      formatter: EmptyFormatter.getValue<Tracker>('trackerBatteryVoltage')
    },
    {
      field: 'lastKnownFullAddress',
      headerText: 'Address',
      generateLocation: (tracker) => tracker,
      autoFit: true
    },
    {
      field: 'lastReportTimestamp',
      headerText: 'Last Report',
      formatter: DateFormatter.getValue<Tracker>(
        'lastReportTimestamp',
        false,
        this.authService.currentUser$
      ),
      autoFit: true
    }
  ];

  readonly filterSettings: FilterSettings = {
    columns: [
      {
        field: 'serialNumber',
        headerText: 'Tracker Serial #',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'currentAsset.name',
        headerText: 'Asset Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'imei',
        headerText: 'IMEI',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'simOne.iccid',
        headerText: 'SIM #',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  readonly trackerAssignmentColumns: LanaColumnModel<Tracker>[] = [
    { field: 'companySummary.name', headerText: 'Company Name' },
    { field: 'serialNumber', headerText: 'Tracker Serial Number' },
    {
      field: 'lastReportTimestamp',
      headerText: 'Last Report Timestamp',
      formatter: DateFormatter.getValue<Tracker>(
        'lastReportTimestamp',
        false,
        this.authService.currentUser$
      )
    }
  ];

  readonly trackerAssignmentFilterSettings: FilterSettings = {
    columns: [
      {
        field: 'serialNumber',
        headerText: 'Tracker Serial Number',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  getTrackers({
    filterQuery,
    pageQuery,
    sortQuery
  }): Observable<OdataResponseTransform<Tracker>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<Tracker>>(
          `${
            this.odataUrl
          }$expand=CurrentAsset,SimOne,SimOne($expand=Carrier),CompanySummary&companyContext=${companyId}&${pageQuery}${
            filterQuery ? `${filterQuery}` : ''
          }${sortQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getExportTrackers({
    filterQuery
  }): Observable<OdataResponseTransform<Tracker>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<Tracker>>(
          `${this.odataUrl}$expand=CurrentAsset,SimOne,SimOne($expand=Carrier),CompanySummary&companyContext=${companyId}${filterQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getSubCompanyTrackers(
    companyId
  ): Observable<OdataResponseTransform<Tracker>> {
    return this.httpClient
      .get<OdataResponse<Tracker>>(
        `${this.odataUrl}companyContext=${companyId}&$top=0&$filter=CompanySummary/Id eq ${companyId}&$count=true`
      )
      .pipe(
        map((response) => ({
          result: response.value,
          count: response['@odata.count']
        })),
        catchError((_) => of({ result: [], count: 0 }))
      );
  }

  /*
  getSubCompanyTrackerTypes(): Observable<any> {
    return this.authService.companyId$.pipe(
      switchMap(companyId =>
        this.getTrackers({ filterQuery: `&$filter=CompanySummary/Id eq ${companyId}`, pageQuery: '', sortQuery: '' })
          .pipe(
            map(response => response.result),
            map(response => response.map(e => {
              return { id: e.trackerTypeId, name: e.trackerTypeName };
            })),
          )
      ));
  }
  */

  getTracker(trackerId: number): Observable<Tracker> {
    return trackerId
      ? this.httpClient
          .get<Tracker>(`${this.url}/${trackerId}`)
          .pipe(tap((response) => this.currentlyViewingTracker.next(response)))
      : of(null).pipe(tap((_) => this.currentlyViewingTracker.next(null)));
  }

  getTrackerTypes(): Observable<MetaTrackerType[]> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<MetaTrackerType[]>(
          `${this.trackerTypesUrl}?companyContext=${companyId}`
        )
      )
    );
  }

  getTrackerLocate(trackerId: number): Observable<Tracker> {
    return this.httpClient.get<Tracker>(`${this.url}/${trackerId}/locate`);
  }

  getComments(trackerId: number): Observable<CommentList> {
    const url = `${this.url}/${trackerId}/Comments`;

    return this.httpClient.get<CommentList>(url).pipe(
      map((response) => {
        response.comments = response.comments.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
        return response;
      })
    );
  }

  createComment(model: TrackerComment): Observable<CommentList> {
    const url = `${this.url}/Comment`;

    return this.httpClient.post<CommentList>(url, model);
  }

  uploadTrackers(
    uploadTrackers: UploadTrackers,
    file: File,
    carrierId: string
  ): Observable<{ progress: number; response: ImportTrackersResponse }> {
    const progress = new Subject<{
      progress: number;
      response: ImportTrackersResponse;
    }>();
    const formData = new FormData();

    formData.append('file', file, file.name);

    const request = new HttpRequest(
      'POST',
      `${this.url}/Import/${uploadTrackers.companyId}/${carrierId}/${
        uploadTrackers.createAssetMode ? '1' : '0'
      }`,
      formData,
      { reportProgress: true }
    );

    this.httpClient
      .request(request)
      .pipe(
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            progress.next({ progress: percentDone, response: null });
          } else if (event instanceof HttpResponse) {
            progress.next({
              progress: 100,
              response: event.body as ImportTrackersResponse
            });
            progress.complete();
          }
        })
      )
      .subscribe();

    return progress.asObservable();
  }

  onboardTrackers(
    companyId: number,
    serialNumbers: string[]
  ): Observable<OnboardTrackersResponse> {
    return this.httpClient.post<OnboardTrackersResponse>(
      `${this.url}/move-to-company`,
      {
        companyId,
        serialNumbers
      }
    );
  }

  assignToCompany(model: AssignTracker): Observable<Tracker> {
    return this.httpClient.put<Tracker>(`${this.url}/assign-to-company`, model);
  }

  bulkAssignToCompany(model: BulkAssignTrackers): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/bulk-assign-to-company`,
      model
    );
  }

  getAssignableTrackers(
    currentlyViewingCompanyId: number,
    { filterQuery, pageQuery, sortQuery }
  ): Observable<OdataResponseTransform<Tracker>> {
    const filterQueryWithCompanyId = filterQuery
      ? `${filterQuery} and CurrentAsset/Name eq null`
      : `&$filter=CurrentAsset/Name eq null`;

    return this.httpClient
      .get<OdataResponse<Tracker>>(
        `${this.odataUrl}$expand=CurrentAsset,CompanySummary&companyContext=${currentlyViewingCompanyId}&${pageQuery}${filterQueryWithCompanyId} and CompanySummary/Id eq ${currentlyViewingCompanyId}${sortQuery}&$count=true`
      )
      .pipe(
        map((response) => ({
          result: response.value,
          count: response['@odata.count']
        })),
        catchError((_) => of({ result: [], count: 0 }))
      );
  }

  getReclaimableTrackers(
    currentlyViewingCompanyId: number,
    { filterQuery, pageQuery, sortQuery }
  ): Observable<OdataResponseTransform<Tracker>> {
    const filterQueryWithCompanyId = filterQuery
      ? `${filterQuery} and CurrentAsset/Name eq null and CompanySummary/Id ne ${currentlyViewingCompanyId}`
      : `&$filter=CurrentAsset/Name eq null and CompanySummary/Id ne ${currentlyViewingCompanyId}`;

    return this.httpClient
      .get<OdataResponse<Tracker>>(
        `${this.odataUrl}$expand=CurrentAsset,CompanySummary&companyContext=${currentlyViewingCompanyId}&${pageQuery}${filterQueryWithCompanyId}${sortQuery}&$count=true`
      )
      .pipe(
        map((response) => ({
          result: response.value,
          count: response['@odata.count']
        })),
        catchError((_) => of({ result: [], count: 0 }))
      );
  }
  getTrackerSensorThresholds(
    trackerId: number
  ): Observable<TemperatureAlertConfiguration> {
    return this.httpClient.get<TemperatureAlertConfiguration>(
      `${this.url}/${trackerId}/temperature-alert-configuration`
    );
  }
  updateTrackerSensorThresholds(
    thresholdsPayload: TemperatureAlertConfiguration
  ) {
    return this.httpClient.put<TemperatureAlertConfiguration>(
      `${this.url}/temperature-alert-configuration`,
      thresholdsPayload
    );
  }
  updateIgnitionStatus(
    trackerId: number,
    status: boolean
  ): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/${trackerId}/ignition/${status}`
    );
  }

  removeTracker(id: number): Observable<void> {
    return this.httpClient.request<void>('delete', this.url, { body: { id } });
  }
}
