import { Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { MapAsset } from './../../models/asset/map-asset';
import { VisionModalComponent } from './../../components/vision-modal/vision-modal.component';
import { ThemeService } from '@app/services/theme.service';
import { AdministrationService } from '@app/services/administration.service';

@Component({
  selector: 'lana-map-asset-info-window',
  templateUrl: './map-asset-info-window.component.html',
  styleUrls: ['./map-asset-info-window.component.scss']
})
export class MapAssetInfoWindowComponent {
  @Input() asset: MapAsset;
  @Input() imageUrl: Observable<string>;
  @Input() timeZone: string;
  @ViewChild(VisionModalComponent) visionModal: VisionModalComponent;
  theme: Observable<string>;
  showImage = true;

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.currentCustomer$;
  }

  updateUrl(e) {
    this.showImage = false;
  }

  openModal() {
    const asset = this.asset;
    const vision = {
      name: asset.name,
      timestamp: asset.lastReportTimestamp,
      trackerBatteryVoltage: asset?.lastTrackerBatteryVoltage,
      trackerSerialNumber: asset?.trackerSerialNumber,
      fullAddress: asset?.lastFullAddress,
      latitude: asset?.lastLatitude,
      longitude: asset?.lastLongitude,
      eventCodeDescription: asset?.lastEventCodeDescription,
      occupancySensorVolumePercentFull:
        asset?.lastOccupancySensorVolumePercentFull,
      occupancySensorIsDoorOpen: asset?.lastOccupancySensorIsDoorOpen,
      image: asset?.lastImageId,
      temperature: asset?.lastVisionTemperature
    };
    this.visionModal.openModal(vision, this.timeZone);
  }
}
