import { Column } from '@syncfusion/ej2-grids';

export class AlertStateFormatter {
  static getValue<T>(prop: string) {
    return (_: Column, model: T) => {
      let badge = 'bg-primary';

      switch (model[prop]) {
        case 'Active':
          badge = 'bg-danger';
          break;
        case 'Acknowledged':
          badge = 'bg-secondary';
          break;
        case 'Resolved':
          badge = 'bg-success';
          break;
        case 'Paused':
          badge = 'bg-info';
          break;
        default:
          badge = 'bg-primary';
      }
      return `<span class="badge rounded-pill text-light ${badge}">${model[prop]}</span>`;
    };
  }
}
