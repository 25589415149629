import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Constants } from './../constants';
import { LanaColumnModel } from './../models/column';
import { FilterSettings } from './../models/filter-settings';
import { CloudGeozone } from './../models/geozone/cloud-geozone';
import { Configuration } from './../models/geozone/geozone';
import { MapCloudGeozone } from './../models/geozone/map-cloud-geozone';
import {
  OdataResponse,
  OdataResponseTransform
} from './../models/odata-response';
import { AuthService } from './auth.service';
import { MapCloudGeozoneService } from './map-cloud-geozone.service';

@Injectable({
  providedIn: 'root'
})
export class GeozoneService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private mapCloudGeozoneService: MapCloudGeozoneService
  ) {}

  private readonly odataUrl = `${Constants.Odata}/CloudGeozones?$expand=CompanySummary`;
  private readonly url = `${Constants.Api}/CloudGeozone`;
  private readonly mapCloudUrl = `${Constants.Api}/MapCloudGeozones`;
  private readonly exportData = new BehaviorSubject(null);

  readonly columns: LanaColumnModel<CloudGeozone>[] = [
    {
      field: 'companySummary.name',
      headerText: 'Company Name',
      generateUrl: (geozone) => `/management/sub-companies/${geozone.id}`
    },
    {
      field: 'name',
      headerText: 'Geozone Name',
      generateUrl: (geozone) => `/management/geozones/${geozone.id}`
    },
    { field: 'geozoneShape', headerText: 'Geozone Shape' },
    { field: 'geozoneGroupName', headerText: 'Geozone Group' },
    { field: 'geozoneTypeName', headerText: 'Geozone Type' },
    { field: 'fullAddress', headerText: 'Address' }
  ];

  readonly filterSettings: FilterSettings = {
    columns: [
      {
        field: 'companySummary.name',
        headerText: 'Company Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'name',
        headerText: 'Group Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'geozoneShape',
        headerText: 'Geozone Shape',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  getGeozones({
    filterQuery,
    pageQuery,
    sortQuery
  }): Observable<OdataResponseTransform<CloudGeozone>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<CloudGeozone>>(
          `${this.odataUrl}&companyContext=${companyId}&${pageQuery}${filterQuery}${sortQuery}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getGeozonesList() {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<CloudGeozone>>(
          `${this.odataUrl}&companyContext=${companyId}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getExportGeozones(): Observable<OdataResponseTransform<CloudGeozone>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<CloudGeozone>>(
          `${this.odataUrl}&companyContext=${companyId}&$count=true`
        )
      ),
      map((response) => ({
        result: response.value,
        count: response['@odata.count']
      })),
      catchError((_) => of({ result: [], count: 0 })),
      tap((response) => {
        if (response.result.length > 0) {
          const bounds = response.result;
          this.exportData.next(bounds);
        }
      })
    );
  }

  getGeozone(geozoneId: number): Observable<CloudGeozone> {
    return this.httpClient.get<CloudGeozone>(`${this.url}/${geozoneId}`);
  }

  getGeozoneAssetIds(geozoneId: number): Observable<number[]> {
    return this.httpClient.get<number[]>(`${this.url}/${geozoneId}/assets`);
  }

  getMapCloudGeozone(geozoneId: number) {
    return this.httpClient.get<CloudGeozone>(
      `${this.mapCloudUrl}/${geozoneId}`
    );
  }

  updateGeozoneConfiguration(model: Configuration): Observable<CloudGeozone> {
    return this.httpClient.put<CloudGeozone>(
      `${this.url}/configuration`,
      model
    );
  }

  createGeozone(model: CloudGeozone): Observable<MapCloudGeozone> {
    return this.httpClient
      .post<CloudGeozone>(this.url, model)
      .pipe(
        switchMap(({ id }) =>
          this.mapCloudGeozoneService.getMapCloudGeozoneById(id)
        )
      );
  }

  updateGeozone(model: CloudGeozone): Observable<MapCloudGeozone> {
    return this.httpClient
      .put<CloudGeozone>(this.url, model)
      .pipe(
        switchMap(({ id }) =>
          this.mapCloudGeozoneService.getMapCloudGeozoneById(id)
        )
      );
  }

  deleteGeozone(id: number): Observable<void> {
    return this.httpClient.request<void>('delete', `${this.url}/${id}`, {});
  }
}
