import { Injectable } from '@angular/core';
import { Predicate } from '@syncfusion/ej2-data';
import {
  DataStateChangeEventArgs,
  PredicateModel,
  Sorts
} from '@syncfusion/ej2-grids';

@Injectable({
  providedIn: 'root'
})
export class ODataHelperService {
  calcPageQuery(state: DataStateChangeEventArgs): string {
    return `$skip=${state.skip}&$top=${state.take}`;
  }

  calcSortQuery(state: DataStateChangeEventArgs): string {
    return (state.sorted || []).length
      ? `&$orderby=` +
          state.sorted
            .map((obj: Sorts) =>
              obj.direction === 'descending'
                ? `${obj.name.replace(/\./g, '/')} desc`
                : obj.name.replace(/\./g, '/')
            )
            .reverse()
            .join(',')
      : '';
  }

  calcFilterQuery(filter: Predicate[], includeSubCompany = true): string {
    return filter && filter.length > 0
      ? `&$filter=(` +
          filter.map((obj: Predicate) => {
            return (obj as any).predicate
              .map((predicate) => {
                const encodedValue = predicate?.value
                  ? encodeURIComponent(predicate?.value.toString())
                  : null;

                return predicate.operator === 'equal'
                  ? `${predicate.field.replace('.', '/')} eq ${encodedValue}`
                  : predicate.field.includes('[].')
                  ? `${predicate.field.split('[].')[0]}/any(x:${
                      predicate.operator
                    }(tolower(x/${
                      predicate.field.split('[].')[1]
                    }),'${encodedValue}'))`
                  : `${predicate.operator}(tolower(${predicate.field.replace(
                      /\./g,
                      '/'
                    )}),'${encodedValue}')`;
              })
              .reverse()
              .join(' or ');
          }) +
          ')'
      : '';
  }
}
