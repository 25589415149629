<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center">
    <lana-loading></lana-loading>
  </div>
</ng-template>

<ng-container *ngIf="fullData$ | async as fullData; else loadingTemplate">
  <ng-template #mainGrid></ng-template>
  <ng-container *ngIf="includeSubCompanyText && showSubCompanyCheckbox">
    <div class="d-flex justify-content-end">
      <div class="form-group form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="includeSubCompany"
          [formControl]="includeSubCompanyFormControl" />
        <label class="form-check-label" for="includeSubCompany"
          >Include sub-company {{ includeSubCompanyText }} in result set</label
        >
      </div>
    </div>
  </ng-container>
  <div class="shadow-sm">
    <ejs-grid
      #grid
      [allowPaging]="true"
      [allowSelection]="isSelectable || isModal"
      [allowGrouping]="allowGrouping"
      [groupSettings]="groupSettings"
      [toolbar]="toolbar"
      [allowExcelExport]="true"
      [allowSorting]="true"
      [enablePersistence]="true"
      [showColumnChooser]="true"
      [class]="filterSettings.columns.length === 0 ? 'no-search' : ''"
      [dataSource]="fullData.data"
      [filterSettings]="filterSettings"
      [pageSettings]="pageOptions"
      [className]="customClass"
      [selectionSettings]="selectionOptions"
      [sortSettings]="sortSettings"
      [childGrid]="childGrid"
      [enableHover]="false"
      [allowResizing]="allowResizing"
      (toolbarClick)="toolbarClick($event)"
      (excelExportComplete)="excelExportComplete()"
      (dataStateChange)="dataStateChange($event, includeSubCompanyFormControl)"
      allowReordering="true"
      (rowSelecting)="rowSelecting($event)"
      (headerCellInfo)="headerCellInfo($event)"
      (rowDeselecting)="rowSelected.emit(null)"
      (load)="load($event)"
      (queryCellInfo)="tooltip($event)">
      <e-columns>
        <e-column *ngIf="isSelectable" type="checkbox" width="60"></e-column>
        <ng-container *ngFor="let column of columns">
          <ng-template #defaultColumnTemplate>
            <e-column
              [field]="column.field"
              [headerText]="column.headerText"
              [visible]="column.visible"
              [allowSorting]="column.allowSorting"
              [autoFit]="column.autoFit"
              clipMode="EllipsisWithTooltip">
              <ng-template #template let-data>
                <ng-template #defaultTemplate>
                  {{
                    fullData.data | columnParser : fullData.data.column.field
                  }}
                </ng-template>
                <ng-container
                  *ngIf="
                    !!column.generateUrl ||
                      !!column.generateLocation ||
                      !!column.generateStatus;
                    else defaultTemplate
                  ">
                  <ng-container *ngIf="!!column.generateUrl">
                    <ng-container
                      *ngIf="column.generateUrl(fullData.data) as url">
                      <ng-template #defaultUrlTemplate>
                        <a [routerLink]="url">{{
                          fullData.data
                            | columnParser : fullData.data.column.field
                        }}</a>
                      </ng-template>
                      <ng-container
                        *ngIf="
                          fullData.data.column.field ===
                            companySummaryNameField;
                          else defaultUrlTemplate
                        ">
                        <lana-company-summary
                          [isEntity]="displayEntityBreadcrumb"
                          [summary]="
                            fullData.data | columnParser : companySummaryField
                          "
                          [url]="url"></lana-company-summary>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!!column.generateLocation">
                    <ng-template #defaultLocationTemplate>
                      <ng-container
                        *ngIf="
                          column.generateLocation(fullData.data) as location
                        ">
                        <ng-template #noLocation
                          ><span style="color: #a9a9a9">N/A</span></ng-template
                        >
                        <ng-container *ngIf="location; else noLocation">
                          <a
                            [routerLink]=""
                            (click)="locationModal(fullData.data)"
                            >{{ location }}</a
                          >
                        </ng-container>
                      </ng-container>
                    </ng-template>
                    <ng-container
                      *ngIf="
                        column.field === 'fullAddress' ||
                          column.field === 'lastKnownFullAddress';
                        else defaultLocationTemplate
                      ">
                      <ng-container
                        *ngIf="
                          column.generateLocation(fullData.data) as location
                        ">
                        <ng-template #noLocation
                          ><span style="color: #a9a9a9">N/A</span></ng-template
                        >
                        <ng-container
                          *ngIf="location[column.field]; else noLocation">
                          <a
                            [routerLink]=""
                            (click)="locationModal(fullData.data)"
                            >{{ location[column.field] }}</a
                          >
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!!column.generateStatus">
                    <ng-container
                      *ngIf="column.generateStatus(fullData.data) as status">
                      <ng-template #defaultStatus>{{
                        status.userStatusName
                      }}</ng-template>
                      <ng-container
                        *lanaHasRole="column.roles; else defaultStatus">
                        <ng-container
                          *ngIf="
                            status.userStatusId === 3 ||
                              status.userStatusId === 4;
                            else defaultStatus
                          ">
                          <a
                            class="mr-2"
                            [routerLink]=""
                            (click)="statusModal(fullData.data, 'resend')"
                            >Re-send Invite</a
                          >
                          <a
                            [routerLink]=""
                            (click)="statusModal(fullData.data, 'cancel')"
                            >Cancel Invite</a
                          >
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>
            </e-column>
          </ng-template>
          <ng-template #defaultDynamicTemplate>
            <ng-template #defaultVisible>
              <ng-template #defaultColumnFormatterTemplate>
                <ng-container
                  *ngIf="column.formatter; else defaultColumnTemplate">
                  <ng-template #defaultColumnFormatter>
                    <e-column
                      [field]="column.field"
                      [headerText]="column.headerText"
                      [formatter]="column.formatter"
                      [disableHtmlEncode]="false"
                      [allowSorting]="column.allowSorting"
                      [autoFit]="column.autoFit"
                      clipMode="EllipsisWithTooltip"></e-column>
                  </ng-template>
                  <ng-container
                    *ngIf="
                      column.field === 'lanaVisionEvent.imageId';
                      else defaultColumnFormatter
                    ">
                    <e-column
                      [field]="column.field"
                      [headerText]="column.headerText"
                      width="100">
                      <ng-template #template let-data>
                        <lana-image-data
                          [field]="column.field"
                          [data]="fullData.data"></lana-image-data>
                      </ng-template>
                    </e-column>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-container
                *ngIf="column.format; else defaultColumnFormatterTemplate">
                <e-column
                  [field]="column.field"
                  [headerText]="column.headerText"
                  [format]="column.format"
                  [disableHtmlEncode]="false"
                  [allowSorting]="column.allowSorting"
                  [autoFit]="column.autoFit"
                  clipMode="EllipsisWithTooltip"></e-column>
              </ng-container>
            </ng-template>

            <ng-container
              *ngIf="column.generateVisibility; else defaultVisible">
              <ng-container
                *ngIf="column.generateVisibility(fullData.data) as history">
                <e-column
                  [field]="column.field"
                  [headerText]="column.headerText"
                  [visible]="checkVisibility(fullData.data)"
                  [disableHtmlEncode]="false"
                  [allowSorting]="column.allowSorting"
                  [autoFit]="column.autoFit"
                  clipMode="EllipsisWithTooltip"></e-column>
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="column?.dynamic; else defaultDynamicTemplate">
            <ng-container *ngIf="column?.field && column?.headerText">
              <e-column
                [field]="column.field"
                [headerText]="column.headerText"
                [autoFit]="true"
                [visible]="column?.visible"></e-column>
            </ng-container>
          </ng-container>
        </ng-container>
      </e-columns>
    </ejs-grid>
  </div>
  <ng-container *ngIf="loading | async; else mainGrid">
    <div class="d-flex justify-content-center">
      <lana-loading [fullscreen]="true"></lana-loading>
    </div>
  </ng-container>
</ng-container>
