import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'lana-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.scss']
})
export class ProtectedComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) {}

  requiredRoles: Observable<string>;

  ngOnInit(): void {
    this.requiredRoles = this.activatedRoute.data.pipe(
      map((data) => data?.requiredRoles || ['None'])
    );
  }
}
