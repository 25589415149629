import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MapCloudGeozone } from './../../models/geozone/map-cloud-geozone';

@Component({
  selector: 'lana-cloud-based-geozone-list-detail-item',
  templateUrl: './cloud-based-geozone-list-detail-item.component.html',
  styleUrls: ['./cloud-based-geozone-list-detail-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloudBasedGeozoneListDetailItemComponent {
  @Input() geozone: MapCloudGeozone;
  @Input() selected: boolean = false;
  @Output() showGeozoneOnMap = new EventEmitter<MapCloudGeozone>();
  @Output() hideGeozoneOnMap = new EventEmitter<MapCloudGeozone>();
}
