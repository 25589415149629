<div class="card" @fadeSlideDown>
  <div class="card-header step-header">Assets Summary</div>
  <div class="card-body step">
    <ul class="list-group list-group-flush">
      <ng-container *ngFor="let asset of assets">
        <li class="list-group-item">
          <!-- {{ asset.type | titlecase }}:  -->
          <!-- <span class="badge badge-dark" *ngIf="asset.type !== 'all'">{{ asset.type }}</span> -->
          {{ asset.name }}
          <i class="far fa-times-circle" (click)="removeAsset(asset.id)"></i>
          <p class="card-text" *ngIf="asset.type !== 'all'">
            <small class="text-muted">{{ asset.type | titlecase }}</small>
          </p>
        </li>
      </ng-container>
    </ul>
  </div>
  <!-- <div class="card-footer text-muted">
    Asset Count: <strong>{{ count }}</strong>
  </div> -->
</div>
