import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyBreadcrumb'
})
export class CompanyBreadcrumbPipe implements PipeTransform {
  transform(value: string): string {
    return (value || '').replace(/>>>/g, ' > ');
  }
}
