import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Constants } from './../constants';
import { CreateMetaAssetType, MetaAssetType } from './../models/asset/asset';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AssetTypeService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private odataUrl = `${Constants.Odata}/MetaAssetTypes`;
  private url = `${Constants.Api}/MetaAssetType`;

  getAssetTypes(): Observable<MetaAssetType[]> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<MetaAssetType>(
          `${this.odataUrl}?companyContext=${companyId}&$filter=companyId eq ${companyId}`
        )
      ),
      map((response) => response['value']),
      catchError((_) => of({ value: [] }))
    );
  }

  createAssetModel(model: CreateMetaAssetType): Observable<MetaAssetType> {
    return this.httpClient.post<MetaAssetType>(this.url, model);
  }
}
