/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {
  LogLevel,
  Configuration,
  BrowserCacheLocation
} from '@azure/msal-browser';
import { ConfigsLoaderService } from './../app/services/configs-loader.service';

export class AuthConfig {
  constructor(private configService: ConfigsLoaderService) {}

  private readonly isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

  getMsalConfig(): Configuration {
    const azureAuth = this.configService.configs.azureAuthentication;

    return {
      auth: {
        clientId: this.configService.configs.azureAuthentication.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${azureAuth.tenantId}`, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: azureAuth.redirectUris[window.location.hostname], // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: this.isIE // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel: LogLevel, message: string) {
            // console.log(message);
          },
          logLevel: LogLevel.Verbose,
          piiLoggingEnabled: false
        }
      }
    };
  }
}
