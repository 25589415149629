<lana-company-selector
  (companySelected)="setCompanySelected($event)"
  [internalCompany]="internalCompany"
  [closed]="false"></lana-company-selector>
<ng-template #defaultCompanyTemplate>
  <select
    class="form-control"
    [formControl]="control"
    (click)="openCompanySelectorModal()">
    <option disabled [ngValue]="null">Select Company</option>
  </select>
</ng-template>
<ng-container *ngIf="!!selectedCompany; else defaultCompanyTemplate">
  <select
    class="form-control"
    [formControl]="control"
    (click)="openCompanySelectorModal()">
    <option [ngValue]="selectedCompany.id">{{ selectedCompany.name }}</option>
  </select>
</ng-container>
