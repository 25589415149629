<header class="navbar navbar-expand flex-column flex-md-row p-0 shadow-sm">
  <a routerLink="/" class="navbar-brand pl-4 pr-1">
    <ng-template #defaultLogo>
      <img class="logo" src="/assets/cp-logo.png" />
    </ng-template>
    <ng-container *ngIf="theme | async as theme; else defaultLogo">
      <img class="logo" [src]="'/assets/logos/' + theme + '/cp-logo.png'" />
    </ng-container>
  </a>
  <ng-container *ngIf="currentUser | async as currentUser">
    <ul class="navbar-nav flex-row mr-auto top-navbar">
      <i-bs
        name="list"
        width="2rem"
        height="2rem"
        (click)="toggleMenu()"
        style="cursor: pointer"></i-bs>
      <ng-container *ngIf="topMenu | async as topMenu">
        <li
          *ngFor="let menuItem of topMenu.menuItems"
          class="nav-item"
          [class.active]="isRouteActive(menuItem.url, true)">
          <a
            [routerLink]="menuItem.url"
            class="nav-link font-weight-bold pl-3 pr-3"
            >{{ menuItem.name }}</a
          >
        </li>
      </ng-container>
    </ul>

    <div class="d-flex dropdown justify-content-center align-items-center">
      <div
        class="d-flex flex-column justify-content-center align-items-end mr-3 small">
        <p class="user-name font-weight-bold mb-0">
          <i class="menu-arrow fas fa-fw fa-sort-down mr-1"></i>
          {{ currentUser.name }}
        </p>
        <span class="company-name">{{
          currentUser.companyName ?? 'companyName'
        }}</span>
      </div>

      <ng-container *ngIf="userMenu | async as userMenu">
        <div class="dropdown-menu dropdown-menu-main right shadow-sm mr-2">
          <ng-container *ngFor="let menuItem of userMenu.menuItems">
            <ng-template #defaultMenuItemTemplate>
              <a
                routerLinkActive="active"
                [routerLink]="menuItem.url"
                class="dropdown-item"
                >{{ menuItem.name }}</a
              >
            </ng-template>
            <ng-container
              *ngIf="
                menuItems.SwitchCompany === menuItem.id ||
                  menuItems.ContactUs === menuItem.id ||
                  menuItems.Administration === menuItem.id;
                else defaultMenuItemTemplate
              ">
              <div
                (click)="openCompanySelector()"
                *ngIf="menuItems.SwitchCompany === menuItem.id"
                class="dropdown-item">
                {{ menuItem.name }}
              </div>
              <div
                (click)="openContactUs()"
                *ngIf="menuItems.ContactUs === menuItem.id"
                class="dropdown-item">
                {{ menuItem.name }}
              </div>
              <ng-container *lanaHasRole="[roles.Administrator]">
                <a
                  *ngIf="menuItems.Administration === menuItem.id"
                  routerLinkActive="active"
                  [routerLink]="menuItem.url"
                  class="dropdown-item"
                  >{{ menuItem.name }}</a
                >
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</header>

<div class="d-flex align-items-stretch" style="min-height: 100%">
  <ng-container *ngIf="showMenu">
    <div class="flex-shrink-1" @fadeSlideInOut>
      <div class="d-flex flex-column flex-shrink-0 p-3 sidebar-container">
        <ul class="navbar-nav nav-pills flex-column mb-auto">
          <ng-template #loadingTemplate>
            <lana-loading></lana-loading>
          </ng-template>
          <ng-container
            *ngIf="mainMenu | async as mainMenu; else loadingTemplate">
            <ng-container *ngFor="let menuItem of mainMenu.menuItems">
              <ng-template #defaultChildMenu>
                <li class="nav-item menu-list-item">
                  <a
                    (click)="goToSidebarPage(menuItem.url)"
                    class="nav-link"
                    aria-current="page"
                    [class.active]="isRouteActive(menuItem.url, true)">
                    <i-bs [name]="menuItem.icon"></i-bs>
                    {{ menuItem.name }}
                  </a>
                </li>
              </ng-template>
              <ng-container
                *ngIf="
                  menuItem.childMenuItems.length > 0;
                  else defaultChildMenu
                ">
                <ngb-accordion
                  #acc="ngbAccordion"
                  [class.menu-list-item]="true">
                  <ngb-panel>
                    <ng-template ngbPanelHeader let-opened="opened">
                      <div
                        class="accordion-button custom-header justify-content-between"
                        [class.collapsed]="!opened">
                        <div class="justify-content-between">
                          <button ngbPanelToggle class="btn btn-link p-0">
                            <i-bs [name]="menuItem.icon"></i-bs>
                            {{ menuItem.name }}
                          </button>
                          <i
                            class="fas arrow-angle"
                            [class.fa-angle-right]="!opened"
                            [class.fa-angle-down]="opened"></i>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <ul class="list-group">
                        <ng-container
                          *ngFor="let childMenuItem of menuItem.childMenuItems">
                          <li class="list-group-item">
                            <a
                              [routerLink]="childMenuItem.url"
                              class="dropdown-item"
                              routerLinkActive="active">
                              <i-bs [name]="childMenuItem.icon"></i-bs>
                              {{ childMenuItem.name }}
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </ng-container>
            </ng-container>
            <!-- Logos -->
            <ng-container *ngIf="mainMenu.id === 1">
              <img class="bottom-logo" [src]="'/assets/header-logo.png'" />
            </ng-container>
            <ng-container *ngIf="mainMenu.id === 2">
              <img class="bottom-logo" [src]="'/assets/igotcha-logo.png'" />
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-container>
  <div class="flex-grow-1 main-content">
    <lana-company-selector
      (companySelected)="selectCompany($event.id)"
      [closed]="true"></lana-company-selector>
    <lana-contact-us></lana-contact-us>
    <lana-viewport></lana-viewport>
    <section>
      <router-outlet></router-outlet>
    </section>
  </div>
</div>
