import { CompanySummary } from './../company/company-summary';

export interface SubscriptionInformation {
  subscriptionId: string;
  subscriptionName: string;
  subscriptionStatus: number;
  planId: string;
  subscriptionPlan: string | null;
  offerId: string;
  deviceCountLimit: number;
}

export interface User {
  id: string;
  companySummary: CompanySummary;
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  userStatusName: string;
  userStatusId: number;
  adUserRoles: AdUserRole[];
  emailAddresses: EmailAddress[];
  phoneNumbers: PhoneNumber[];
  subscriptionInformation?: SubscriptionInformation;
}

export interface ExportUser {
  id: string;
  companySummary: CompanySummary;
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  userStatusName: string;
  userStatusId: number;
  adUserRoles: string;
  emailAddresses: EmailAddress[];
  phoneNumbers: PhoneNumber[];
  subscriptionInformation?: SubscriptionInformation;
}

export interface AdUserRole {
  id: number;
  adUserId: string;
  roleId: number;
  roleName: string;
}

export interface EmailAddress {
  id: number;
  adUserId: string;
  address: string;
  notificationsEnabled: boolean;
}

export interface CreateEmailAddress {
  adUserId: string;
  address: string;
  notificationsEnabled: boolean;
}

export interface UpdateEmailAddress extends CreateEmailAddress {
  id: number;
}

export interface PhoneNumber {
  id: number;
  adUserId: string;
  phoneNumberType: any;
  number: string;
  extension: string;
  notificationsEnabled: boolean;
}

export interface CreatePhoneNumber {
  adUserId: string;
  number: string;
  phoneNumberTypeId: number;
  notificationsEnabled: boolean;
}

export interface UpdatePhoneNumber extends CreatePhoneNumber {
  id: number;
}
