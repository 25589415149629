<ng-template #loadingTemplate>
  <lana-loading></lana-loading>
</ng-template>
<ng-container *ngIf="image | async as image; else loadingTemplate">
  <ng-template #defaultImage>
    <div class="d-flex justify-content-center">
      <img (click)="openModal(content)" [src]="image" />
    </div>
  </ng-template>
  <ng-container
    *ngIf="image === 'N/A' || image === 'Unknown'; else defaultImage">
    <span style="color: #a9a9a9">{{ image }}</span>
  </ng-container>
</ng-container>

<ng-template #content let-modal>
  <div class="reports-modal-map">
    <div class="modal-header">
      <h5 class="modal-title">
        <img
          class="header-asset-type"
          src="/assets/asset-types/vision.svg" /><span
          >Asset: {{ data.assetName }}</span
        >
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-template #loadingModalTemplate>
        <lana-loading></lana-loading>
      </ng-template>
      <ng-container *ngIf="image | async as image; else loadingModalTemplate">
        <div class="d-flex align-items-stretch">
          <div class="flex-shrink-1 info">
            <div class="card shadow p-0 rounded-0 position">
              <div class="card-body p-2">
                <p>
                  <strong>Date/Time:</strong>
                  {{
                    data.responseDateTime
                      | momentDate : 'MMM D, YYYY, h:mm:ss a z' : timeZone
                  }}
                </p>
                <p>
                  <strong>Address:</strong>
                  {{ data.fullAddress }}
                </p>

                <p>
                  <strong>Coordinates:</strong> {{ data.latitude }},{{
                    data.longitude
                  }}
                </p>
                <p *ngIf="data?.eventCodeDescription">
                  <strong>Event Type:</strong> {{ data.eventCodeDescription }}
                </p>

                <p>
                  <strong>Percentage Full:</strong>
                  {{ data.lanaVisionEvent.occupancySensorVolumePercentFull }}%
                </p>

                <p>
                  <strong>Battery:</strong>
                  {{ data.lanaVisionEvent.occupancySensorBackupBattery }}
                </p>

                <p>
                  <strong>Temperature:</strong> {{ temperatureFormatter(data) }}
                </p>

                <p>
                  <strong>Door State:</strong> {{ doorStateFormatter(data) }}
                </p>
              </div>
            </div>
          </div>

          <div class="flex-grow-1">
            <ng-template #defaultImage>
              <div class="d-flex justify-content-center">
                <img [src]="image" />
              </div>
            </ng-template>
            <ng-container
              *ngIf="image === 'N/A' || image === 'Unknown'; else defaultImage">
              <span style="color: #a9a9a9">{{ image }}</span>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
