<div class="card shadow">
  <div class="card-header d-flex align-items-center">
    <div class="mr-auto font-weight-bold">
      <i class="fas fa-phone fa-fw mr-2"></i>
      Phone Numbers
    </div>
    <div>
      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">
            {{ formGroup.value.id ? 'Update' : 'Add' }} Phone
          </h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal(formGroup, modal)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formGroup">
            <div class="form-group">
              <label>Phone Number</label>
              <input type="tel" class="form-control" formControlName="number" />
              <lana-error-display
                [control]="formGroup.get('number')"
                label="Phone Number"></lana-error-display>
            </div>
            <div class="form-group">
              <label>Extension</label>
              <input
                type="tel"
                class="form-control"
                formControlName="extension" />
            </div>
            <div class="form-group">
              <label>Type</label>
              <select class="form-control" formControlName="phoneNumberTypeId">
                <option disabled [ngValue]="null">Select Type</option>
                <option [ngValue]="1">Work</option>
                <option [ngValue]="2">Mobile</option>
                <option [ngValue]="3">Fax</option>
              </select>
              <lana-error-display
                [control]="formGroup.get('phoneNumberTypeId')"
                label="Type"></lana-error-display>
            </div>
            <div class="form-group">
              <label>Receive Notifications</label>
              <div class="list-group list-group-horizontal switch">
                <li
                  (click)="formGroup.get('notificationsEnabled').setValue(true)"
                  class="list-group-item"
                  [class.active]="formGroup.get('notificationsEnabled').value">
                  Yes
                </li>
                <li
                  (click)="
                    formGroup.get('notificationsEnabled').setValue(false)
                  "
                  class="list-group-item"
                  [class.active]="!formGroup.get('notificationsEnabled').value">
                  No
                </li>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-start">
          <ng-template #defaultTemplate>
            <p>
              Are you sure you wish to delete
              <strong>{{ formGroup.value.number | phoneNumber }}</strong
              >?
            </p>
            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              (click)="toggleConfirm()">
              No, Nevermind
            </button>
            <button
              type="button"
              class="btn btn-sm btn-success"
              (click)="deletePhone(formGroup, modal)">
              Yes, Delete Phone
            </button>
          </ng-template>
          <ng-container *ngIf="!showConfirm; else defaultTemplate">
            <button
              *ngIf="formGroup.value.id"
              type="button"
              class="btn btn-sm btn-outline-danger"
              (click)="toggleConfirm()">
              Delete Phone
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary ml-auto"
              (click)="confirmAddPhone(formGroup, modal)">
              {{ formGroup.value.id ? 'Update' : 'Add' }} Phone
            </button>
          </ng-container>
        </div>
      </ng-template>
      <ng-container *ngIf="isCurrentUser | async as isCurrentUser">
        <ng-template #addPhoneTemplate>
          <button
            (click)="openModal(content)"
            type="button"
            class="btn btn-sm btn-primary">
            Add Phone
          </button>
        </ng-template>
        <ng-template #defaultAddPhoneTemplate>
          <ng-container
            *lanaHasRole="
              [roles.Administrator];
              then addPhoneTemplate
            "></ng-container>
        </ng-template>
        <ng-container
          *ngIf="
            isCurrentUser;
            then addPhoneTemplate;
            else defaultAddPhoneTemplate
          "></ng-container>
      </ng-container>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <lana-loading></lana-loading>
  </ng-template>
  <div class="card-body p-0">
    <ul class="list-group list-group-flush">
      <ng-template #detaultComment>
        <li class="list-group-item p-5 text-center">
          <p class="mb-0">No phone numbers found</p>
        </li>
      </ng-template>
      <ng-container
        *ngIf="user && user.phoneNumbers.length > 0; else detaultComment">
        <li
          class="list-group-item"
          *ngFor="let phoneNumber of user.phoneNumbers">
          <div class="d-flex align-items-center">
            <div class="mr-auto">
              <ng-container [ngSwitch]="phoneNumber.phoneNumberType.id">
                <ng-container *ngSwitchCase="1">Work</ng-container>
                <ng-container *ngSwitchCase="2">Mobile</ng-container>
                <ng-container *ngSwitchCase="3"
                  >Fax</ng-container
                > </ng-container
              >: {{ phoneNumber.number | phoneNumber }}
              <ng-container *ngIf="phoneNumber.extension"
                >ext: {{ phoneNumber.extension }}</ng-container
              >
            </div>
            <div class="mr-2">
              <i
                [ngClass]="{
                  'fas fa-fw fa-bell': phoneNumber.notificationsEnabled,
                  'far fa-fw fa-bell-slash': !phoneNumber.notificationsEnabled
                }"></i>
            </div>
            <div class="small" *lanaHasRole="[roles.Administrator]">
              <a
                (click)="openModal(content, formGroup, phoneNumber)"
                href="javascript:void(0)"
                >Edit</a
              >
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
