<ng-container *ngIf="control?.touched && control?.errors">
  <ng-container *ngFor="let error of control?.errors | keyvalue">
    <ng-template #errorTemplate let-e>
      <div class="d-block error">
        <i class="fas fa-exclamation-triangle"></i>
        {{ e }}
      </div>
    </ng-template>
    <ng-template #defaultTemplate>
      <ng-container
        *ngTemplateOutlet="
          errorTemplate;
          context: {
            $implicit: errors[error.key]
              ? errors[error.key](label || labels, error.value)
              : (error | json)
          }
        "></ng-container>
    </ng-template>
    <ng-container *ngIf="isArray(error.value); else defaultTemplate">
      <ng-container *ngFor="let e of error.value">
        <ng-container
          *ngTemplateOutlet="
            errorTemplate;
            context: { $implicit: e }
          "></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
