import { Asset } from './../asset/asset';
import { CompanySummary } from './../company/company-summary';
import { Lookup } from '../lookup';
import { SimConfiguration } from './../sim-configuration';

export interface Tracker {
  id: number;
  companySummary: CompanySummary;
  trackerTypeId: number;
  trackerProtocolTypeId?: number;
  trackerTypeName: string;
  imei: string;
  ipAddress: string;
  port: string;
  serialNumber: string;
  meid: string;
  phoneNumber: string;
  ignitionEnabled: boolean;
  reportedFirmwareVersion: string;
  reportedFirmwareVersionTimestamp: Date;
  reportedFirmwareVersionUserId: string;
  mainFirmware: string;
  lowPowerFirmware: string;
  cargoSensorMspFirmware: string;
  cargoSensorDspFirmware: string;
  simOne: SimConfiguration;
  simTwo: SimConfiguration;
  lastReportTimestamp: Date;
  currentAsset: Asset;
  currentAssetExists: boolean;
  trackerLoadedTimestamp: string;
}

export interface UploadTrackers {
  companyId: number;
  createAssetMode: boolean;
}

export interface OnboardTrackers {
  companyId: number;
  serialNumbers: string[];
}

export interface ImportTrackersResponse {
  successful: boolean;
  importCount: number;
  errors: string[];
}

export interface OnboardTrackersResponse {
  success: string[];
  failure: string[];
  noAction: string[];
}

export interface AssignTracker {
  trackerId: number;
  companyId: number;
}

export interface BulkAssignTrackers {
  fromCompanyId: number;
  toCompanyId: number;
  serialNumbers: string[];
}

export interface TrackerTypes {
  id: number;
  name: string;
}

export interface MetaTrackerType extends Lookup {
  companyId: number;
}
