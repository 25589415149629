import { Column } from '@syncfusion/ej2-grids';

export class PriorityLevelFormatter {
  static getValue<T>(prop: string) {
    return (_: Column, model: T) => {
      let badge = 'bg-primary';

      switch (model[prop]) {
        case 'High':
          badge = 'bg-high-priority';
          break;
        case 'Medium':
          badge = 'bg-medium-priority';
          break;
        case 'Low':
          badge = 'bg-low-priority';
          break;
        default:
          badge = 'bg-unknown-priority';
      }
      return `<span class="badge rounded-pill text-light priority-badge ${badge}">${model[prop]}</span>`;
    };
  }
}
