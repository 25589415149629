import { AgmCoreModule } from '@agm/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HasPermissionDirective } from '@app/directives/has-permission/has-permission.directive';
import { MultipleTrackerSensorsPipe } from '@app/pipes/multiple-tracker-sensors.pipe';
import {
  NgbAccordionModule,
  NgbCarouselModule,
  NgbModalModule,
  NgbPopoverModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import {
  AccumulationChartModule,
  ChartModule
} from '@syncfusion/ej2-angular-charts';
import {
  DropDownListModule,
  MultiSelectModule
} from '@syncfusion/ej2-angular-dropdowns';
import {
  FilterService,
  GridModule,
  GroupService,
  PageService,
  ResizeService,
  SortService
} from '@syncfusion/ej2-angular-grids';
import {
  NumericTextBoxModule,
  UploaderModule
} from '@syncfusion/ej2-angular-inputs';
import { LinearGaugeAllModule } from '@syncfusion/ej2-angular-lineargauge';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import {
  AccordionModule,
  ToolbarModule,
  TreeViewModule
} from '@syncfusion/ej2-angular-navigations';
import { PivotViewModule } from '@syncfusion/ej2-angular-pivotview';
import { GridsterModule } from 'angular-gridster2';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HighchartsChartModule } from 'highcharts-angular';
import { CountUpModule } from 'ngx-countup';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NotificationsComponent } from 'src/app/components/alerts/notifications/notifications.component';
import { ScheduleModalComponent } from 'src/app/components/alerts/notifications/schedule-modal/schedule-modal.component';
import { TimeperiodModalComponent } from 'src/app/components/alerts/notifications/timeperiod-modal/timeperiod-modal.component';
import { DashboardReportsComponent } from 'src/app/components/dashboard/dashboard-reports/dashboard-reports.component';
import { WidgetDetailsComponent } from 'src/app/components/dashboard/widget-settings/widget-details/widget-details.component';
import { ViewportComponent } from 'src/app/components/viewport/viewport.component';
import { AlertChainComponent } from './../../components/alerts/alert-chain/alert-chain.component';
import { AssetCategoryBrowserComponent } from './../../components/alerts/asset-category-browser/asset-category-browser.component';
import { AssetsSummaryComponent } from './../../components/alerts/assets-summary/assets-summary.component';
import { AssetsModalComponent } from './../../components/alerts/assets-table/assets-modal/assets-modal.component';
import { AssetsTableComponent } from './../../components/alerts/assets-table/assets-table.component';
import { EmailPreviewComponent } from './../../components/alerts/email-preview/email-preview.component';
import { EvaluationTileComponent } from './../../components/alerts/evaluation-tile/evaluation-tile.component';
import { GeozoneComponent } from './../../components/alerts/evaluation-tile/types/geozone/geozone.component';
import { AssetFormComponent } from './../../components/asset-form/asset-form.component';
import { CarouselComponent } from './../../components/carousel/carousel.component';
import { CloudBasedGeozoneListDetailItemComponent } from './../../components/cloud-based-geozone-list-detail-item/cloud-based-geozone-list-detail-item.component';
import { CloudBasedGeozoneListItemComponent } from './../../components/cloud-based-geozone-list-item/cloud-based-geozone-list-item.component';
import { CompanySelectorSelectComponent } from './../../components/company-selector-select/company-selector-select.component';
import { CompanySelectorComponent } from './../../components/company-selector/company-selector.component';
import { CompanySummaryComponent } from './../../components/company-summary/company-summary.component';
import { ContactUsComponent } from './../../components/contact-us/contact-us.component';
import { DynamicGridComponent } from './../../components/dynamic-grid/dynamic-grid.component';
import { EventDataInfoWindowComponent } from './../../components/event-data-info-window/event-data-info-window.component';
import { GridComponent } from './../../components/grid/grid.component';
import { ImageDataComponent } from './../../components/image-data/image-data.component';
import { MapAssetInfoWindowComponent } from './../../components/map-asset-info-window/map-asset-info-window.component';
import { MapMarkerClusterComponent } from './../../components/map-marker-cluster/map-marker-cluster.component';
import { MultiSelectListViewComponent } from './../../components/multi-select-list-view/multi-select-list-view.component';
import { PivotReportComponent } from './../../components/pivot-report/pivot-report.component';
import { ReportFormComponent } from './../../components/report-form/report-form.component';
import { ReportsModalMapComponent } from './../../components/reports-modal-map/reports-modal-map.component';
import { CommentCardComponent } from './../../components/shared/comment-card/comment-card.component';
import { EmailAddressCardComponent } from './../../components/shared/email-address-card/email-address-card.component';
import { ErrorDisplayComponent } from './../../components/shared/error-display/error-display.component';
import { LoadingButtonComponent } from './../../components/shared/loading-button/loading-button.component';
import { LoadingComponent } from './../../components/shared/loading/loading.component';
import { PasswordDisplayComponent } from './../../components/shared/password-display/password-display.component';
import { PhoneNumberCardComponent } from './../../components/shared/phone-number-card/phone-number-card.component';
import { SubscriptionInformationComponent } from './../../components/shared/subscription-information/subscription-information..component';
import { UserStatusCardComponent } from './../../components/shared/user-status/user-status.component';
import { VisionModalComponent } from './../../components/vision-modal/vision-modal.component';
import { DelayedHoverDirective } from './../../directives/delayed-hover/delayed-hover.directive';
import { HasCompanyDirective } from './../../directives/has-company/has-company.directive';
import { HasRoleDirective } from './../../directives/has-role/has-role.directive';
import { InputFocusDirective } from './../../directives/input-focus/input-focus.directive';
import { AddressPointPipe } from './../../pipes/address-point.pipe';
import { AssetSettingActionPipe } from './../../pipes/asset-setting-action.pipe';
import { CargoVisionPipe } from './../../pipes/cargo-vision.pipe';
import { ColumnParserPipe } from './../../pipes/column-parser.pipe';
import { CompanyBreadcrumbPipe } from './../../pipes/company-breadcrumb.pipe';
import { MomentDatePipe } from './../../pipes/date-timezone.pipe';
import { DoorStatusPipe } from './../../pipes/door-status.pipe';
import { HeadingPipe } from './../../pipes/heading.pipe';
import { IconClassPipe } from './../../pipes/icon-class.pipe';
import { PercentageFullPipe } from './../../pipes/percentage-full.pipe';
import { PhoneNumberPipe } from './../../pipes/phone-number.pipe';
import { TemperaturePipe } from './../../pipes/temperature.pipe';
import { TrackerSensorsPipe } from './../../pipes/tracker-sensors.pipe';
import { WithLoadingPipe } from './../../pipes/with-loading.pipe';
import {
  DatePickerModule,
  DateTimePickerModule
} from '@syncfusion/ej2-angular-calendars';

@NgModule({
  declarations: [
    CommentCardComponent,
    PasswordDisplayComponent,
    ErrorDisplayComponent,
    GridComponent,
    DynamicGridComponent,
    LoadingComponent,
    LoadingButtonComponent,
    AssetFormComponent,
    ReportFormComponent,
    ReportsModalMapComponent,
    PhoneNumberPipe,
    TrackerSensorsPipe,
    MultipleTrackerSensorsPipe,
    TemperaturePipe,
    DoorStatusPipe,
    PercentageFullPipe,
    CargoVisionPipe,
    WithLoadingPipe,
    EmailAddressCardComponent,
    UserStatusCardComponent,
    PhoneNumberCardComponent,
    SubscriptionInformationComponent,
    AssetSettingActionPipe,
    AddressPointPipe,
    MomentDatePipe,
    ColumnParserPipe,
    CompanySummaryComponent,
    CompanyBreadcrumbPipe,
    CompanySelectorComponent,
    ContactUsComponent,
    VisionModalComponent,
    CompanySelectorSelectComponent,
    HeadingPipe,
    MultiSelectListViewComponent,
    CloudBasedGeozoneListItemComponent,
    CloudBasedGeozoneListDetailItemComponent,
    DelayedHoverDirective,
    CarouselComponent,
    HasRoleDirective,
    HasCompanyDirective,
    InputFocusDirective,
    MapAssetInfoWindowComponent,
    EventDataInfoWindowComponent,
    MapMarkerClusterComponent,
    ImageDataComponent,
    EvaluationTileComponent,
    AssetsTableComponent,
    AssetsSummaryComponent,
    AssetCategoryBrowserComponent,
    AlertChainComponent,
    EmailPreviewComponent,
    AssetsModalComponent,
    PivotReportComponent,
    DashboardReportsComponent,
    WidgetDetailsComponent,
    NotificationsComponent,
    TimeperiodModalComponent,
    ScheduleModalComponent,
    ViewportComponent,
    GeozoneComponent,
    IconClassPipe,
    HasPermissionDirective
  ],
  exports: [
    CommentCardComponent,
    PasswordDisplayComponent,
    ErrorDisplayComponent,
    GridComponent,
    DynamicGridComponent,
    LoadingComponent,
    LoadingButtonComponent,
    GridModule,
    ReactiveFormsModule,
    AgmCoreModule,
    AngularSvgIconModule,
    GooglePlaceModule,
    AssetFormComponent,
    ReportFormComponent,
    ReportsModalMapComponent,
    PhoneNumberPipe,
    TrackerSensorsPipe,
    MultipleTrackerSensorsPipe,
    TemperaturePipe,
    DoorStatusPipe,
    PercentageFullPipe,
    CargoVisionPipe,
    WithLoadingPipe,
    EmailAddressCardComponent,
    UserStatusCardComponent,
    PhoneNumberCardComponent,
    SubscriptionInformationComponent,
    AssetSettingActionPipe,
    AddressPointPipe,
    MomentDatePipe,
    ColumnParserPipe,
    CompanySummaryComponent,
    CompanyBreadcrumbPipe,
    CompanySelectorComponent,
    ContactUsComponent,
    VisionModalComponent,
    CompanySelectorSelectComponent,
    HeadingPipe,
    IconClassPipe,
    MultiSelectListViewComponent,
    CloudBasedGeozoneListItemComponent,
    CloudBasedGeozoneListDetailItemComponent,
    DelayedHoverDirective,
    CarouselComponent,
    HasRoleDirective,
    HasCompanyDirective,
    InputFocusDirective,
    ScrollingModule,
    MapAssetInfoWindowComponent,
    EventDataInfoWindowComponent,
    MapMarkerClusterComponent,
    ImageDataComponent,
    EvaluationTileComponent,
    AssetsTableComponent,
    AssetsSummaryComponent,
    AssetCategoryBrowserComponent,
    AlertChainComponent,
    EmailPreviewComponent,
    AssetsModalComponent,
    GridsterModule,
    PivotReportComponent,
    ChartModule,
    AccumulationChartModule,
    HighchartsChartModule,
    CountUpModule,
    DashboardReportsComponent,
    WidgetDetailsComponent,
    NotificationsComponent,
    TimePickerModule,
    NumericTextBoxModule,
    TimeperiodModalComponent,
    ScheduleModalComponent,
    CheckBoxModule,
    LinearGaugeAllModule,
    ViewportComponent,
    GeozoneComponent,
    HasPermissionDirective,
    UploaderModule,
    DatePickerModule,
    DateTimePickerModule
  ],
  imports: [
    CommonModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    AngularSvgIconModule,
    RouterModule,
    GooglePlaceModule,
    NgbModalModule,
    NgbCarouselModule,
    NgbTooltipModule,
    TreeViewModule,
    PivotViewModule,
    ToolbarModule,
    ListViewModule,
    ScrollingModule,
    AccordionModule,
    DropDownListModule,
    MultiSelectModule,
    NgbAccordionModule,
    NgbPopoverModule,
    GridsterModule,
    ChartModule,
    AccumulationChartModule,
    HighchartsChartModule,
    CountUpModule,
    TimePickerModule,
    NumericTextBoxModule,
    CheckBoxModule,
    LinearGaugeAllModule,
    UploaderModule,
    DatePickerModule,
    DateTimePickerModule
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    ResizeService
  ]
})
export class SharedModule {}
