import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as customerJson from '../../assets/customers.json';
import * as domainsJson from '../../assets/domains.json';
import { ConfigsLoaderService } from '../services/configs-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private configService: ConfigsLoaderService
  ) {}

  private readonly customers = this.configService?.configs?.customers;
  private readonly currentCustomer = new BehaviorSubject<string>(null);
  private readonly mapMarker = new BehaviorSubject<string>(null);

  currentCustomer$ = this.currentCustomer.asObservable();
  mapMarker$ = this.mapMarker.asObservable();

  loadStyle(name: string) {
    const data = (customerJson as any).default;
    const root = document.documentElement;
    this.mapMarker.next(null);

    if (name && data[name] !== undefined) {
      Object.keys(data[name]).forEach((value) => {
        root.style.setProperty(`--${value}`, data[name][value]);
      });

      if (data?.[name]?.['mapMarker']) {
        this.mapMarker.next(data[name]['mapMarker']);
      }
    } else {
      Object.keys(data['default']).forEach((value) => {
        root.style.setProperty(`--${value}`, data['default'][value]);
      });
      if (data?.['default']?.['mapMarker']) {
        this.mapMarker.next(data['default']['mapMarker']);
      }
    }
  }

  loadFavicon(name: string) {
    document
      .getElementById('appFavicon')
      .setAttribute('href', `/assets/favicons/${name}/favicon.ico`);
  }

  loadTitle(customer) {
    if (customer?.title) {
      document.getElementById('appTitle').innerText = customer.title;
    } else {
      document.getElementById('appTitle').innerText =
        'Callpass Administrative Tool';
    }
  }

  loadDescription(customer) {
    if (customer?.title) {
      document.getElementById('appDescription').innerText =
        customer.description;
    } else {
      document.getElementById('appDescription').innerText =
        'Callpass Administrative Tool';
    }
  }

  structureHostToCss() {
    const hostname = window.location.hostname;
    const [customer] = this.customers.filter(
      (customer) =>
        customer.domains.filter((domain) => domain === hostname).length > 0
    );

    this.currentCustomer.next(null);

    if (customer && customer?.name) {
      this.loadStyle(customer.name);
      this.loadFavicon(customer.name);
      this.loadTitle(customer);
      this.loadDescription(customer);
      this.currentCustomer.next(customer.name);
    } else {
      this.loadTitle(null);
      this.loadDescription(null);
      this.loadStyle(null);
    }
  }

  getCustomer() {
    this.structureHostToCss();
  }

  detectMobileDevice() {
    const hostname = window.location.hostname;
    const [customer] = this.customers.filter(
      (customer) =>
        customer.domains.filter((domain) => domain === hostname).length > 0
    );
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const isTablet = this.isTablet();
    const domainHostname = window.location.hostname;
    const domainContainsMobile = domainHostname.includes('mobile');
    const domains = (domainsJson as any).default;

    if (isMobile && !domainContainsMobile && !isTablet) {
      let key = 'default';
      if (customer?.name === 'att') {
        key = 'att';
        const mobileObject = domains[key].mobile;
        const defaultObject = domains[key].default;
        const newLocationKey = Object.keys(defaultObject).find((k) =>
          defaultObject[k].includes(domainHostname)
        );
        window.location = mobileObject[newLocationKey];
        return true;
      }
    }
    return false;
  }

  isTablet() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  }
}
