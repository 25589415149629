import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { Roles } from './../../../models/auth/role';
import { EmailAddress, User } from './../../../models/user/user';
import { AuthService } from './../../../services/auth.service';
import { EmailAddressService } from './../../../services/email-address.service';

@Component({
  selector: 'lana-email-address-card',
  templateUrl: './email-address-card.component.html',
  styleUrls: ['./email-address-card.component.scss']
})
export class EmailAddressCardComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private emailAddressService: EmailAddressService,
    private formBuilder: UntypedFormBuilder,
    private ngbModal: NgbModal
  ) {}

  @Input() user: User;
  @Output() emailAddressAdded = new EventEmitter<EmailAddress>();
  @Output() emailAddressUpdated = new EventEmitter<EmailAddress>();

  readonly roles = Roles;

  formGroup: UntypedFormGroup;
  isCurrentUser: Observable<boolean>;
  showConfirm = false;

  closeModal(formGroup: UntypedFormGroup, modal: NgbActiveModal): void {
    Object.keys(formGroup.controls).forEach((control) => {
      if (control !== 'adUserId') {
        formGroup.get(control).reset();
      }
    });
    modal.close();
    this.showConfirm = false;
  }

  confirmAddEmail(formGroup: UntypedFormGroup, modal: NgbActiveModal): void {
    if (formGroup.valid) {
      const model = formGroup.value;
      const isCreate = !model.id;
      const apiCall = isCreate
        ? this.emailAddressService.createEmailAddress(model)
        : this.emailAddressService.updateEmailAddress(model);

      apiCall
        .pipe(
          first(),
          tap((response) =>
            isCreate
              ? this.emailAddressAdded.emit(response)
              : this.emailAddressUpdated.emit(response)
          ),
          tap((_) => this.closeModal(formGroup, modal))
        )
        .subscribe();
    }
  }

  deleteEmail(formGroup: UntypedFormGroup, modal: NgbActiveModal): void {
    this.emailAddressService
      .deleteEmailAddress(formGroup.value.id)
      .pipe(
        first(),
        tap(() => this.emailAddressUpdated.emit(null)),
        tap((_) => this.closeModal(formGroup, modal))
      )
      .subscribe();
  }

  openModal(
    content: any,
    formGroup: UntypedFormGroup = null,
    model: EmailAddress = null
  ): void {
    this.ngbModal.open(content, { backdrop: 'static' });

    if (formGroup && model) {
      formGroup.patchValue(model);
    }
  }

  toggleConfirm(): void {
    this.showConfirm = !this.showConfirm;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      id: this.formBuilder.control(null),
      adUserId: this.formBuilder.control(null, Validators.required),
      address: this.formBuilder.control(
        '',
        Validators.compose([Validators.required, Validators.email])
      ),
      notificationsEnabled: this.formBuilder.control(true)
    });

    if (this.user) {
      this.formGroup.get('adUserId').setValue(this.user.id);
    }

    // this.isCurrentUser = this.user
    //   ? this.authService.currentUser$.pipe(map(({ id }) => this.user.id === id))
    //   : of(false);
  }
}
