import { Injectable } from '@angular/core';
import {
  DataStateChangeEventArgs,
  PredicateModel
} from '@syncfusion/ej2-grids';
import { Predicate } from '@syncfusion/ej2-data';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { OdataResponseTransform } from './../models/odata-response';
import { Tracker } from './../models/tracker/tracker';
import { AuthService } from './auth.service';
import { ODataHelperService } from './o-data-helper.service';
import { TrackerService } from './tracker.service';

@Injectable()
export class AssetAlertTableService extends Subject<DataStateChangeEventArgs> {
  constructor(
    private authService: AuthService,
    private oDataHelperService: ODataHelperService,
    private trackerService: TrackerService
  ) {
    super();
  }

  private readonly destroy = new Subject();
  // private filter: PredicateModel[] = undefined;
  private filter: Predicate[] = undefined;
  private readonly exportData = new BehaviorSubject(null);

  exportData$ = this.exportData.asObservable();

  public execute(state: any): void {
    this.getData(state).subscribe((x) => super.next(x as any));
  }

  protected getExport(
    state: DataStateChangeEventArgs & { includeSubCompany: boolean }
  ): Observable<OdataResponseTransform<Tracker>> {
    this.filter = state.where || this.filter;

    const filterQuery = this.oDataHelperService.calcFilterQuery(this.filter);

    return state.includeSubCompany
      ? this.trackerService.getExportTrackers({ filterQuery })
      : this.authService.companyId$.pipe(
          switchMap((companyId) =>
            this.trackerService.getExportTrackers({
              filterQuery: filterQuery
                ? `${filterQuery} and CompanySummary/Id eq ${companyId}`
                : `&$filter=CompanySummary/Id eq ${companyId}`
            })
          )
        );
  }

  protected getData(
    state: DataStateChangeEventArgs & { includeSubCompany: boolean }
  ): Observable<OdataResponseTransform<Tracker>> {
    this.filter = state.where || this.filter;

    const filterQuery = this.oDataHelperService.calcFilterQuery(this.filter);
    const pageQuery = this.oDataHelperService.calcPageQuery(state);
    const sortQuery = this.oDataHelperService.calcSortQuery(state);

    return state.includeSubCompany
      ? this.trackerService.getTrackers({ filterQuery, pageQuery, sortQuery })
      : this.authService.companyId$.pipe(
          switchMap((companyId) =>
            this.trackerService.getTrackers({
              filterQuery: filterQuery
                ? `${filterQuery} and CompanySummary/Id eq ${companyId}`
                : `&$filter=CompanySummary/Id eq ${companyId}`,
              pageQuery,
              sortQuery
            })
          )
        );
  }
}
