import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lana-timeperiod-modal',
  templateUrl: './timeperiod-modal.component.html',
  styleUrls: ['./timeperiod-modal.component.scss']
})
export class TimeperiodModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
