import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Constants } from './../constants';
import { CreateMetaAssetModel, MetaAssetModel } from './../models/asset/asset';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AssetModelService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  private odataUrl = `${Constants.Odata}/MetaAssetModels?$expand=MetaAssetMake`;
  private url = `${Constants.Api}/MetaAssetModel`;

  getAssetModels(metaAssetMakeId: number): Observable<MetaAssetModel[]> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<MetaAssetModel>(
          `${this.odataUrl}&companyContext=${companyId}&$filter=MetaAssetMake/Id eq ${metaAssetMakeId}`
        )
      ),
      map((response) => response['value']),
      catchError((_) => of({ value: [] }))
    );
  }

  createAssetModel(model: CreateMetaAssetModel): Observable<MetaAssetModel> {
    return this.httpClient.post<MetaAssetModel>(this.url, model);
  }
}
