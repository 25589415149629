import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lana-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss'],
  providers: [DecimalPipe]
})
export class ErrorDisplayComponent {
  constructor(private decimalPipe: DecimalPipe) {}

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() labels: string[];
  @Input() radiusUnit: string;

  private getRadiusValue(
    radiusValue: number,
    radiusUnit: string
  ): { value: string; unit: string } {
    let retval = radiusValue;
    let unit = radiusUnit;

    switch (radiusUnit) {
      case 'feet':
        retval = radiusValue * 3.281;
        unit = retval === 1 ? 'foot' : 'feet';
        break;
      case 'yards':
        retval = radiusValue * 1.094;
        unit = retval === 1 ? 'yard' : 'yards';
        break;
      case 'miles':
        retval = radiusValue / 1609;
        unit = retval === 1 ? 'mile' : 'miles';
        break;
      case 'kilometers':
        retval = radiusValue / 1000;
        unit = retval === 1 ? 'kilometer' : 'kilometers';
        break;
    }

    return { value: this.decimalPipe.transform(retval, '1.0'), unit };
  }

  private getRadiusUnitError(
    error: { max: number },
    radiusUnit: string,
    label: string
  ): string {
    const { value, unit } = this.getRadiusValue(error.max, radiusUnit);
    return `${label} must be less than or equal to ${value} ${unit}.`;
  }

  readonly errors = {
    email: (label: string, _: boolean) => `${label} must be an email address.`,
    invaliCommaEmails: (label: string, _: boolean) =>
      `${label} must be an email address.`,
    invalidAddress: (label: string, _: boolean) =>
      `${label} must contain a valid street number and name, city, state, country, and zip code.`,
    invalidPassword: (label: string, _: boolean) =>
      `${label} must contain a letter and a number.`,
    required: (label: string, _: boolean) => `${label} is a required field.`,
    mask: (label: string, { requiredMask }: { requiredMask: string }) =>
      `${label} is invalid. Please use the ${requiredMask} format.`,
    max: (label: string, error: { max: number }) =>
      this.radiusUnit
        ? this.getRadiusUnitError(error, this.radiusUnit, label)
        : `${label} must be less than or equal to ${error.max}.`,
    maxlength: (label: string, error: { requiredLength: number }) =>
      `${label} must be less than or equal to ${error.requiredLength} characters.`,
    minlength: (label: string, error: { requiredLength: number }) =>
      `${label} must be more than ${error.requiredLength} characters.`,
    maxRows: (label: string, { rowCount }) =>
      `${label} must be less than ${rowCount} rows.`,
    min: (label: string, error: { min: number }) =>
      `${label} must be greater than or equal to ${error.min}.`,
    mustMatch: (labels: string[], _: boolean) =>
      labels.reduce(
        (working, next) =>
          working ? `${working} ${next}.` : `${next} must match`,
        ''
      ),
    pattern: (label: string, _) => `${label} must be valid.`,
    fromServer: (_: string, message: string) => `${message}`
  };

  isArray(error: any): boolean {
    return Array.isArray(error);
  }
}
