import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';
import { Roles } from '@app/models/auth/role';
import { AdministrationService } from '@app/services/administration.service';
import { CompanyService } from '@app/services/company.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { SignalRService } from 'src/app/services/signalr.service';
import { CurrentUser } from './../../models/auth/current-user';
import { Menu, Menus } from './../../models/menu/menu';
import { MenuItems } from './../../models/menu/menu-items';
import { AuthService } from './../../services/auth.service';
import { MenuService } from './../../services/menu.service';
import { ThemeService } from './../../services/theme.service';
import { CompanySelectorComponent } from './../company-selector/company-selector.component';
import { ContactUsComponent } from './../contact-us/contact-us.component';

@Component({
  selector: 'lana-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-200px)' }),
        animate('250ms', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('250ms', style({ opacity: 0, transform: 'translateX(-200px)' }))
      ])
    ])
  ]
})
export class LayoutComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private themeService: ThemeService,
    private router: Router,
    private signalRService: SignalRService,
    private toastr: ToastrService,
    private companyService: CompanyService,
    private administrationService: AdministrationService,
    private route: ActivatedRoute
  ) {}

  @ViewChild(CompanySelectorComponent)
  companySelector: CompanySelectorComponent;
  @ViewChild(ContactUsComponent) contactUs: ContactUsComponent;

  private readonly mapUrl = '/map';
  private readonly dashboardUrl = '/dashboard';
  private readonly startOfGeozoneUrl = '/management/geozones';

  getCompanyDetailSub: Subscription;
  routerSub: Subscription;
  readonly menuItems = MenuItems;
  readonly roles = Roles;
  currentUser: Observable<CurrentUser>;
  isMapUrl: Observable<boolean>;
  mainMenu: Observable<Menu>;
  topMenu: Observable<Menu>;
  theme: Observable<string>;
  userMenu: any;
  hideMenu = false;
  showMenu = true;
  navStyle = 'font-size: 1.2rem; color: cornflowerblue;';

  openCompanySelector(): void {
    this.companySelector.openModel();
  }

  openContactUs(): void {
    this.contactUs.openModel();
  }

  // TODO: This method is triggered extremely frequently and should be looked at
  isRouteActive(menuItemName: string, special = false): boolean {
    if (special) {
      return this.router.url.includes(menuItemName);
    }
    const url = `/${menuItemName.toLowerCase()}`;

    return this.router.url.startsWith(url);
  }

  selectCompany(companyId: number): void {
    this.authService.selectCompany(companyId);
  }

  showError(message: string) {
    this.toastr.error(message, 'An error has occurred');
  }

  noPortError() {
    this.toastr.error(
      'If you continue to get this error, please contact customer service.',
      'No port is configured',
      {
        timeOut: 5000,
        enableHtml: true
      }
    );
  }

  showSuccess(message: string) {
    this.toastr.success(message, 'Success!');
  }

  toggleMenu(): void {
    this.hideMenu = !this.hideMenu;
    this.showMenu = !this.showMenu;
  }

  goToSidebarPage(url = '/'): void {
    const currentPlatform = window.location.pathname.split('/')[1];

    if (currentPlatform) {
      this.router.navigateByUrl(`${currentPlatform}/${url}`);
    }
  }

  routerListener(): void {
    this.routerSub = this.router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationStart) {
            this.getMenu(event?.url);
          }
        })
      )
      .subscribe();
  }

  getMenu(url: string): void {
    if (url?.includes('lana')) {
      this.mainMenu = this.menuService.getMenu(Menus.LANA);
    } else if (url?.includes('igotcha')) {
      this.mainMenu = this.menuService.getMenu(Menus.iGotcha);
    } else {
      this.mainMenu = this.menuService.getMenu(Menus.Callpass);
    }
    this.topMenu = this.menuService.getMenu(Menus.Top);
    this.userMenu = this.menuService.getMenu(Menus.User);
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser$;
    this.routerListener();
    // this.getCompanyDetailSub = this.getCompanyDetail()
    //   .pipe(
    //     tap((res: any) => {
    //       console.log([res]);
    //       // this.administrationService.administrationFeature.next(
    //       //   res?.companyProfile?.administration?.companyFeatures
    //       // );
    //     })
    //     )
    //     .subscribe();
    this.getMenu(this.router.url);
    // this.theme = this.themeService.currentCustomer$;
  }

  getCompanyDetail() {
    return this.authService.companyId$.pipe(
      concatMap((companyId) => this.companyService.getCompany(companyId)),
      switchMap((_) => this.companyService.currentlyViewingCompany$),
      map((response) => response),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  clearStarterDataFromLocalStorage() {
    // clean up starter interrupt notification data when component is destroyed
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('starterInterrupt')) {
        localStorage.removeItem(key);
      }
    });
  }

  ngOnDestroy() {
    this.clearStarterDataFromLocalStorage();
    this.getCompanyDetailSub.unsubscribe();
    this.routerSub.unsubscribe();
  }
}
