import {
  Asset,
  MetaAssetMake,
  MetaAssetModel,
  MetaAssetType
} from 'src/app/models/asset/asset';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  tap
} from 'rxjs/operators';

import { AlertsService } from 'src/app/services/alerts.service';
import { AssetAlertTableService } from 'src/app/services/asset-alert-table.service';
import { AssetTypeService } from 'src/app/services/asset-type.service';
import { Lookup } from 'src/app/models/lookup';
import { MetaTrackerType } from 'src/app/models/tracker/tracker';
import { TrackerService } from 'src/app/services/tracker.service';

@Component({
  selector: 'lana-assets-table',
  templateUrl: './assets-table.component.html',
  styleUrls: ['./../../../styles/header.scss', './assets-table.component.scss'],
  providers: [AssetAlertTableService]
})
export class AssetsTableComponent implements OnInit {
  private assetsList: any;

  get assets(): any {
    return this.assetsList;
  }

  @Input() set assets(assets) {
    this.assetsList = assets;

    assets.map((asset) =>
      asset.type === 'picker' ? (this.assetsSelected = asset.selection) : null
    );

    this.selectedTrackerTypes = assets.filter(
      (asset) => asset.type === 'tracker type'
    );

    if (!this.selectedAssetTypes) {
      this.selectedAssetTypes = assets.filter(
        (asset) => asset.type === 'asset type'
      );
    }

    if (!this.selectedAssetMakes) {
      this.selectedAssetMakes = assets.filter(
        (asset) => asset.type === 'asset make'
      );
    }

    if (!this.selectedAssetModels) {
      this.selectedAssetModels = assets.filter(
        (asset) => asset.type === 'asset model'
      );
    }

    this.selectedGroups = assets.filter((asset) => asset.type === 'group');

    if (typeof this.formGroup !== 'undefined' && assets.length === 0) {
      this.formGroup.setValue({ assetFilter: null });
    }
  }
  @Output() row = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() individual = new EventEmitter<any>();
  formGroup: UntypedFormGroup;
  filterTypes = [
    { value: 'all', name: 'Include All Assets' },
    { value: 'groups', name: 'Select a Group' },
    // { value: 'types', name: 'Select an Asset Type' },
    { value: 'assets', name: 'Select Individual Assets' },
    { value: 'trackerTypes', name: 'Tracker Type' }
  ];
  table$: Observable<any> = null;
  assetTypes: Observable<MetaAssetType[]>;
  assetMakes: Observable<MetaAssetMake[]>;
  assetModels: Observable<MetaAssetModel[]>;
  assetsSelection: Observable<Asset[]>;
  assetsSelected: any[] = [];
  assetsCount = 0;
  trackerTypes: Observable<MetaTrackerType[]>;

  selectedAssetTypes: Lookup[];
  selectedAssetMakes: Lookup[];
  selectedAssetModels: Lookup[];

  selectedGroups: Observable<Lookup[]>;
  selectedTrackerTypes: Observable<MetaTrackerType[]>;

  readonly loading = new BehaviorSubject(false);

  type: string = '';
  valueSelection = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public assetAlertTableService: AssetAlertTableService,
    private alertsService: AlertsService,
    private assetTypeService: AssetTypeService,
    private trackerService: TrackerService
  ) {}

  addAsset(
    type: string,
    id: number = 0,
    name: string = '',
    assetCount: number = 0,
    data: any = null
  ) {
    this.row.emit({ type, id, name, assetCount, data });
  }

  assetAdded(asset: any) {
    if (!this.selectedAssetTypes.some((t) => t.id === asset.parent.id)) {
      this.selectedAssetTypes.push(asset.parent);
    }

    if (!this.selectedAssetMakes.some((t) => t.id === asset.metaAssetMake.id)) {
      this.selectedAssetMakes.push(asset.metaAssetMake);
    }

    this.selectedAssetModels.push(asset);
    this.addAsset(
      'asset type',
      asset.parent.id,
      asset.parent.name,
      1,
      this.getSelectedMakesAndModels(asset)
    );
  }

  assetRemoved(asset: any) {
    this.selectedAssetModels.splice(this.selectedAssetModels.indexOf(asset), 1);
    this.addAsset(
      'asset type',
      asset.parent.id,
      asset.parent.name,
      1,
      this.getSelectedMakesAndModels(asset)
    );
  }

  assetSelected(name: string, selection: any) {
    selection.forEach((asset) => {
      if (typeof asset !== 'number') {
        asset.isChecked
          ? this.addAsset(name, asset.id, asset.name, 1)
          : this.remove.emit(asset.id);
      }
    });
  }

  getSelectedMakesAndModels(asset: any): any {
    return {
      makes: this.selectedAssetMakes.filter(
        (m) => m.id === asset.metaAssetMake.id
      ),
      models: this.selectedAssetModels.filter(
        (m: any) =>
          m.parent.id === asset.parent.id &&
          m.metaAssetMake.id === asset.metaAssetMake.id
      )
    };
  }

  individualAssetsSelected(assets: any[]) {
    if (typeof assets[0] === 'number' || assets.length === 0) {
      this.individual.emit(assets);
    }
  }

  loadTable(type: string) {
    this.loading.next(true);
    this.type = type;

    switch (type) {
      case 'groups':
        this.table$ = this.alertsService.filterTypes(type).pipe(
          map((response) => response.result),
          tap((_) => this.loading.next(false))
        );
        break;
      case 'all':
        this.addAsset('all', -9999, 'Include All Assets');
        this.table$ = null;
        this.loading.next(false);
        break;
      case 'types':
        this.assetTypes = this.assetTypeService
          .getAssetTypes()
          .pipe(tap((_) => this.loading.next(false)));
        break;
      case 'assets':
        this.assetsSelection = this.alertsService.getAssets().pipe(
          map((res: any) => {
            return 0 === res.count ? [] : res.result;
          }),
          tap((_) => this.loading.next(false)),
          catchError((_) => of({ result: [], count: 0 }))
        );
        break;
      case 'trackerTypes':
        this.trackerTypes = this.trackerService.getTrackerTypes().pipe(
          map((response: any) => response),
          tap((_) => this.loading.next(false)),
          catchError((_) => of({ result: [], count: 0 }))
        );

        break;
      default:
        this.table$ = null;
        this.loading.next(false);
    }
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      assetFilter: this.formBuilder.control(null, Validators.required)
    });

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250),
        tap((value) => this.loadTable(value.assetFilter))
      )
      .subscribe();
  }
}
