import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  animate,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

@Component({
  selector: 'lana-assets-summary',
  templateUrl: './assets-summary.component.html',
  styleUrls: ['./assets-summary.component.scss'],
  animations: [
    trigger('enterExitLeft', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-200px)' }),
        animate(
          '300ms ease-in',
          style({ opacity: 1, transform: 'translateX(0)' })
        )
      ]),
      transition(':leave', [
        animate(
          '300ms ease-in',
          style({ opacity: 0, transform: 'translateX(-200px)' })
        )
      ])
    ]),
    trigger('fadeSlideDown', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' }))
      ])
    ])
  ]
})
export class AssetsSummaryComponent implements OnInit {
  @Input() assets: any;
  @Input() count: number = 0;
  @Output() remove = new EventEmitter<any>();

  removeAsset(id: number) {
    this.remove.emit(id);
  }
  constructor() {}

  ngOnInit(): void {}
}
