import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@app/constants';
import { Company } from '@app/models/company/company';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';
import { ConfigsLoaderService } from './configs-loader.service';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  administration = new BehaviorSubject([]);
  administrationFeature = new BehaviorSubject([]);
  AdministrationRrepots = new BehaviorSubject([]);
  companyProfile: any;
  data = [];
  dataReports = [];
  companyFeatures: any;
  public adHeader: HttpHeaders;
  private readonly url = `${Constants.Api}`;
  constructor(
    private configService: ConfigsLoaderService,
    private authService: AuthService,
    private companyService: CompanyService,
    private httpClient: HttpClient
  ) {
    this.administrationFeature.subscribe((res) => {
      this.companyFeatures = res;
      if (res?.length > 0) {
        this.data = [
          {
            id: 1,
            moduleType: 'LANA Vision',
            isActive: res[0].feature.lanaVision,
            children: []
          },
          {
            id: 2,
            moduleType: 'LANA Chill',
            isActive: res[0].feature.lanaChill,
            children: []
          },
          {
            id: 3,
            moduleType: 'Reports',
            isActive: this.checkCondition(res[0].feature.reports),
            children: [
              {
                id: 4,
                moduleType: 'Alert History',
                isActive: res[0].feature.reports.alertHistory,
                children: []
              },
              {
                id: 5,
                moduleType: 'Asset Awareness',
                isActive: res[0].feature.reports.assetAwareness,
                children: []
              },
              {
                id: 6,
                moduleType: 'Cargo History',
                isActive: res[0].feature.reports.cargoHistory,
                children: []
              },
              {
                id: 7,
                moduleType: 'Climate',
                isActive: res[0].feature.reports.climate,
                children: []
              },
              {
                id: 8,
                moduleType: 'Command History',
                isActive: res[0].feature.reports.commandHistory,
                children: []
              },
              {
                id: 9,
                moduleType: 'Data Log',
                isActive: res[0].feature.reports.dataLog,
                children: []
              },
              {
                id: 10,
                moduleType: 'Engine Hours',
                isActive: res[0].feature.reports.engineHours,
                children: []
              },
              {
                id: 11,
                moduleType: 'Mileage',
                isActive: res[0].feature.reports.mileage,
                children: []
              },
              {
                id: 12,
                moduleType: 'Trip Summary',
                isActive: res[0].feature.reports.tripSummary,
                children: []
              },
              {
                id: 13,
                moduleType: 'Dwell and Demurrage',
                isActive: res[0].feature.reports.dwellAndDemurrage,
                children: []
              },
              {
                id: 14,
                moduleType: 'Periodic Events',
                isActive: res[0].feature.reports.periodicEvents,
                children: []
              }
            ]
          }
        ];
        this.dataReports = [
          {
            value: 'excelExport',
            name: 'Excel Export',
            isActive: res[0].reportFeatures.excelExport,
            children: []
          },
          {
            value: 'pdfExport',
            name: 'PDF Export',
            isActive: res[0].reportFeatures.pdfExport,
            children: []
          },
          {
            value: 'columnSelector',
            name: 'Column Selector',
            isActive: res[0].reportFeatures.columnSelector,
            children: []
          },
          {
            value: 'columnResizing',
            name: 'Column Resizing',
            isActive: res[0].reportFeatures.columnResizing,
            children: []
          },
          {
            value: 'columnGrouping',
            name: 'Column Grouping',
            isActive: res[0].reportFeatures.columnGrouping,
            children: []
          }
        ];
        this.administration.next(this.data);
        localStorage.setItem('administration', JSON.stringify(this.data));
        this.AdministrationRrepots.next(this.dataReports);
      }
    });
    this.getCompanyDetail().subscribe((res: any) => {
      this.companyProfile = res;
    });
  }
  getPermission() {
    return this.administration;
  }
  updateCompanyProfile(companyId) {
    return this.httpClient.put(`${this.url}/${companyId}`, this.companyProfile);
  }
  getCompanyProfile(companyId: number): Observable<Company> {
    return this.httpClient.get<Company>(
      `${this.url}/igotcha/User/?CompanyId=14826`
    );
  }
  getCompanyDetail() {
    return this.authService.companyId$.pipe(
      concatMap((companyId) => this.getCompanyProfile(companyId)),
      map((response) => response),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  updateCompanyDetail(formValue) {
    this.administration.next(this.data);
    this.AdministrationRrepots.next(this.dataReports);
    this.companyProfile.administration = this.mapData(
      this.data,
      this.dataReports,
      formValue
    );
    return this.authService.companyId$.pipe(
      tap((companyId) =>
        this.updateCompanyProfile(
          formValue?.companyId ? formValue.companyId : companyId
        ).subscribe()
      )
    );
  }
  mapData(data, dataReport, formValue) {
    const administration = {
      companyFeatures: [
        {
          companyId: formValue.companyId,
          feature: {
            lanaVision: data[0].isActive,
            lanaChill: data[1].isActive,
            reports: {
              alertHistory: data[2].children[0].isActive,
              assetAwareness: data[2].children[1].isActive,
              cargoHistory: data[2].children[2].isActive,
              climate: data[2].children[3].isActive,
              commandHistory: data[2].children[4].isActive,
              dataLog: data[2].children[5].isActive,
              dwellAndDemurrage: data[2].children[6].isActive,
              engineHours: data[2].children[7].isActive,
              mileage: data[2].children[8].isActive,
              periodicEvents: data[2].children[9].isActive,
              tripSummary: data[2].children[10].isActive
            }
          },
          reportFeatures: {
            excelExport: dataReport[0].isActive,
            pdfExport: dataReport[1].isActive,
            columnSelector: dataReport[2].isActive,
            columnResizing: dataReport[3].isActive,
            columnGrouping: dataReport[4].isActive
          },
          settings: {
            battery: '',
            occupancy: '',
            temperature: '',
            timezone: formValue.Timezone
          }
        }
      ]
    };
    return administration;
  }
  checkCondition(obj) {
    for (const o in obj) if (!obj[o]) return false;

    return true;
  }
}
