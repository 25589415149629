import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  ColumnChooserService,
  DetailRowService,
  ExcelExportProperties,
  ExcelExportService,
  GridComponent as SyncGridComponent,
  GroupService,
  PageSettingsModel,
  ReorderService,
  ResizeService,
  ToolbarItems,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { Constants } from 'src/app/constants';
import {
  DataStateChangeEventArgs,
  SortSettingsModel
} from '@syncfusion/ej2-grids';

@Component({
  selector: 'lana-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
  providers: [
    GroupService,
    ToolbarService,
    ExcelExportService,
    ColumnChooserService,
    DetailRowService,
    ReorderService,
    ResizeService
  ]
})
export class ReportFormComponent implements OnInit, OnChanges {
  @Input() assetHoursReport;
  @Input() allDates;
  @Input() exportFileName: string = 'Export';
  @ViewChild('grid') public grid: SyncGridComponent;

  public toolbarOptions: ToolbarItems[];
  public data: object[];
  public pageSettings: PageSettingsModel;
  public pageOptions: object;
  @Output() dataStateChange = new EventEmitter();

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id.includes('excelexport')) {
      const excelExportProperties: ExcelExportProperties = {
        fileName: `${this.exportFileName}.csv`
      };
      this.grid.csvExport(excelExportProperties);
    }
  }

  pageChanged(state: DataStateChangeEventArgs): void {
    const includeSubCompany = false;

    // const newState = { ...state, includeSubCompany };
    const { skip } = state;
    // this.loadData(newState);

    // this.pagination$.next({skip, take: this.rowsperpage});
    this.dataStateChange.emit(state);
  }

  ngOnInit(): void {
    this.data = this.assetHoursReport;
    this.toolbarOptions = ['ExcelExport'];
    this.pageOptions = { pageSize: Constants.DefaultPageSize, pageCount: 5 };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allDates) {
      this.allDates = changes.allDates.currentValue;
    }
    if (changes.assetHoursReport) {
      this.data = changes.assetHoursReport.currentValue;
    }
  }
}
