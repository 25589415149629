import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { AdministrationReportsService } from '@app/services/administration-reports.service';
import { AdministrationService } from '@app/services/administration.service';
import {
  ColumnChooserService,
  DetailRowService,
  ExcelExportProperties,
  ExcelExportService,
  GridComponent as SyncGridComponent,
  GridModel,
  GroupService,
  GroupSettingsModel,
  QueryCellInfoEventArgs,
  ReorderService,
  ResizeService,
  SelectionSettingsModel,
  ToolbarItems,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { MouseEventArgs } from '@syncfusion/ej2-base';
import { Predicate } from '@syncfusion/ej2-data';
import {
  DataStateChangeEventArgs,
  ExcelQueryCellInfoEventArgs,
  SortSettingsModel
} from '@syncfusion/ej2-grids';
import { Tooltip } from '@syncfusion/ej2-popups';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Constants } from './../../constants';
import { LanaColumnModel } from './../../models/column';
import { FilterSettings } from './../../models/filter-settings';
import { AuthService } from './../../services/auth.service';
import { CompanyService } from './../../services/company.service';

@Component({
  selector: 'lana-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [
    GroupService,
    ToolbarService,
    ExcelExportService,
    ColumnChooserService,
    DetailRowService,
    ReorderService,
    ResizeService
  ]
})
export class GridComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private companyService: CompanyService,
    private AdministrationService: AdministrationService,
    private modalService: NgbModal
  ) {}

  @Input() columns: LanaColumnModel<any>[];
  @Input() dynamicColumns: Subject<any>;
  @Input() childColumns: any;
  @Input() dataService: Subject<DataStateChangeEventArgs> & {
    execute: (
      state: DataStateChangeEventArgs & { includeSubCompany: boolean }
    ) => void;
  } & {
    getExportAssets: (
      state: DataStateChangeEventArgs,
      includeSubCompany: boolean
    ) => void;
  } & { exportData$: any } & {
    childData: any;
  } & {
    getExport: (
      state: DataStateChangeEventArgs,
      includeSubCompany: boolean
    ) => any;
  };
  @Input() filterSettings: FilterSettings;
  @Input() sortSettings: SortSettingsModel;
  @Input() link: string;
  @Input() defaultState: any;
  @Input() isSelectable: boolean;
  @Input() isSearchAble: boolean;
  @Input() isDeviceReport: boolean;
  @Input() isAuditReport: boolean;
  @Input() isStatusFilter: boolean;
  @Input() isCompanyAndSearch: boolean;
  @Input() isModal = false;
  @Input() includeSubCompanyText: string;
  @Input() includeSubCompany = false;
  @Input() displayEntityBreadcrumb = false;
  @Input() allowGrouping = false;
  @Input() allowExcelExport = false;
  @Input() excelExportObservable = false;
  @Input() toolbarOptions: ToolbarItems[] = ['ExcelExport', 'CsvExport'];
  @Input() exportVisible: any = {};
  @Input() exportFileName = 'Export';
  @Input() groupSettings: GroupSettingsModel = {};
  @Input() customClass = 'customClass';
  @Input() selectionOptions: SelectionSettingsModel;
  @Input() pageNo$: Observable<any>;
  @Input() version: { version: number };
  @Input() exportColumns: { show: string[]; hide: string[] };

  @Output() rowSelected = new EventEmitter<any>();
  @Output() locationSelected = new EventEmitter<any>();
  @Output() statusSelected = new EventEmitter<any>();
  @Output() statusSelectedCancel = new EventEmitter<any>();

  @ViewChild('grid') grid: SyncGridComponent;
  selectedCompany: { id: number; name: string } = null;
  selectedCompanyFroms: { id: number; name: string } = null;
  allowResizing = false;
  filterTypes = [
    { value: 'all', name: 'Include All Assets' },
    { value: 'groups', name: 'Select a Group' },
    // { value: 'types', name: 'Select an Asset Type' },
    { value: 'assets', name: 'Select Individual Assets' },
    { value: 'trackerTypes', name: 'Tracker Type' }
  ];
  changeStatus = [
    { value: 'disable', name: 'Disable' },
    { value: 'enable', name: 'Enable' }
  ];
  private destroy: Subject<void>;

  readonly companySummaryField = 'companySummary';
  readonly companySummaryNameField = `${this.companySummaryField}.name`;
  readonly loading = new BehaviorSubject(false);

  toolbar: ToolbarItems[];
  data: Observable<DataStateChangeEventArgs>;
  formGroup: UntypedFormGroup;
  pageOptions: object;
  placeholder = '';
  state: DataStateChangeEventArgs & { includeSubCompany: boolean } = {
    skip: 0,
    take: Constants.DefaultPageSize,
    includeSubCompany: false
  };
  includeSubCompanyFormControl: UntypedFormControl;
  dataExport;
  companyId: number;
  showSubCompanyCheckbox = false;
  public childData: any;
  public childGrid: GridModel;
  public customAttributes: Object;
  public tz;
  dynamicColumns$: Observable<any>;
  tmpWidths = [];
  administrationRrepotsPermission: any;
  closeResult = '';
  dataStateChange(
    state: DataStateChangeEventArgs,
    includeSubCompanyFormControl: UntypedFormControl
  ): void {
    const includeSubCompany = includeSubCompanyFormControl
      ? includeSubCompanyFormControl.value
      : true;
    const newState = { ...state, includeSubCompany };

    this.grid.showSpinner();
    this.dataService.execute(newState);
    this.state = newState;
  }

  private handleFilterChange(value: string, includeSubCompany: boolean): void {
    const where: Predicate[] = value
      ? ([
          {
            isComplex: true,
            ignoreAccent: false,
            condition: 'or',
            predicate: this.filterSettings.columns.map((column) => ({
              ...column,
              value
            }))
          }
        ] as unknown as Predicate[])
      : [];

    this.grid.showSpinner();
    this.dataService.execute({ ...this.state, includeSubCompany, where });

    this.state.includeSubCompany = includeSubCompany;
  }

  headerCellInfo(args: any): void {
    args.node.getElementsByClassName('e-checkbox-wrapper')[0] &&
      args.node.getElementsByClassName('e-checkbox-wrapper')[0].remove();
  }

  locationModal(data) {
    this.locationSelected.emit(data);
  }

  statusModal(data, type) {
    if (type === 'resend') {
      this.statusSelected.emit(data);
    } else {
      this.statusSelectedCancel.emit(data);
    }
  }

  rowSelecting({ data }: { data: any }): void {
    if (this.grid.getSelectedRecords().length) {
      this.grid.clearSelection();
    }
    this.rowSelected.emit(data);
  }

  created(args) {
    if (!this.version) return;
    const versions = localStorage.getItem('reportVersions') ?? '{}';
    const json = JSON.parse(versions);
    const [key] = Object.keys(this.version);
    const [value] = Object.values(this.version);
    const versionMatch = json?.[key] !== value;

    if (versionMatch) {
      this.grid.enablePersistence = false;
      window.localStorage.setItem('grid' + this.grid.element.id, '');
      this.grid.destroy();
      location.reload();

      // Set current report version in localStorage
      localStorage.setItem(
        'reportVersions',
        JSON.stringify({ ...json, ...this.version })
      );
    }
  }

  load(args): void {
    if (this.grid?.element) {
      this.grid.element.addEventListener('mousedown', (e: MouseEventArgs) => {
        if (
          (e.target as HTMLElement).classList.contains('e-rowcell') &&
          this.isModal
        ) {
          this.grid.clearSelection();
        }
      });
    }
    if (this.childColumns !== undefined) {
      this.grid.childGrid.dataSource = this.dataService.childData;
    }

    if (this.pageNo$) {
      this.pageNo$
        .pipe(
          tap((pageNo) => {
            this.grid.pageSettings.currentPage = pageNo;
          })
        )
        .subscribe();
    }
  }

  public employeeData: Object[];

  exportQueryCellInfo(args: ExcelQueryCellInfoEventArgs): void {
    if (
      args.column.headerText === 'Export Image' &&
      (args as any).data?.imageForExport.length > 0
    ) {
      if ((args as any).name === 'excelQueryCellInfo') {
        (args.cell as any).value = '';
        args.value = '';
        args.image = {
          height: 75,
          base64: (args as any).data?.imageForExport,
          width: 75
        };
      } else {
        args.image = {
          height: 75,
          base64: (args as any).data?.imageForExport,
          width: 75
        };
      }
    }
  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id.includes('excelexport')) {
      this.loading.next(true);
      this.dataExport = this.dataService
        .getExport(this.state, this.state.includeSubCompany)
        .pipe(
          tap((data: any) => {
            if (data !== null && data?.result.length > 0) {
              // Show columns for export results
              if (this.exportColumns?.show?.length > 0) {
                this.grid.showColumns(this.exportColumns.show, 'headerText');
              }
              // Hide columns for export results
              if (this.exportColumns?.hide?.length > 0) {
                this.grid.hideColumns(this.exportColumns.hide, 'headerText');
              }
              // If Cargo History report, to adjust the widths
              if (args.item.id.includes('excelexport')) {
                if (this.exportFileName === 'CargoHistory') {
                  this.tmpWidths = this.grid.columns.map((column, i) => {
                    if (column?.width) {
                      return column.width;
                    }
                    (this.grid.columns[i] as any).width = 200;
                    return undefined;
                  });
                  const excelExportProperties: ExcelExportProperties = {
                    fileName: `${this.exportFileName}.xlsx`,
                    dataSource: data.result
                  };
                  this.grid.excelExport(excelExportProperties);
                } else {
                  const excelExportProperties: ExcelExportProperties = {
                    fileName: `${this.exportFileName}.xlsx`,
                    dataSource: data.result
                  };
                  this.grid.excelExport(excelExportProperties);
                }
              }
            } else {
              this.grid.hideSpinner();
              this.loading.next(false);
            }
          })
        )
        .subscribe();
    }
  }

  excelExportComplete(): void {
    // REVERT - Show columns for export results
    if (this.exportColumns?.show?.length > 0) {
      this.grid.hideColumns(this.exportColumns.show, 'headerText');
    }
    // REVERT - Hide columns for export results
    if (this.exportColumns?.hide?.length > 0) {
      this.grid.showColumns(this.exportColumns.hide, 'headerText');
    }
    if (this.exportFileName === 'CargoHistory') {
      this.grid.columns.map((_, i) => {
        (this.grid.columns[i] as any).width = this.tmpWidths[i];
      });

      this.tmpWidths = [];
    }
    this.dataExport.unsubscribe();
    this.loading.next(false);
  }

  checkVisibility(data): boolean {
    return data?.result?.[0]?.showCompanyCol
      ? data.result[0].showCompanyCol
      : false;
  }

  displayTimeTooltip(args: QueryCellInfoEventArgs, prop: string): void {
    const lastKnownBatteryVoltage = args?.data[prop];
    const timestampProp = `${prop}Timestamp`;
    const timestamp = args?.data[timestampProp];

    if (lastKnownBatteryVoltage || lastKnownBatteryVoltage === 0) {
      const time = moment(timestamp)
        ?.tz(this.tz)
        ?.format('M/D/YYYY h:mm:ss A z');
      const tooltip: Tooltip = new Tooltip(
        {
          content: `Last Received: ${time}`
        },
        args.cell as HTMLTableCellElement
      );
    }
  }

  tooltip(args: QueryCellInfoEventArgs) {
    const field = args?.column?.field;
    const fieldLowerCase = field ? args.column.field.toLowerCase() : field;

    if (
      fieldLowerCase &&
      (fieldLowerCase.includes('assetbatteryvoltage') ||
        fieldLowerCase.includes('trackerbatteryvoltage'))
    ) {
      this.displayTimeTooltip(args, field);
    }
  }

  ngOnInit(): void {
    this.state = this.defaultState
      ? {
          ...this.state,
          ...this.defaultState,
          includeSubCompany: this.includeSubCompany
        }
      : { ...this.state, includeSubCompany: this.includeSubCompany };
    this.pageOptions = { pageSize: Constants.DefaultPageSize, pageCount: 5 };
    this.AdministrationService.AdministrationRrepots.subscribe((res) => {
      this.administrationRrepotsPermission = res;
    });
    this.authService.companyId$.subscribe((companyId) => {
      this.companyId = companyId;
      this.getCompanyDetail(companyId);
      if (companyId && this.formGroup) {
        this.formGroup.patchValue({
          companyId: companyId
        });
      }
    });
    const toolbar: any[] = ['ColumnChooser'];
    let i = 1;
    this.administrationRrepotsPermission.forEach((element) => {
      if (element.value == 'excelExport' && element.isActive == true) {
        toolbar[i] = 'ExcelExport';
      } else if (element.value == 'pdfExport' && element.isActive == true) {
        toolbar[i] = 'PdfExport';
      }
      if (element.value == 'columnSelector' && element.isActive == true) {
        this.selectionOptions = {
          allowColumnSelection: true,
          type: 'Multiple'
        };
      }
      if (element.value == 'columnResizing' && element.isActive == true) {
        this.allowResizing = true;
      }
      if (element.value == 'columnGrouping' && element.isActive == true) {
        this.allowGrouping = true;
      }
      i = i + 1;
    });
    this.toolbar = toolbar;
    this.data = this.dataService;
    this.dataService.execute(this.state);
    this.authService.currentUser$
      .pipe(
        tap((data) => (this.tz = data['ianaTimeZone'] ?? 'America/New_York'))
      )
      .subscribe();

    this.authService.companyId$
      .pipe(
        switchMap((companyId) =>
          this.companyService.getCompanyHierarchy().pipe(
            map((companies) =>
              companies.map((company) => {
                if (company.id === companyId) {
                  this.showSubCompanyCheckbox = company.hasChild;
                }
              })
            )
          )
        )
      )
      .subscribe();

    if (this.childColumns !== undefined) {
      this.childGrid = {
        queryString: 'tripId',
        columns: this.childColumns,
        enableHover: false
      };
    }

    this.placeholder = this.filterSettings.columns.reduce(
      (working, next, index, values) => {
        return values.length === 1
          ? `${working}${next.headerText}`
          : index === values.length - 1
          ? `${working}or ${next.headerText}`
          : `${working}${next.headerText}${
              index === values.length - 2 ? ' ' : ', '
            }`;
      },
      'Search '
    );

    if (this.filterSettings.columns.length > 0) {
      this.destroy = new Subject();
      this.formGroup = this.formBuilder.group({
        search: this.formBuilder.control(''),
        searchFrom: this.formBuilder.control(''),
        searchTo: this.formBuilder.control(''),
        statusFilter: this.formBuilder.control(null),
        changeStatus: this.formBuilder.control('disable'),
        searchUser: this.formBuilder.control(''),
        companyId: this.formBuilder.control(''),
        froms: this.formBuilder.control(''),
        startDateTime: this.formBuilder.control(''),
        endDateTime: this.formBuilder.control('')
      });

      this.formGroup
        .get('search')
        .valueChanges.pipe(
          takeUntil(this.destroy),
          map((value) => ((value as string) || '').trim().toLowerCase()),
          distinctUntilChanged(),
          debounceTime(500),
          tap((value) =>
            this.handleFilterChange(
              value,
              this.includeSubCompanyFormControl
                ? this.includeSubCompanyFormControl.value
                : true
            )
          )
        )
        .subscribe();
    }

    if (this.includeSubCompanyText) {
      this.includeSubCompanyFormControl = this.formBuilder.control(
        this.includeSubCompany
      );

      if (typeof this.formGroup !== 'undefined') {
        this.includeSubCompanyFormControl.valueChanges
          .pipe(
            takeUntil(this.destroy),
            tap((value) =>
              this.handleFilterChange(this.formGroup.get('search').value, value)
            )
          )
          .subscribe();
      } else {
        this.includeSubCompanyFormControl.valueChanges
          .pipe(tap((value) => this.handleFilterChange(null, value)))
          .subscribe();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.destroy) {
      this.destroy.next();
    }
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed`;
        }
      );
  }
  getCompanyDetail(companyId) {
    this.companyId = companyId;
    this.selectedCompany = null;
    this.selectedCompanyFroms = null;
    if (this.companyId) {
      this.companyService.getCompany(companyId).subscribe((res) => {
        this.selectedCompany = { id: res.id, name: res.name };
        this.selectedCompanyFroms = { id: res.id, name: res.name };
      });
    }
  }
}
