import {
  AgmCoreModule,
  LazyMapsAPILoaderConfigLiteral,
  LAZY_MAPS_API_CONFIG
} from '@agm/core';
import { AgmDrawingModule } from '@agm/drawing';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SharedModule } from './modules/shared/shared.module';
import { GlobalSettingsComponent } from './pages/global-settings/global-settings.component';
import { ProtectedComponent } from './pages/protected/protected.component';
import { ApplicationInsightsErrorHandler } from './services/appinsights-error-handler.service';
import { ConfigsLoaderService } from './services/configs-loader.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';

import { AuthConfig } from './auth-config';
import { DeviceManagementComponent } from './pages/device-management/device-management.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(
  configsLoaderService: ConfigsLoaderService
): IPublicClientApplication {
  return new PublicClientApplication(
    new AuthConfig(configsLoaderService).getMsalConfig()
  );
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(
  configsLoaderService: ConfigsLoaderService
): MsalGuardConfiguration {
  return {
    interactionType: IsInIframe()
      ? InteractionType.Popup
      : InteractionType.Redirect,
    authRequest: {
      scopes: [
        'openId',
        'profile',
        'offline_access',
        configsLoaderService.configs.azureAuthentication.scopesUrl
      ],
      extraQueryParameters: {
        env: configsLoaderService.configs.azureAuthentication.env // need to dynamically set this identifier based on the current url
      }
    }
  };
}
export function IsInIframe() {
  return window !== window.parent && !window.opener;
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

export function appInitializerFactory(
  configsLoaderService: ConfigsLoaderService
) {
  return () => configsLoaderService.loadConfigs();
}

export function googleMaps(
  configsLoaderService: ConfigsLoaderService
): LazyMapsAPILoaderConfigLiteral {
  return configsLoaderService.configs.googleMaps;
}

@Injectable()
class GoogleMapsConfig {
  googleMaps: LazyMapsAPILoaderConfigLiteral;
  constructor(private configService: ConfigsLoaderService) {
    this.googleMaps = configService.configs.googleMaps;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    ProtectedComponent,
    GlobalSettingsComponent,
    DeviceManagementComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AgmCoreModule.forRoot(),
    AgmDrawingModule,
    SharedModule,
    MsalModule,
    NgbAccordionModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [window.location.hostname]
      }
    }),
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: true,
      progressAnimation: 'increasing'
    }),
    AngularSvgIconModule.forRoot(),
    NgxBootstrapIconsModule.pick(allIcons)
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [ConfigsLoaderService],
      multi: true
    },
    {
      provide: LAZY_MAPS_API_CONFIG,
      useClass: GoogleMapsConfig
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ConfigsLoaderService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [ConfigsLoaderService]
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]
})
export class AppModule {}
