import { Component, Input } from '@angular/core';
import { CompanySummary } from './../../models/company/company-summary';

@Component({
  selector: 'lana-company-summary',
  templateUrl: './company-summary.component.html',
  styleUrls: ['./company-summary.component.scss']
})
export class CompanySummaryComponent {
  @Input() isEntity = true;
  @Input() summary: CompanySummary;
  @Input() url: string;
}
