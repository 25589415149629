import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnParser'
})
export class ColumnParserPipe implements PipeTransform {
  transform(value: any, ...args: string[]): unknown {
    const [property] = args;
    const properties = (property || '').split('.');
    return properties.reduce(
      (working, next) => (working ? working[next] : null),
      value
    );
  }
}
