import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Column, ICellFormatter } from '@syncfusion/ej2-grids';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { Constants } from './../constants';
import { CommentList } from './../models/asset/comment';
import { LanaColumnModel } from './../models/column';
import { FilterSettings } from './../models/filter-settings';
import {
  AssetGroup,
  AssignableAssetGroup,
  AssetGroupAssets
} from './../models/group/asset-group';
import { AssetGroupComment } from './../models/group/asset-group-comment';
import {
  OdataResponse,
  OdataResponseTransform
} from './../models/odata-response';
import { AssetService } from './asset.service';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';

class StatusFormatter implements ICellFormatter {
  public getValue(_: Column, data: AssetGroup): string {
    return data.isPublic ? 'Public' : 'Private';
  }
}

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private assetService: AssetService,
    private httpClient: HttpClient
  ) {}

  private readonly currentlyViewingGroup = new BehaviorSubject<AssetGroup>(
    null
  );
  private readonly odataUrl = `${Constants.Odata}/AssetGroups?$expand=Assets,CompanySummary`;
  private readonly mapGroupUrl = `${Constants.Api}/MapAssetGroups`;
  private readonly url = `${Constants.Api}/AssetGroup`;

  readonly columns: LanaColumnModel<AssetGroup>[] = [
    {
      field: 'companySummary.name',
      headerText: 'Company Name',
      generateUrl: (assetGroup) =>
        assetGroup.companySummary
          ? `/management/sub-companies/${assetGroup.companySummary.id}`
          : ''
    },
    {
      field: 'name',
      headerText: 'Group Name',
      generateUrl: (assetGroup) => `/management/groups/${assetGroup.id}`
    },
    { field: 'assetCount', headerText: 'Asset Count' },
    {
      field: 'isPublic',
      headerText: 'Public/Private',
      formatter: StatusFormatter
    }
  ];

  readonly filterSettings: FilterSettings = {
    columns: [
      {
        field: 'companySummary.name',
        headerText: 'Company Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      },
      {
        field: 'name',
        headerText: 'Group Name',
        matchCase: false,
        operator: 'contains',
        predicate: 'or'
      }
    ]
  };

  currentlyViewingGroup$ = this.currentlyViewingGroup.asObservable();

  getAssetGroups({
    filterQuery,
    pageQuery,
    sortQuery
  }): Observable<OdataResponseTransform<AssetGroup>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<AssetGroup>>(
          `${this.odataUrl}&companyContext=${companyId}&${pageQuery}${filterQuery}${sortQuery}&$count=true`
        )
      ),
      map(
        (response) =>
          ({ result: response.value, count: response['@odata.count'] } as any)
      ),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getAssetGroupsExport({
    filterQuery
  }): Observable<OdataResponseTransform<AssetGroup>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<OdataResponse<AssetGroup>>(
          `${this.odataUrl}&companyContext=${companyId}&${filterQuery}&$count=true`
        )
      ),
      map(
        (response) =>
          ({ result: response.value, count: response['@odata.count'] } as any)
      ),
      catchError((_) => of({ result: [], count: 0 }))
    );
  }

  getMapAssetGroups(
    { filterQuery, pageQuery, sortQuery },
    includeSubCompany = false
  ): Observable<OdataResponseTransform<AssetGroup>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient.get<AssetGroup>(
          `${
            this.mapGroupUrl
          }?companyContext=${companyId}&includeSubcompany=${includeSubCompany}${pageQuery}${
            filterQuery
              ? `${filterQuery}${
                  includeSubCompany
                    ? ''
                    : ' and CompanySummary/Id eq ' + companyId
                }`
              : `${
                  includeSubCompany
                    ? ''
                    : '&$filter=CompanySummary/Id eq ' + companyId
                }`
          }${sortQuery}`
        )
      ),
      map((response) => ({ result: response } as any)),
      catchError((_) => of({ result: [] }))
    );
  }

  getAssignableAssetGroups(
    assetId: number,
    { filterQuery, pageQuery }
  ): Observable<OdataResponseTransform<AssignableAssetGroup>> {
    const filter = filterQuery
      ? `${filterQuery} and Id ne ${assetId}`
      : `&$filter=Id ne ${assetId}`;

    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient
          .get<OdataResponse<AssignableAssetGroup>>(
            `${this.odataUrl}${filter} and CompanySummary/Id eq ${companyId}${pageQuery}&$count=true&companyContext=${companyId}`
          )
          .pipe(
            map((response) => ({
              result: response.value,
              count: response['@odata.count']
            })),
            map((response) => {
              response.result = response.result.map((result) => {
                result.canRemoveFromGroup =
                  result.assets.length > 0 &&
                  result.assets.some((asset) => asset.id === assetId);
                return result;
              });
              return response;
            }),
            catchError((_) => of({ result: [], count: 0 }))
          )
      )
    );
  }

  getAssetGroupsForCompany({
    filterQuery,
    pageQuery,
    sortQuery,
    companyContext
  }): Observable<OdataResponseTransform<AssetGroup>> {
    return this.httpClient
      .get<OdataResponse<AssetGroup>>(
        `${this.odataUrl}&${pageQuery}${filterQuery}${sortQuery}&$count=true${companyContext}`
      )
      .pipe(
        map(
          (response) =>
            ({ result: response.value, count: response['@odata.count'] } as any)
        ),
        catchError((_) => of({ result: [], count: 0 }))
      );
  }

  getAssetGroupsForAsset(
    assetId: number,
    { pageQuery }
  ): Observable<OdataResponseTransform<AssetGroup>> {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.httpClient
          .get<OdataResponse<AssetGroup>>(
            `${this.odataUrl}&companyContext=${companyId}&$filter=CompanySummary/Id eq ${companyId} and Assets/any(a: a/Id eq ${assetId})${pageQuery}&$count=true`
          )
          .pipe(
            map(
              (response) =>
                ({
                  result: response.value,
                  count: response['@odata.count']
                } as any)
            ),
            catchError((_) => of({ result: [], count: 0 }))
          )
      )
    );
  }

  getAssetGroup(assetGroupId: number): Observable<AssetGroup> {
    this.currentlyViewingGroup.next(null);

    return assetGroupId
      ? this.httpClient
          .get<AssetGroup>(`${this.url}/${assetGroupId}`)
          .pipe(tap((response) => this.currentlyViewingGroup.next(response)))
      : of(null).pipe(tap((_) => this.currentlyViewingGroup.next(null)));
  }

  getGroupAssetIds(assetGroupId: number): Observable<number[]> {
    return this.httpClient.get<number[]>(`${this.url}/${assetGroupId}/assets`);
  }

  createAssetGroup(assetGroup: AssetGroup): Observable<AssetGroup> {
    return this.httpClient.post<AssetGroup>(this.url, assetGroup);
  }

  updateAssetGroup(assetGroup: AssetGroup): Observable<AssetGroup> {
    return this.httpClient
      .put<AssetGroup>(this.url, assetGroup)
      .pipe(tap((response) => this.currentlyViewingGroup.next(response)));
  }

  getParentAssetGroupEnabled() {
    return this.authService.companyId$.pipe(
      concatMap((companyId) => this.companyService.getCompany(companyId)),
      switchMap((_) => this.companyService.currentlyViewingCompany$),
      map((response) => response.parentAssetGroupEnabled),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getDeviceAssets() {
    return this.authService.companyId$.pipe(
      switchMap((companyId) =>
        this.assetService.getAssets({
          filterQuery: `&$filter=CompanySummary/Id eq ${companyId}`,
          pageQuery: '',
          sortQuery: ''
        })
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getComments(assetGroupId: number): Observable<CommentList> {
    const url = `${this.url}/${assetGroupId}/Comments`;

    return this.httpClient.get<CommentList>(url).pipe(
      map((response) => {
        response.comments = response.comments.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
        return response;
      })
    );
  }

  createComment(assetGroupComment: AssetGroupComment): Observable<CommentList> {
    const url = `${this.url}/Comment`;

    return this.httpClient.post<CommentList>(url, assetGroupComment);
  }

  addAssetCountForAssetGroup(): void {
    this.setAssetCountForAssetGroup(1);
  }

  updateAssetCountForAssetGroup(): void {
    this.setAssetCountForAssetGroup(-1);
  }

  removeAssetGroup(id: number): Observable<void> {
    return this.httpClient.request<void>('delete', this.url, { body: { id } });
  }

  getAssetGroupsForMap(includeSubCompany = false): Observable<AssetGroup[]> {
    return this.getMapAssetGroups(
      { filterQuery: '', pageQuery: '', sortQuery: '' },
      includeSubCompany
    ).pipe(map(({ result }) => result));
  }

  private setAssetCountForAssetGroup(value: number): void {
    const currentValue = this.currentlyViewingGroup.getValue();

    if (currentValue) {
      currentValue.assetCount = currentValue.assetCount + value;

      this.currentlyViewingGroup.next(currentValue);
    }
  }
}
