import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lana-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {
  @Input() disabled = false;
  @Input() loading: boolean;
  @Input() noMargin = false;
  @Input() primary = false;
  @Input() danger = false;
  @Input() success = false;

  @Output() buttonPressed = new EventEmitter();
}
