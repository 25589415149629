<ng-template #loadingTemplate>
  <lana-loading></lana-loading>
</ng-template>
<!-- <lana-assets-modal></lana-assets-modal> -->
<form [formGroup]="formGroup">
  <div class="form-group">
    <label>Filter</label>
    <select class="form-control" formControlName="assetFilter">
      <option disabled [ngValue]="null">Select Filter Type</option>
      <option [ngValue]="filter.value" *ngFor="let filter of filterTypes">
        {{ filter.name }}
      </option>
    </select>
    <lana-error-display
      [control]="formGroup.get('assetFilter')"
      label="Asset Filter"></lana-error-display>
  </div>
</form>
<ng-container *ngIf="loading | async">
  <lana-loading></lana-loading>
</ng-container>
<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'groups'">
    <ng-container *ngIf="table$ | async as groups">
      <lana-multi-select-list-view
        header="Asset Groups"
        [data]="groups"
        (selectionChange)="assetSelected('group', $event)"
        [selected]="selectedGroups">
      </lana-multi-select-list-view>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'types'">
    <ng-container *ngIf="assetTypes | async as assetTypes">
      <lana-asset-category-browser
        header="Asset Types"
        [assetTypes]="assetTypes"
        [selectedAssetTypes]="selectedAssetTypes"
        [selectedMakes]="selectedAssetMakes"
        [selectedModels]="selectedAssetModels"
        (modelAdd)="assetAdded($event)"
        (modelRemove)="assetRemoved($event)">
      </lana-asset-category-browser>
      <!--
      <lana-multi-select-list-view header="Asset Types" [data]="assetTypes"
        (selectionChange)="assetSelected('asset type', $event)" [selected]="selectedAssetTypes">
      </lana-multi-select-list-view>
    -->
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'assets'">
    <ng-container *ngIf="assetsSelection | async as assetsSelection">
      <lana-multi-select-list-view
        header="Asset Picker"
        [data]="assetsSelection"
        [showButtons]="true"
        (selectionChange)="individualAssetsSelected($event)"
        (count)="assetsCount = $event"
        [selected]="assetsSelected">
      </lana-multi-select-list-view>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'trackerTypes'">
    <ng-container *ngIf="trackerTypes | async as trackerTypes">
      <lana-multi-select-list-view
        header="Tracker Types"
        [data]="trackerTypes"
        (selectionChange)="assetSelected('tracker type', $event)"
        [selected]="selectedTrackerTypes">
      </lana-multi-select-list-view>
    </ng-container>
  </ng-container>
</ng-container>
