<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Alert Schedule</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="card-text">
      <small class="text-muted"
        >Set the days and times you would like to receive this alert. Leaving
        this blank will send the alert immediately.</small
      >
    </p>
    <ng-container *ngIf="schedule.length > 0">
      <ng-container *ngFor="let item of schedule; count as c; index as i">
        <div class="row">
          <div class="col-1">
            <div class="step-icon">
              <div class="step-icon-content">{{ c }}</div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Start Time</label>
                  <ejs-timepicker
                    [value]="item.startTime"
                    (change)="time('startTime', startTime.value, i)"
                    #startTime>
                  </ejs-timepicker>
                  <ng-container *ngIf="invalidStart && currentIndex === i">
                    <div class="d-block error">
                      <i class="fas fa-exclamation-triangle"></i>
                      Invalid Start Time Value. Click on clock icon to select a
                      formatted time.
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>End Time</label>
                  <ejs-timepicker
                    [value]="item.endTime"
                    (change)="time('endTime', endTime.value, i)"
                    #endTime>
                  </ejs-timepicker>
                  <ng-container *ngIf="invalidEnd && currentIndex === i">
                    <div class="d-block error">
                      <i class="fas fa-exclamation-triangle"></i>
                      Invalid End Time Value. Click on clock icon to select a
                      formatted time.
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Select Day(s)</label>
                  <button
                    *ngFor="let day of item.alertOnDays"
                    type="button"
                    class="ml-2 btn btn-{{
                      day.selected ? '' : 'outline-'
                    }}primary btn-sm"
                    (click)="daySelected(day.val, i)">
                    {{ day.name }}
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div class="col-1">
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              (click)="removeSchedule(i)"
              placement="top"
              ngbTooltip="Remove schedule">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="loading | async">
      <lana-loading></lana-loading>
    </ng-container>
    <div class="row justify-content-center">
      <div class="col-4">
        <button
          type="button"
          class="btn btn-outline-secondary btn-sm"
          (click)="addSchedule()">
          <i class="fas fa-plus-circle"></i> Add another schedule
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-outline"
      (click)="modal.close('Cancel button')">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
      <i class="fas fa-save"></i> Save
    </button>
  </div>
</ng-template>
