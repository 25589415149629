import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MapService } from '@app/services/map.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of } from 'rxjs';

@Component({
  selector: 'lana-vision-modal',
  templateUrl: './vision-modal.component.html',
  styleUrls: ['./vision-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VisionModalComponent implements OnInit {
  @ViewChild('content') content: TemplateRef<any>;
  public images: Observable<any>;
  public vision: any;
  public timezone = 'America/New_York';

  constructor(private ngbModal: NgbModal, private mapService: MapService) {}

  getImages(asset) {
    const images: string = asset?.image
      .replace(/["]+/g, '')
      .replace(/[\[\]']+/g, '');
    const imagesArray: any = images.split(',');

    const images$ = imagesArray.map((image) => {
      return this.mapService.getImage(image);
    });

    this.images = forkJoin(images$);
  }

  dismiss(modal: NgbActiveModal): void {
    modal.dismiss();
  }

  openModal(vision, timezone, image = null): void {
    this.vision = vision;
    this.timezone = timezone;
    this.ngbModal.open(this.content, {
      size: 'lg',
      windowClass: 'lana-vision'
    });

    if (image) {
      this.images = of([image]);
    } else if (vision?.image) {
      this.getImages(vision);
    }
  }

  getTemp(temperature) {
    return `${Math.round(temperature * 10) / 10}\xB0F`;
  }

  ngOnInit(): void {}
}
