import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { LoginResponse } from '../models/auth/login-response';
import { Roles } from '../models/auth/role';
import { Constants } from './../constants';
import { CurrentUser } from './../models/auth/current-user';
import { UserLoginRequest } from './../models/auth/user-login-request';
import { LoggingService } from './logging.service';
import { MsalService } from '@azure/msal-angular';
import { ConfigsLoaderService } from './configs-loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private jwtHelperService: JwtHelperService,
    private ngbModal: NgbModal,
    private loggingService: LoggingService,
    private msalAuthService: MsalService,
    private configService: ConfigsLoaderService,
    private router: Router
  ) {
    this.init();
  }

  private readonly accessToken = new BehaviorSubject<string>(null);
  private readonly companyId = new BehaviorSubject<number>(null);
  private readonly adToken = new BehaviorSubject<string>(null);
  private readonly currentUser = new BehaviorSubject<CurrentUser>(null);
  private readonly currentPlatform = new BehaviorSubject<string>('igotcha');
  private readonly roles = new BehaviorSubject<Roles[]>(null);
  private readonly parentCompanyId = new BehaviorSubject<number>(null);

  private readonly currentUserUrl = `${Constants.Api}/CurrentUser`;
  private readonly url = `${Constants.Api}/oauth/token`;

  private setLogoutTimeout: any;

  readonly accessToken$ = this.accessToken.asObservable();
  readonly companyId$ = this.companyId.pipe(distinctUntilChanged());
  readonly currentPlatform$ = this.currentPlatform.pipe(distinctUntilChanged());
  readonly parentCompanyId$ = this.parentCompanyId.pipe(distinctUntilChanged());
  readonly currentUser$ = this.currentUser.pipe(
    filter((currentUser) => currentUser !== null)
  );
  readonly roles$ = this.roles.pipe(filter((roles) => roles !== null));
  readonly adToken$ = this.adToken.pipe(filter((a) => a != null));

  private init(): void {
    const accessToken = this.getAccessToken();
    const expiresAtString = this.getExpiresAt();
    if (accessToken && expiresAtString) {
      const expiresAt = +expiresAtString;
      const now = new Date().getTime();

      if (expiresAt > now) {
        this.accessToken.next(accessToken);
        const companyId = this.getCompanyId(accessToken);
        const parentCompanyId = this.setParentCompanyId(accessToken);
        const currentPlatform = this.setPlatform();
        const roles = this.getRoles(accessToken);
        const adToken = this.getAdToken(accessToken);
        this.adToken.next(adToken);
        this.companyId.next(companyId);
        this.parentCompanyId.next(parentCompanyId);
        this.currentPlatform.next(currentPlatform);
        this.roles.next(roles);
        this.setupTimer(expiresAt, now);
        this.getCurrentUser();
      } else {
        this.logout();
      }
    } else {
      const account = this.msalAuthService.instance.getActiveAccount();
      if (account) {
        const azureAuth = this.configService.configs.azureAuthentication;
        const redirectUrs =
          this.configService.configs.azureAuthentication.redirectUris;
        const hostName = window.location.hostname;
        const accessTokenRequest = {
          scopes: [azureAuth.scopesUrl],
          clientId: this.configService.configs.azureAuthentication.clientId,
          authority: `${azureAuth.domains[hostName]}/${azureAuth.tenantId}/${azureAuth.signUpSignInAuthority}`,
          redirectUri: redirectUrs[window.location.hostname]
        };
        this.msalAuthService
          .acquireTokenSilent(accessTokenRequest)
          .pipe(
            tap((response) => {
              this.processAccessToken(response.accessToken);
            }),
            // this error is handled in app.component.ts
            catchError(() => {
              return of(false);
            })
          )
          .subscribe();
      }
    }
  }

  private getAdToken(token: string): string {
    const windowObject: any = window;
    const claims = this.jwtHelperService.decodeToken(token);
    console.log('CLAIMS', claims);
    if (windowObject.Cypress) {
      const adTokenClaim = Object.keys(claims).find((key) =>
        key.endsWith('activedirectorytoken')
      );
      return claims[adTokenClaim];
    } else {
      const adTokenClaim = token;
      claims['activedirectorytoken'] = adTokenClaim;
      return adTokenClaim;
    }
  }

  private getCurrentUser(): any {
    const token = this.adToken.getValue();
    const { name, companyName, preferred_username, userTimeZone } =
      this.jwtHelperService.decodeToken(token);
    const user = {
      name,
      companyName,
      email: preferred_username,
      username: preferred_username,
      ianaTimeZone: userTimeZone
    };
    console.log(user);
    this.currentUser.next(user);
    return user;
  }

  private setupTimer(expiresAt: number, now: number): void {
    this.setLogoutTimeout = setTimeout(() => {
      // save router state so we can get the user back to where they were
      this.logout();
      const windowObject: any = window;
      if (windowObject.Cypress) {
        this.router.navigateByUrl('/login');
      }
    }, expiresAt - now);
  }

  private storeAccessTokenResponse(
    accessToken: string,
    expiresIn: number
  ): void {
    localStorage.setItem('access_token', accessToken);

    const expiresInMilliSeconds = expiresIn * 1000;
    const now = new Date();
    const expiresAt = now.getTime() + expiresInMilliSeconds;

    localStorage.setItem('expires_at', `${expiresAt}`);

    this.setupTimer(expiresAt, now.getTime());
  }

  private getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  private getExpiresAt(): string {
    return localStorage.getItem('expires_at');
  }
  private setParentCompanyId(token: string): number {
    const claims = this.jwtHelperService.decodeToken(token);
    const companyIdClaim = Object.keys(claims).find((key) =>
      key.endsWith('companyId')
    );
    return +claims[companyIdClaim];
  }
  private setPlatform(): string {
    return window.location.pathname.split('/')[1];
  }
  private getCompanyId(token: string): number {
    let retval = +localStorage.getItem('selectedCompanyId');
    if (!retval) {
      const { companyId } = this.jwtHelperService.decodeToken(token);
      retval = +companyId;
    }
    return retval;
  }
  private getRoles(token: string): Roles[] {
    const claims = this.jwtHelperService.decodeToken(token);
    const roleClaims = Object.keys(claims).find((key) =>
      key.endsWith('userRoles')
    );
    return claims[roleClaims] as Roles[];
  }

  login(userLoginRequest: UserLoginRequest): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(this.url, userLoginRequest).pipe(
      tap(({ access_token, expires_in }) =>
        this.storeAccessTokenResponse(access_token, expires_in)
      ),
      tap(({ access_token }) => this.accessToken.next(access_token)),
      tap(({ access_token }) => {
        const companyId = this.getCompanyId(access_token);
        const roles = this.getRoles(access_token);
        const adToken = this.getAdToken(access_token);
        this.adToken.next(adToken);
        this.companyId.next(companyId);
        this.roles.next(roles);
        this.getCurrentUser();
        this.loggingService.setUserId(userLoginRequest.username, companyId);
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  processAccessToken(accessToken: string) {
    this.storeAccessTokenResponse(accessToken, 43200);
    this.accessToken.next(accessToken);
    const companyId = this.getCompanyId(accessToken);
    const roles = this.getRoles(accessToken);
    const adToken = this.getAdToken(accessToken);
    this.adToken.next(adToken);
    this.companyId.next(companyId);
    this.roles.next(roles);
    this.getCurrentUser();
    this.currentUser$
      .pipe(
        tap((user) => this.loggingService.setUserId(user.username, companyId))
      )
      .subscribe();
    this.router.navigateByUrl('/');
  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');

    this.accessToken.next(null);

    if (this.setLogoutTimeout) {
      clearTimeout(this.setLogoutTimeout);
    }

    this.ngbModal.dismissAll();
    localStorage.removeItem('selectedCompanyId');
    this.loggingService.clearUserId();

    const windowObject: any = window;
    if (!windowObject.Cypress) {
      if (this.IsInIframe()) {
        this.msalAuthService
          .logoutPopup()
          .pipe(tap((x) => (window.location.href = window.location.href)))
          .subscribe();
      } else {
        this.msalAuthService.logoutRedirect();
      }
    }
  }
  IsInIframe() {
    return window !== window.parent && !window.opener;
  }
  refreshCurrentUser(): void {
    this.getCurrentUser();
  }

  selectCompany(companyId: number): void {
    localStorage.setItem('selectedCompanyId', `${companyId}`);
    this.companyId.next(companyId);
    this.refreshCurrentUser();
    window.location.reload();
  }
}
