import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Constants } from './../constants';
import {
  CreateEmailAddress,
  EmailAddress,
  UpdateEmailAddress
} from './../models/user/user';

@Injectable({
  providedIn: 'root'
})
export class EmailAddressService {
  constructor(private httpClient: HttpClient) {}

  private readonly url = `${Constants.Api}/EmailAddress`;

  createEmailAddress(model: CreateEmailAddress): Observable<EmailAddress> {
    return this.httpClient.post<EmailAddress>(this.url, model);
  }

  updateEmailAddress(model: UpdateEmailAddress): Observable<EmailAddress> {
    return this.httpClient.put<EmailAddress>(this.url, model);
  }

  deleteEmailAddress(id: number): Observable<void> {
    return this.httpClient.request<void>('delete', this.url, { body: { id } });
  }
}
