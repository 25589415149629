<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- https://codemyui.com/rounded-button-like-css-only-checkbox-glide-toggle/ -->
    <div class="toggle-switch">
      <label class="checkbox toggle switch" onclick="">
        <input id="view" type="checkbox" />
        <p>
          <span>Asset Group</span>
          <span>Asset Type</span>
          <span>Tracker Type</span>
        </p>
        <a class="slide-button"></a>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>

<button class="btn btn-lg btn-outline-primary" (click)="open(content)">
  Launch demo modal
</button>

<hr />

<pre>{{ closeResult }}</pre>
