import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'lana-image-data',
  templateUrl: './image-data.component.html',
  styleUrls: ['./image-data.component.scss']
})
export class ImageDataComponent implements OnInit {
  private _data;
  @Input() field: string;
  @Input() timeZone: string;
  @Input() set data(value: any) {
    if (value?.lanaVisionEvent?.imageId) {
      this.getImages(value.lanaVisionEvent.imageId); // the answer is here
    } else {
      this.image = of('N/A');
    }
    this._data = value;
  }

  get data() {
    return this._data;
  }

  public images: Observable<any>;
  public image: Observable<string>;

  constructor(private mapService: MapService, private modalService: NgbModal) {}

  getImages(images) {
    if (images.length > 0) {
      this.image = this.mapService.getImage(images[0]);
    } else {
      this.image = of('Unknown');
    }
  }

  ngOnInit(): void {}

  openModal(content) {
    this.modalService.open(content);
  }

  temperatureFormatter(rec): string {
    const temp = rec?.temperatureFahrenheit;
    if (temp) {
      return `${Math.round(temp * 10) / 10}\xB0F`;
    }
    return 'N/A';
  }

  doorStateFormatter(rec) {
    const val = rec?.lanaVisionEvent?.occupancySensorIsDoorOpen;

    if (typeof val !== 'undefined') {
      try {
        return typeof val !== 'undefined'
          ? val
            ? 'Opened'
            : 'Closed'
          : "<span style='color: #A9A9A9'>Unknown</span>";
      } catch (e) {
        return "<span style='color: #A9A9A9'>N/A</span>";
      }
    }
    return "<span style='color: #A9A9A9'>N/A</span>";
  }
}
