import { MenuItem } from './menu-items';

export interface Menu {
  id: number;
  name: string;

  menuItems: MenuItem[];
}

export enum Menus {
  LANA = 1,
  iGotcha = 2,
  User = 3,
  Top = 4,
  Callpass = 20
}
